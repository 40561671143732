import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";

const Description = styled.Text`
  text-align: left;
  width: "100%";
  font-size: 9px;
`;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 1,
  },
  row: {
    flexDirection: "row",
    height: 16,
  },
});

const TypeAreaStateCust = ({ dat }: any) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.row}>
        <Description>
          {dat.aCalc.report_type} Report In {dat.aCalc.area},{" "}
          {dat.aCalc.state_sel}
          {dat.coverPage.name != "" && (
            <Description> for {dat.coverPage.name}</Description>
          )}
        </Description>
      </View>
    </View>
  );
};

export default TypeAreaStateCust;
