import React, { useState } from "react";
import DatePicker from 'react-date-picker';

const ChristeeDatePicker = (args: any) => {
  const [value, onChange] = useState(new Date());
  console.log('args', args);

  return (
                  <>
                    <DatePicker
                      onChange={onChange}
                      value={value}
                    />
                  </>
  );
}

export default ChristeeDatePicker;
