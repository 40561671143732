import React from 'react';
import { DetailText, DetailTextCenter, TdBlank, TdCash, TdCashPercent, TdDownloadPdfColSpan, TdPercent } from '../../../atoms';

export type TDat = {
  dat: {
    aCalc1: {
      report_type: 'string';
    };
    aCalc2: {
      report_type: 'string';
    };
    bCalc2: {
      report_type: 'string';
    };
    results: {
      aCalc4: {
        report_type: 'string';
        state_sel: 'string';
        condo: 'string';
        temp_ins: 'string';
        fumess: 'string';
        ratio_string: 'string';
        area: 'string';
        allow_piti: 'string';
        target_piti: 'string';
        sp: 'string';
        ma: 'string';
        ltv: 'string';
        d_payment: 'string';
        totalcc: 'string';
        dol_cred: 'string';
        temp_cred: 'string';
        dol_lend: 'string';
        temp_lend: 'string';
        r_cash: 'string';
        t_cash: 'string';
        ex_cash: 'string';
        temp_rate: 'string';
        t_piti: 'string';
        temp_pi: 'string';
        temp_mi: 'string';
        temp_pt: 'string';
      };
    };
  };
};

const Vacation = ({ dat }: TDat) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <DetailText v="Test Conditional" />

          {dat.aCalc1 && <DetailTextCenter v={dat.aCalc1.report_type} />}
          {dat.aCalc2 && <DetailTextCenter v={dat.bCalc2.report_type} />}
        </tr>
        <tr>
          <DetailText v="State" />
          <DetailTextCenter v={dat.results.aCalc4.state_sel} />
        </tr>
        <tr>
          <TdBlank />
          <DetailTextCenter v={dat.results.aCalc4.report_type} />
        </tr>
        <tr>
          <TdBlank />
          <DetailTextCenter v={dat.results.aCalc4.fumess} />
        </tr>
        <tr>
          <TdBlank />
          <DetailTextCenter v={dat.results.aCalc4.ratio_string} />
        </tr>
        <tr>
          <DetailText v="Selected Area" />
          <DetailTextCenter v={dat.results.aCalc4.area} />
        </tr>
        <tr>
          <DetailText v="Target Payment (allow_piti)" />
          <TdCash v={dat.results.aCalc4.allow_piti} />
        </tr>
        <tr>
          <DetailText v="Target Payment (target_piti)" />
          <TdCash v={dat.results.aCalc4.target_piti} />
        </tr>
        <tr>
          <DetailText v="Purchase Price" />
          <TdCash v={dat.results.aCalc4.sp} />
        </tr>
        <tr>
          <DetailText v="Loan Amount" />
          <TdCashPercent a={dat.results.aCalc4.ma} b={dat.results.aCalc4.ltv} />
        </tr>
        <tr>
          <DetailText v="Down payment" />
          <TdCash v={dat.results.aCalc4.d_payment} />
        </tr>
        <tr>
          <DetailText v="Closing Cost" />
          <TdCash v={dat.results.aCalc4.totalcc} />
        </tr>
        <tr>
          <DetailText v="Seller Closing Credit" />
          <TdCashPercent a={dat.results.aCalc4.dol_cred} b={dat.results.aCalc4.temp_cred} />
        </tr>
        <tr>
          <DetailText v="Lender Closing Credit" />
          <TdCashPercent a={dat.results.aCalc4.dol_lend} b={dat.results.aCalc4.temp_lend} />
        </tr>
        <tr>
          <DetailText v="Required Cash" />
          <TdCash v={dat.results.aCalc4.r_cash} />
        </tr>
        <tr>
          <DetailText v="Cash Available" />
          <TdCash v={dat.results.aCalc4.t_cash} />
        </tr>
        <tr>
          <DetailText v="Additional Cash Required" />
          <TdCash v={dat.results.aCalc4.ex_cash} />
        </tr>
        <tr>
          <DetailText v="Interest Rate" />
          <TdPercent v={dat.results.aCalc4.temp_rate} />
        </tr>
        <tr>
          <DetailText v="Total Monthly Payment" />
          <TdCash v={dat.results.aCalc4.t_piti} />
        </tr>
        <tr>
          <DetailText v="Principal and Interest" />
          <TdCash v={dat.results.aCalc4.temp_pi} />
        </tr>
        <tr>
          <DetailText v="Monthly Mortgage Insurance" />
          <TdCash v={dat.results.aCalc4.temp_mi} />
        </tr>
        <tr>
          <DetailText v="Monthly Property Taxes" />
          <TdCash v={dat.results.aCalc4.temp_pt} />
        </tr>
        <tr>
          <DetailText v="Monthly Property Insurance" />
          <TdCash v={dat.results.aCalc4.temp_ins} />
        </tr>
        <tr>
          <DetailText v="Monthly Condo/HOA" />
          <TdCash v={dat.results.aCalc4.condo} />
        </tr>
        <tr>
          <TdDownloadPdfColSpan v={2} />
        </tr>
      </tbody>
    </table>
  );
};

export default Vacation;
