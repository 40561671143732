import React from 'react';
import { TInvestmentCalc } from '../../../../types/api';
import { TdCash, TdCashPercent, TdCenter, TdDownloadPdfColSpan, TdPercent, TdPrefixPrompt, TdText, TdTextPrefix } from '../../../atoms';

interface Props {
  calc: TInvestmentCalc;
}

const Investment: React.FC<Props> = ({ calc }) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <TdText v="" />
          {calc && <td align="center">{calc.report_type}</td>}
        </tr>
        <tr>
          <TdText v="State" />
          {calc && <TdCenter v={calc.state_sel.toString()} />}
        </tr>
        <tr>
          <TdText v="Selected Area" />
          {calc && <TdCenter v={calc.area.toString()} />}
        </tr>
        <tr>
          <TdText v="Purchase Price" />
          {calc && <TdCash v={calc.sp.toString()} />}
        </tr>
        <tr>
          <TdText v="Down payment" />
          {calc && <TdCash v={calc.d_payment.toString()} />}
        </tr>
        <tr>
          <TdText v="Total Closing Cost" />
          {calc && <TdCash v={calc.totalcc.toString()} />}
        </tr>
        <tr>
          <TdText v="Closing Cost Credit" />
          {calc && <TdCashPercent a={calc.dol_cred.toString()} b={calc.temp_cred} />}
        </tr>
        <tr>
          <TdText v="Cash Investment" />
          {calc && <TdCash v={calc.r_cash.toString()} />}
        </tr>
        <tr>
          <TdText v="Acquisition Cost" />
          {calc && <TdCash v={calc.acq_cost.toString()} />}
        </tr>
        <tr>
          <TdText v="Loan Amount" />
          {calc && <TdCashPercent a={calc.ma.toString()} b={calc.ltv.toString()} />}
        </tr>
        <tr>
          <TdText v="Interest Rate" />
          {calc && <TdPercent v={calc.temp_rate.toString()} />}
        </tr>
        <tr>
          <TdText v="Total Monthly Payment" />
          {calc && <TdCash v={calc.t_piti.toString()} />}
        </tr>
        <tr>
          <TdText v="Average Monthly Rent" />
          {calc && <TdCash v={calc.avg_mo_rent.toString()} />}
        </tr>
        <tr>
          <TdText v="Annual Net Operating Income" />
          {calc && <TdCash v={calc.noi.toString()} />}
        </tr>
        <tr>
          <TdText v="Annual Debt Service" />
          {calc && <TdCash v={calc.debt_ser.toString()} />}
        </tr>
        <tr>
          <TdPrefixPrompt a={calc.cash_flow_$} b="Annual Cash Flow" />
          {calc && <TdCash v={calc.cash_flow.toString()} />}
        </tr>
        <tr>
          <TdText v="Future Home Value" />
          {calc && <TdCash v={calc.h_value.toString()} />}
        </tr>
        <tr>
          <TdText v="Mortgage Balance" />
          {calc && <TdCash v={calc.ma_bal.toString()} />}
        </tr>
        <tr>
          <TdText v="Future Equity" />
          {calc && <TdCash v={calc.net_equity.toString()} />}
        </tr>
        <tr>
          <TdText v="Average Yearly Depreciation" />
          {calc && <TdCash v={calc.avg_deprec.toString()} />}
        </tr>
        <tr>
          <TdText v="Projected Gross Profits" />
          {calc && <TdCash v={calc.gross_profit.toString()} />}
        </tr>
        <tr>
          <TdText v="Projected Taxable Capital Gain" />
          {calc && <TdCash v={calc.capital_gain.toString()} />}
        </tr>
        <tr>
          <TdText v="Projected Net Profits" />
          {calc && <TdCash v={calc.net_profits.toString()} />}
        </tr>
        <tr>
          <TdText v={calc.cash_$.toString()} />
          {calc && <TdCash v={calc.cash_imp.toString()} />}
        </tr>
        <tr>
          <TdTextPrefix prefix="Average" v={calc.tax_sit.toString()} />
          {calc && <TdCash v={calc.tot_duct.toString()} />}
        </tr>
        <tr>
          <TdTextPrefix prefix="Average" v={calc.tax_due$.toString()} />
          {calc && <TdCash v={calc.tax_saving.toString()} />}
        </tr>
        <tr>
          <TdDownloadPdfColSpan v={2} />
        </tr>
      </tbody>
    </table>
  );
};

export default Investment;
