import React from "react";

import christeeLogo from "../../../images/logo.png"

import "./styles.css";

export default function PoweredBy() {
  return (
    <p>
      Powered by
      <img
        src={christeeLogo}
        className="christee-logo"
        alt="Christy"
      />
    </p>
  );
}
