import React, { Fragment } from 'react';
import { TEquityHeader, TEquityResults } from '../../../../types/api';
import { TdCash, TdDownloadPdfColSpan } from '../../../atoms';

interface Props {
  header: TEquityHeader;
  results: TEquityResults;
}

const Summary: React.FC<Props> = ({ header }) => {
  const txtHeader = Object.fromEntries(Object.entries(header).slice(2));
  console.log('Summary->txtHeader', txtHeader);
  const mad = Object.values(txtHeader);
  console.log('Summary->mad', mad);
  return (
    <Fragment>
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={7}>
              <b>Christee Equity Summary</b>
            </td>
          </tr>
          <tr>
            <td align="center">Monthly</td>
            <td align="center">Extra</td>
            <td align="center">Payoff</td>
            <td align="center">Total</td>
            <td align="center">Future</td>
            <td align="center">Projected</td>
            <td align="center">Projected Equity</td>
          </tr>
          <tr>
            <td align="center">Payment</td>
            <td align="center">Principal</td>
            <td align="center">Months</td>
            <td align="center">Interest</td>
            <td align="center">Value</td>
            <td align="center">Equity</td>
            <td align="center">Years</td>
          </tr>
          {mad &&
            mad.map((name: any) => {
              console.log(name);
              return (
                <tr>
                  <TdCash v={name[0]} />
                  <TdCash v={name[1]} />
                  <td align="center">{name[2]}</td>
                  <TdCash v={name[3]} />
                  <TdCash v={name[4]} />
                  <TdCash v={name[5]} />
                  <td align="center">{name[6]}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Fragment>
  );
};

const Detail: React.FC<Props> = ({ results }) => {
  const txtHeader = Object.fromEntries(Object.entries(results).slice(2));
  console.log('Detail->txtHeader', txtHeader);
  const mad = Object.values(txtHeader);
  console.log('Detail->mad', mad);
  return (
    <Fragment>
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={9}>
              <b>Equity on A Year To Year Basis</b>
            </td>
          </tr>
          <tr>
            <td align="center">Month</td>
            <td align="center">Year</td>
            <td align="center">Yearly</td>
            <td align="center">Yearly</td>
            <td align="center">Home</td>
            <td align="center">Mortage</td>
            <td align="center">Accured</td>
          </tr>
          <tr>
            <td align="center">Number</td>
            <td align="center">Number</td>
            <td align="center">Principal</td>
            <td align="center">Interest</td>
            <td align="center">Value</td>
            <td align="center">Balance</td>
            <td align="center">Equity</td>
          </tr>
          {mad &&
            mad.map((name: any) => (
              <tr>
                <td align="center">{name[0]}</td>
                <td align="center">{name[1]}</td>
                <TdCash v={name[2]} />
                <TdCash v={name[3]} />
                <TdCash v={name[4]} />
                <TdCash v={name[5]} />
                <TdCash v={name[6]} />
              </tr>
            ))}
          <tr>
            <TdDownloadPdfColSpan v={8} />
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

const Equity = (props: Props) => {
  return (
    <div>
      <Summary {...props} />
      <Detail {...props} />
    </div>
  );
};

export default Equity;
