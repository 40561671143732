import React, { useContext, useState } from 'react';

import { toast } from 'react-toastify';

import {
  TAmortizationResponse,
  TBudgetResponse,
  TBuyerQualResponse,
  TBuyersChoiceResponse,
  TBuyOrRentResponse,
  TBuyWaitResponse,
  TClosingCostResponse,
  THecmResponse,
  TInvestmentResponse,
  TSellerNetResponse,
  TVacationResponse,
  TConsumerDebtResponse,
  TPaymentSubsidyResponse,
} from '../../../types/api';
import { ButtonWithSpinner, UiSettingsProviderContext } from '../../atoms';

type Props = {
  dats:
    | TBuyerQualResponse
    | TAmortizationResponse
    | TBudgetResponse
    | TBuyersChoiceResponse
    | TBuyOrRentResponse
    | TBuyWaitResponse
    | TClosingCostResponse
    | TInvestmentResponse
    | THecmResponse
    | TSellerNetResponse
    | TVacationResponse
    | TConsumerDebtResponse
    | TPaymentSubsidyResponse;
};

export const ButtonSendPdf = ({ dats }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentSettings } = useContext(UiSettingsProviderContext);

  const onSendMail = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(currentSettings.sendPdfLink, {
        method: 'POST',
        body: JSON.stringify(dats),
      });
      const resData = await res.json();
      toast.success(resData.message);
      setIsLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="btn mx-2">
      <ButtonWithSpinner loadingIndicator="Sending..." loading={isLoading} onClick={onSendMail} variant="outline-primary" type="button">
        Send to email
      </ButtonWithSpinner>
    </div>
  );
};
