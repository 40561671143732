import React from 'react';
import { TSellerNetResponse } from '../../../../types/api';
import { DetailText, DetailTextCenter, TdBlank, TdCash, TdCashPercent, TdSellerNetLimitMessage } from '../../../atoms';

export type TSellerNetReport = TSellerNetResponse;

const SellerNet = ({ results }: TSellerNetReport) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <TdBlank />
          <DetailTextCenter v={results!.aCalc.fumess} />
        </tr>
        <tr>
          <TdBlank />
          <DetailTextCenter v={results!.aCalc.current_date} />
        </tr>
        <tr>
          <TdBlank />
          <DetailTextCenter v={results!.aCalc.report_type} />
        </tr>
        <tr>
          <DetailText v="Sales Price" />
          <TdCash v={results!.aCalc.sp} />
        </tr>
        <tr>
          <DetailText v="Mortgage Payoff" />
          <TdCash v={results!.aCalc.total_payoff} />
        </tr>
        <tr>
          <DetailText v="Real Estate Commission" />
          <TdCashPercent a={results!.aCalc.broker_fee} b={results!.aCalc.comm} />
        </tr>
        <tr>
          <DetailText v="Estimated Seller Proceeds" />
          <TdCash v={results!.aCalc.seller_net} />
        </tr>
        <tr>
          <TdSellerNetLimitMessage />
        </tr>
      </tbody>
    </table>
  );
};

export default SellerNet;
