import React, { useRef } from "react";

import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";

type Props = {
  children: JSX.Element;
  title?: string
}

const IconHint = ({ children, title }: Props) => {
  const ref = useRef(null);
  return (
    <div ref={ref} className="float-end" style={{position: 'absolute', right: 0, top: 0}}>
      <OverlayTrigger trigger="click" placement="right" overlay={(
        <Popover id="popover-contained" style={{minWidth: 300, maxWidth: 600, textAlign: 'left'}}>
          { title ? <Popover.Header as="h3">{title}</Popover.Header> : <></> }
          <Popover.Body>
            <div>{children || `Add Content to this overlay between the tags!!`}</div>
          </Popover.Body>
        </Popover>
      )}>
        <Button variant="link" ><InfoCircle /></Button>
      </OverlayTrigger>
    </div>
  );
};

export default IconHint;
