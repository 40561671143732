import React from 'react';
import FormInputText from '../FormInputText';

type FormInputUseRatiosProps = {
  name?: string;
  accountType?: string;
  tip: string;
  error: any;
  control: any;
  required?: boolean;
};

// this should always be displayed, it's not dependent on accountType
const FormInputEmail = ({ name = 'cust_email', accountType, ...rest }: FormInputUseRatiosProps) => {
  return accountType ? <FormInputText disabled={accountType === 'never'} label="Email Address" data-test={name} name={name} {...rest} /> : null;
};

export default FormInputEmail;
