/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { BuyersChoicePdf } from "@ask-christee/ui-pdf";

const PdfButton = ({ dat }: any) => {
  return (
    <PDFDownloadLink
      document={
        <BuyersChoicePdf
          dat={dat}
          title="Christee Report: Buyer PreQual"
          subtitle="Buyer Prequal"
        />
      }
      fileName="buyer-prequal.pdf"
      style={{
        textDecoration: "none",
        padding: "10px",
        color: "#4a4a4a",
        backgroundColor: "#f2f2f2",
        border: "1px solid #4a4a4a",
      }}
    >
      {({ loading }) =>
        loading ? "Loading PDF..." : "Download PDF"
      }
    </PDFDownloadLink>
  );
}

export default PdfButton;
