import React from "react";

import {Button, Form, Modal, Spinner} from "react-bootstrap";

import FormInputText from "../FormInputText";

type Props = {
  errors: any,
  control: any,
  show: boolean,
  hideModal: any,
  watchRates: any,
  handleSubmit: any,
  resetCalcForm: any,
  watchCalcForm: any,
  ratesToRender: any
}

const FormInterestRatesModal = ({
  show,
  hideModal,
  resetCalcForm,
  watchCalcForm,
  errors,
  watchRates,
  handleSubmit,
  ratesToRender,
  control
}: Props) => {
  const onSubmit = (data: any) => {
    resetCalcForm({
      ...watchCalcForm,
      ...data
    });
    hideModal();
  }

  return (
    <>
      <Modal
        show={show}
        onHide={hideModal}
        backdrop="static"
        centered
        size="lg"
        aria-labelledby="amortization-help-title"
      >
        <Modal.Header closeButton className="display">
          <h4 className="fw-bold px-4 my-0">Interest Rates</h4>
          <Modal.Title id="amortization-help-title">&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {watchRates.interest_rates ?
              <div className="container content spacebelow">
                <div className="row">
                  <Form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
                    <div className="px-4 d-flex justify-content-center">
                      <div className="mx-5">
                        <h3 className="text-center">30 Year</h3>
                        <div>
                          {Object.keys(ratesToRender).map((rate) => {
                             return <FormInputText key={ratesToRender[rate][0].name} label={rate} tip={ratesToRender[rate][0].tip} error={errors.interest_rates?.[ratesToRender[rate][0].name]} name={`interest_rates.[${ratesToRender[rate][0].name}]`} control={control} />
                            }
                          )}
                        </div>
                      </div>
                      <div className="mx-5">
                        <h3 className="text-center">15 Year</h3>
                        <div>
                          {Object.keys(ratesToRender).map((rate) => {
                              return ratesToRender[rate][1] && <FormInputText key={ratesToRender[rate][1].name} label={rate} tip={ratesToRender[rate][1].tip} error={errors.interest_rates?.[ratesToRender[rate][1].name]} name={`interest_rates.[${ratesToRender[rate][1].name}]`} control={control} />
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-100 d-flex justify-content-end">
                      <Button type="submit" style={{ marginRight: "115px" }} className="mt-4" variant="primary">
                        Save
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              : <div style={{ height: "350px" }} className="d-flex justify-content-center align-items-center">
                  <Spinner
                    className="loader"
                    animation="border"
                    variant="primary"
                  />
              </div>}

          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FormInterestRatesModal;
