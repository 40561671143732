/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { StyleSheet, View, Text } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    height: 16,
  },
  acalc: {
    width: '12%',
    fontSize: 9,
    textAlign: 'left',
  },
});

const PDFSummaryHeader = () => {
  const rows = (
    <View>
      <View style={styles.row}>
        <Text style={styles.acalc}>Monthly</Text>
        <Text style={styles.acalc}>Extra</Text>
        <Text style={styles.acalc}>Payoff</Text>
        <Text style={styles.acalc}>Total</Text>
        <Text style={styles.acalc}>Future</Text>
        <Text style={styles.acalc}>Projected</Text>
        <Text style={styles.acalc}>Projected Equity</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.acalc}>Payment</Text>
        <Text style={styles.acalc}>Prinipal</Text>
        <Text style={styles.acalc}>Months</Text>
        <Text style={styles.acalc}>Interest</Text>
        <Text style={styles.acalc}>Value</Text>
        <Text style={styles.acalc}>Equity</Text>
        <Text style={styles.acalc}>Years</Text>
      </View>
    </View>
  );
  return rows;
};

export default PDFSummaryHeader;
