import React, { Fragment } from 'react';
import { TAmortizationHeader, TAmortizationResults } from '../../../../types/api';
import { TdCash, TdPercent, TdDownloadPdfColSpan } from '../../../atoms';

interface Props {
  header: TAmortizationHeader;
  results: TAmortizationResults;
}

const Summary: React.FC<Props> = ({ header }) => {
  const txtHeader = Object.fromEntries(Object.entries(header).slice(2));
  console.log('Summary->txtHeader', txtHeader);
  const mad = Object.values(txtHeader);
  console.log('Summary->mad', mad);
  return (
    <Fragment>
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={7}>
              <b>Christee Amortization Summary</b>
            </td>
          </tr>
          <tr>
            <td align="center">Monthly</td>
            <td align="center">Original</td>
            <td align="center">Extra Principal</td>
            <td align="center">Payoff</td>
            <td align="center">Months</td>
            <td align="center">Total Interest</td>
          </tr>
          <tr>
            <td align="center">Payment</td>
            <td align="center">Term</td>
            <td align="center">Payment</td>
            <td align="center">Months</td>
            <td align="center">Reduced</td>
            <td align="center">Paid</td>
          </tr>
          {mad &&
            mad.map((name: any) => {
              console.log(name);
              return (
                <tr>
                  <TdCash v={name[0]} />
                  <td align="center">{name[1]}</td>
                  <TdCash v={name[2]} />
                  <td align="center">{name[3]}</td>
                  <td align="center">{name[4]}</td>
                  <TdCash v={name[5]} />
                </tr>
              );
            })}
        </tbody>
      </table>
    </Fragment>
  );
};

const Detail: React.FC<Props> = ({ results }) => {
  const txtHeader = Object.fromEntries(Object.entries(results).slice(2));
  console.log('Detail->txtHeader', txtHeader);
  const mad = Object.values(txtHeader);
  console.log('Detail->mad', mad);
  return (
    <Fragment>
      <table className="table">
        <tbody>
          <tr>
            <td colSpan={9}>
              <b>Amortization Details on A Year To Year Basis</b>
            </td>
          </tr>
          <tr>
            <td align="center">Month</td>
            <td align="center">Year</td>
            <td align="center">Year</td>
            <td align="center">Year</td>
            <td align="center">Interest</td>
            <td align="center">Year</td>
            <td align="center">Principal</td>
            <td align="center">Mortgage</td>
          </tr>
          <tr>
            <td align="center">Number</td>
            <td align="center">Number</td>
            <td align="center">Payment</td>
            <td align="center">Interest</td>
            <td align="center">Percent</td>
            <td align="center">Principal</td>
            <td align="center">Percent</td>
            <td align="center">Balance</td>
          </tr>
          {mad &&
            mad.map((name: any) => (
              <tr>
                <td align="center">{name[0]}</td>
                <td align="center">{name[1]}</td>
                <TdCash v={name[2]} />
                <TdCash v={name[3]} />
                <TdPercent v={name[4]} />
                <TdCash v={name[5]} />
                <TdPercent v={name[6]} />
                <TdCash v={name[7]} />
              </tr>
            ))}
          <tr>
            <TdDownloadPdfColSpan v={8} />
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

const Amortization = (props: Props) => {
  return (
    <div>
      <Summary {...props} />
      <Detail {...props} />
    </div>
  );
};

export default Amortization;
