/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ContactGenericButton from '../Forms/ContactGenericButton';
import {SettingsParamsType} from "../../atoms/UiSettingsProvider";

const ContactOptions = ({ isPartner, accountType }: SettingsParamsType) => {
  return isPartner || accountType === "user"
      ? <div className="contact">
          <Container>
            <Row></Row>
            <div className="d-flex mt-3">
              <ContactGenericButton />
            </div>
          </Container>
        </div>
      : null
};

export default ContactOptions;
