/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, Tab, Tabs } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { fetchHecm, hREVERSE } from '../../../../api';
import { checkAccessFullReport } from '../../../../helpers/checkAccessFullReport';
import { THecmResponse } from '../../../../types/api';
import {
  tipAge,
  tipClose_date,
  tipCust_name,
  tipEmail,
  tipHecmT_cash,
  tipInput_ltv,
  tipInput_ma,
  tipInput_orgfee,
  tipInput_rpt_age,
  tipProp_t,
  tipSp,
  tipZip,
  UiSettingsProviderContext,
} from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';
import {
  FormInputChangeRate,
  FormInputDatePicker2,
  FormInputDollar,
  FormInputEmail,
  FormInputNumber,
  FormInputPercent2,
  FormInputRadioButtons,
  FormInputStateAreaDropdowns,
  FormInputStateAreaDropdownsHandle,
  FormInputText,
  FormInputZip,
  ValidationModal,
} from '../../../molecules';
import { ButtonSendPdf } from '../../../molecules/ButtonSendPdf';
import { Help } from '../../../molecules/QuickStart';
import ContactOptions from '../../ContactOptions';
import { HecmReport, LimitHecmReport } from '../../Reports';
import PdfButton from './PdfButton';

const Hecm = () => {
  const defaultValues = {
    account_type: '',
    age: '',
    condo: '',
    cust_email: '',
    cust_name: '',
    cust_phone: '',
    input_dol_cred: '',
    input_ltv: '',
    input_ma: '',
    input_rpt_age: '',
    input_uw_fee: '',
    int_rate2: '',
    int_rate: '',
    lend_cred: '',
    lim_piti: '',
    m_debts: '',
    org_fee: '',
    prop_app: '',
    prop_t: '',
    q_income: '',
    report_years: '',
    s_cred: '',
    sp: '',
    t_cash: '',
    zip: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors, isValid, touchedFields, submitCount },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);

  const isAccessFullReport = checkAccessFullReport(currentSettings);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    console.log('useEffect->scrollTop');
    scrollTop();
  }, []);

  const [dats, setDats] = useState<THecmResponse>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [key, setKey] = useState<string | null>('results');

  const ctx = useContext(ChristeeConfigCtx);

  const stateAreaRef = useRef<FormInputStateAreaDropdownsHandle>(null);
  type TTerm = {
    value: string;
    text: string;
  };
  let termTypeaheadRef: Typeahead<TTerm> | null;
  let loanTypeaheadRef: Typeahead<TTerm> | null;

  const onSubmit = async (data: any) => {
    trigger();
    scrollTop();
    setButtonClicked(false);
    setLoading(true);
    await new Promise((r) => setTimeout(r, 700));

    console.log('data', data);
    console.log('watch', watch);
    console.log('errors', errors);

    const doTypeArr = data.doType && data.doType.length > 0 ? data.doType.map((m: any) => m.value) : [];
    const filteredData = {
      ...data,
      state: data.state[0].code,
      county_select: data.county_select[0].code,
      doType: doTypeArr,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const res = await fetchHecm(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );
      console.log('onSubmit->res', res);
      setDats(res);
      setLoaded(true);
      setLoading(false);
      setKey('results');
      console.log('dats after setDats', dats);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const fireButton = (data: any) => {
    console.log('fireButton->data', data);
    setKey('results');
    setButtonClicked(true);
  };

  const clearForm = (data: any) => {
    console.log('clearForm->data', data);
    console.log('clearForm->isValid', isValid);
    console.log('clearForm->errors', errors);
    console.log('clearForm->touchedFields', touchedFields);
    console.log('clearForm->submitCount', submitCount);
    console.log('clearForm->getValues->state', getValues('state'));
    stateAreaRef.current?.clear();
    loanTypeaheadRef?.clear();
    termTypeaheadRef?.clear();
    reset(defaultValues);
    setDats({} as THecmResponse);
    setLoaded(false);
    setLoading(false);
    setKey('results');
    scrollTop();
  };

  return (
    <div className="buyers-choice">
      <div className="container-fluid">
        <div className="row g-2">
          <div className="col-md-3 col-xl-3">
            <h3 className="h2">Reverse Mortgage</h3>
            <form className="christy" autoComplete="off">
              <fieldset>
                <legend className="sr-only">Required Fields:</legend>
                <FormInputStateAreaDropdowns setValue={setValue} stateWatch={watch('state')} areaWatch={watch('county_select')} control={control} errors={errors} ref={stateAreaRef} />
                <FormInputZip label="Zip Code" tip={tipZip} error={errors.zip} name="zip" control={control} required={true} />
                <FormInputChangeRate watchCalcForm={watch()} resetCalcForm={reset} selectedRates={['HECM']} />
                <FormInputNumber label="Age" tip={tipAge} error={errors.age} name="age" control={control} required={true} min={40} max={114} />
              </fieldset>
              <Accordion id="optional-inputs">
                <Accordion.Item eventKey="0">
                  <Accordion.Header data-cy="btn-optional">Optional Inputs</Accordion.Header>
                  <Accordion.Body>
                    <fieldset>
                      <FormInputDollar label="Sales Price" error={errors.sp} name="sp" control={control} tip={tipSp} />
                      <FormInputDollar label="Enter Cash" tip={tipHecmT_cash} error={errors.t_cash} name="t_cash" control={control} />
                      <FormInputDollar label="Annual Property Taxes" tip={tipProp_t} error={errors.prop_t} name="prop_t" control={control} />
                      <FormInputDatePicker2 label="Close Date" tip={tipClose_date} error={errors.close_date} name="close_date" control={control} />
                      <FormInputPercent2 label="Down Payment" tip={tipInput_ltv} error={errors.input_ltv} name="input_ltv" control={control} />
                      <FormInputDollar label="Limit Loan Amt" tip={tipInput_ma} error={errors.input_ma} name="input_ma" control={control} />
                      <FormInputNumber label="Age for HECM Balance" tip={tipInput_rpt_age} error={errors.input_rpt_age} name="input_rpt_age" control={control} min={62} max={114} />
                      <FormInputRadioButtons
                        label="Include Origination Fee"
                        name="input_orgfee"
                        tip={tipInput_orgfee}
                        defaultValue="n"
                        ids={['input_orgfee_yes', 'input_orgfee_no']}
                        control={control}
                        errors={errors}
                      />
                      <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                      <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                      <FormInputEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                    </fieldset>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <p className="mt-3">
                <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />
                <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                  Clear
                </button>
              </p>
            </form>
          </div>
          <div className="col-md-9 col-xl-9">
            <div className="form-results">
              {loading ? (
                <img id="processed-image" src="https://www.askchristee.com/images/processed.gif" alt="processed" />
              ) : loaded ? (
                <Tabs id="controlled-tab" activeKey={key as string} onSelect={(k) => setKey(k)} className="mb-3">
                  <Tab eventKey="results" title="Reverse Mortgage Results">
                    {loaded ? (
                      <>
                        <br />
                        {isAccessFullReport ? <HecmReport {...dats!} /> : <LimitHecmReport {...dats!} />}
                        <div className="btn btn-generate-pdf" onClick={fireButton}>
                          <div className="btn btn-generate-pdf" onClick={fireButton}>
                            <button className="btn btn-outline-primary" type="button">
                              Generate PDF
                            </button>
                          </div>
                        </div>
                        {buttonClicked ? (
                          <div className="btn btn-download-pdf">
                            <PdfButton dat={dats!} />
                          </div>
                        ) : null}
                        {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                      </>
                    ) : !loading ? (
                      <h3>Complete Form and Press Submit</h3>
                    ) : (
                      <></>
                    )}
                  </Tab>
                </Tabs>
              ) : (
                <>
                  <h4 id="house-title" className="h4">
                    You're seconds away from unlocking your Reverse Mortgage data.
                  </h4>
                  <img id="house-image" src="https://www.askchristee.com/images/main.png" alt="home" />
                  <Help sFile={hREVERSE} />
                </>
              )}
            </div>
            {/* only guests can see this */}
            {loaded && <ContactOptions {...currentSettings} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hecm;
