import React from "react";
import { Form } from 'react-bootstrap';
import ToolTipPrompt from "../ToolTipPrompt";
import { IDefaultProps } from '../../organisms';

export interface ITermFieldProps extends IDefaultProps {
  register: any,
  label: string,
  ids: string,
  tip: string,
  errors: any,
  defaultValue?: string | number
}

const TermField = ({ register, label, ids, tip, errors, defaultValue }: ITermFieldProps ) => {
  return (
    <>
      <div className="form-group">
        {errors.term && false && <div className="alert-expand">Term Required</div>}
      </div>

      <div className="form-group">
        <div className="input-group">
          <div className="input-group-prepend">
                      <span className={errors.term ? "input-group-text alert-expand" : "input-group-text"} >
                        <ToolTipPrompt tip={tip}
                                       prompt={errors.term ? label + ' Required' : label}
                                       alert={errors.term}
                        />
                      </span>
          </div>
          <Form.Select
            id={ids}
            className="form-control ps-2"
            {...register(ids, { required: true })}
            data-cy={ids}
            defaultValue={defaultValue}
          >
            <option value="">Select</option>
            <option value="10">10 Years</option>
            <option value="15">15 Years</option>
            <option value="20">20 Years</option>
            <option value="30">30 Years</option>
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default TermField;
