import React from 'react';
import { Text } from '@react-pdf/renderer';

export interface IVProps {
  v: string | number;
}

export interface IVTProps {
  v: string;
  t: string;
}

export interface IABProps {
  a: string | number;
  b: string | number;
}

export interface IABCDProps {
  a: string;
  b: string;
  c: string;
  d: string;
}

export interface IVPromptProps {
  v: number | string;
  prompt: string;
}

export interface IVPromptPProps {
  v: number;
  prompt: string;
  p: string | number;
}

export interface IBlank {}

export interface IPrefixVProps {
  prefix: string;
  v: string;
}

/**
 * Old function, should be deprecated
 * use TdCash instead.
 * @param {*} param0
 */
const DetailCashNaked = ({ v }: IVProps) => TdCash({ v });

/**
 * Use TdPercent
 */
const DetailPercent = ({ v }: IVProps) => TdPercent({ v });

function DetailTextBold({ v }: IVProps) {
  return (
    <td>
      <strong>{v}</strong>
    </td>
  );
}

function DetailTextBoldTip({ v, t }: IVTProps) {
  return (
    <td data-tip data-for={t}>
      <strong>{v}</strong>
    </td>
  );
}

/**
 * Place text in <td align="center">
 * This is being deprecated to use the more expressive TdAlignCenter
 * @param {String} v
 */
const DetailTextCenter = ({ v }: IVProps) => TdCenter({ v });

/**
 * Format given text inside a <td>
 * @param {string} String to be displayed placed
 */
const DetailText = ({ v }: IVProps) => TdText({ v });

export interface IDolPerText {
  dollAmt: string;
  percAmt: string;
  txtDisplay: string;
}

function DolPerText({ dollAmt, percAmt, txtDisplay }: IDolPerText) {
  const x = addCommas(dollAmt);
  return (
    <td align="center">
      ${x}
      &nbsp;
      {percAmt}
      %&nbsp;
      {txtDisplay}
    </td>
  );
}

export interface IDolText {
  dollAmt: string;
  txtDisplay: string;
}

function DolText({ dollAmt, txtDisplay }: IDolText) {
  const x = addCommas(dollAmt);
  return (
    <td align="center">
      ${x}
      &nbsp;
      {txtDisplay}
    </td>
  );
}

function HtmlDetailTextTwo({ a, b }: IABProps) {
  const x = addCommas(a as string);
  return (
    <td align="center">
      ${x}
      &nbsp;
      {b}%
    </td>
  );
}

function HtmlDetailTextThree({ a, b, c, d }: IABCDProps) {
  return (
    <td align="center">
      {a}
      &nbsp;$
      {b}
      &nbsp;
      {c}
      &nbsp;
      {d}%
    </td>
  );
}

function HtmlDetailTextFour({ a, b }: IABProps) {
  return (
    <td align="center">
      {a}
      &nbsp;$
      {b}
    </td>
  );
}

function HtmlDetailTextFive({ a, b }: IABProps) {
  return (
    <td align="center">
      ${a}
      &nbsp; After {b} years
    </td>
  );
}

function HtmlDetailTextSix({ a }: { a: string | number }) {
  return (
    <td align="center">
      ${a}
      &nbsp; in home ownership
    </td>
  );
}

function PdfCash({ v }: IVProps) {
  const nV: number = +v;
  if (nV > 999) {
    v = addCommas(v as string);
  }
  return <Text>${v}</Text>;
}

function PdfCashPrompt({ v, prompt }: IVPromptProps) {
  let sv = '';
  if (v) {
    typeof v === 'string' ? v : v.toString();
    if (v > 999) {
      sv = addCommas(v.toString());
    } else {
      sv = v.toString();
    }
  }

  return (
    <Text>
      {prompt}
      &nbsp;$
      {sv}
      &nbsp;&nbsp;
    </Text>
  );
}

function PdfCashPromptWithPercent({ v, prompt, p }: IVPromptPProps) {
  let sv: string = '';
  if (v) {
    if (v > 999) {
      sv = addCommas(v.toString());
    } else {
      sv = v.toString();
    }
  }

  return (
    <Text>
      {prompt}
      &nbsp;$
      {sv}
      &nbsp;
      {p}
      %&nbsp;
    </Text>
  );
}

function PdfPercent({ v }: IVProps) {
  const x = addCommas(v as string);
  return <Text>{x}%</Text>;
}

function PdfPercentPrompt({ v, prompt }: IVPromptProps) {
  return (
    <Text>
      {prompt}
      &nbsp;
      {v}
      %&nbsp;&nbsp;
    </Text>
  );
}

function PdfPromptCashPercentParens({ v, prompt, p }: IVPromptPProps) {
  let sv: string = '';
  if (v) {
    if (v > 999) {
      sv = addCommas(v.toString());
    } else {
      sv = v.toString();
    }
  }
  return (
    <Text>
      {prompt}
      &nbsp;$
      {sv}
      &nbsp;(
      {p}
      %)&nbsp;
    </Text>
  );
}

function PdfText({ v }: IVProps) {
  return (
    <Text>
      &nbsp;
      {v}
    </Text>
  );
}

/**
 * https://stackoverflow.com/questions/3753483/javascript-thousand-separator-string-format/19840881
 * @param {*} nStr
 */
const addCommas = (nStr: string) => {
  nStr += '';
  const x = nStr?.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

/**
 * Returns param in td with a % suffix
 * @param {v} param0
 */
function TdPercent({ v }: IVProps) {
  return <td align="center">{v}%</td>;
}

function TdPrefixPrompt({ a, b }: IABProps) {
  return (
    <td align="center">
      {a}
      &nbsp;
      {b}
    </td>
  );
}

/**
 * Returns param in td with a % suffix
 * @param {v} param0
 */
function TdBlank() {
  return <td align="center">&nbsp;</td>;
}

function TdCash({ v }: IVProps) {
  const x = addCommas(v as string);
  return <td align="center">${x}</td>;
}

function TdCashPercent({ a, b }: IABProps) {
  const x = addCommas(a as string);
  return (
    <td align="center">
      ${x}
      &nbsp;
      {b}%
    </td>
  );
}

/**
 * Place text in <td align="center">
 * @param {String} v
 */
function TdCenter({ v }: IVProps) {
  return <td align="center">{v}</td>;
}

/**
 * Format given text inside a <td>
 * @param {string} String to be displayed placed
 */
function TdText({ v }: IVProps) {
  return <td>{v}</td>;
}

/**
 * Format given text inside a <td>
 * @param {string} String to be displayed placed
 */
function TdTextPrefix({ prefix, v }: IPrefixVProps) {
  return (
    <td>
      {prefix}
      &nbsp;
      {v}
    </td>
  );
}

/**
 * Format given text inside a <td>
 * @param {string} String to be displayed placed
 */
function TdTextTwoElements({ a, b }: IABProps) {
  return (
    <td>
      {a}
      &nbsp;
      {b}
    </td>
  );
}

export {
  DetailCashNaked,
  DetailPercent,
  DetailText,
  DetailTextBold,
  DetailTextBoldTip,
  DetailTextCenter,
  DolPerText,
  DolText,
  HtmlDetailTextTwo,
  HtmlDetailTextThree,
  HtmlDetailTextFour,
  HtmlDetailTextFive,
  HtmlDetailTextSix,
  PdfCash,
  PdfCashPrompt,
  PdfCashPromptWithPercent,
  PdfPercent,
  PdfPercentPrompt,
  PdfPromptCashPercentParens,
  PdfText,
  TdBlank,
  TdCash,
  TdCashPercent,
  TdCenter,
  TdPercent,
  TdPrefixPrompt,
  TdText,
  TdTextPrefix,
  TdTextTwoElements,
};
