import React from 'react';
import { TInvestmentCalc } from '../../../../types/api';
import { TdCash, TdCashPercent, TdCenter, TdInvestmentLimitMessage, TdPercent, TdPrefixPromptNoCenter, TdText } from '../../../atoms';

interface Props {
  calc: TInvestmentCalc;
}

const Investment: React.FC<Props> = ({ calc }) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <TdText v="" />
          {calc && <td align="center">{calc.report_type}</td>}
        </tr>
        <tr>
          <TdText v="State" />
          {calc && <TdCenter v={calc.state_sel.toString()} />}
        </tr>
        <tr>
          <TdText v="Selected Area" />
          {calc && <TdCenter v={calc.area.toString()} />}
        </tr>
        <tr>
          <TdText v="Purchase Price" />
          {calc && <TdCash v={calc.sp.toString()} />}
        </tr>
        <tr>
          <TdText v="Cash Investment" />
          {calc && <TdCash v={calc.r_cash.toString()} />}
        </tr>
        <tr>
          <TdText v="Acquisition Cost" />
          {calc && <TdCash v={calc.acq_cost.toString()} />}
        </tr>
        <tr>
          <TdText v="Loan Amount" />
          {calc && <TdCashPercent a={calc.ma.toString()} b={calc.ltv.toString()} />}
        </tr>
        <tr>
          <TdText v="Interest Rate" />
          {calc && <TdPercent v={calc.temp_rate.toString()} />}
        </tr>
        <tr>
          <TdText v="Total Monthly Payment" />
          {calc && <TdCash v={calc.t_piti.toString()} />}
        </tr>
        <tr>
          <TdText v="Average Monthly Rent" />
          {calc && <TdCash v={calc.avg_mo_rent.toString()} />}
        </tr>
        <tr>
          <TdText v="Annual Net Operating Income" />
          {calc && <TdCash v={calc.noi.toString()} />}
        </tr>
        <tr>
          <TdText v="Annual Debt Service" />
          {calc && <TdCash v={calc.debt_ser.toString()} />}
        </tr>
        <tr>
          <TdPrefixPromptNoCenter a={calc.cash_flow_$} b="Annual Cash Flow" />
          {calc && <TdCash v={calc.cash_flow.toString()} />}
        </tr>
        <tr>
          <TdInvestmentLimitMessage />
        </tr>
      </tbody>
    </table>
  );
};

export default Investment;
