import React from 'react';

import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';

type FormInputDollarProps = {
  label: string;
  tip?: string;
  name: string;
  error: any;
  control: any;
  required?: boolean;
  validate?: any;
};

const FormInputDollar = ({ label, tip, error, name, control, required = false, validate }: FormInputDollarProps) => {
  const options = {
    required: required,
    pattern: {
      value: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.|\.\d{1,2})?$/,
      message: 'Numeric required',
    },
    validate: validate,
  };

  const errorMessage = required || validate ? (label.length < 21 ? 'Required' : 'Req.') : label.length < 21 ? 'Incorrect' : 'Inc.';

  return (
    <div className="sp-input">
      <FloatingLabel controlId="floatingSelect" label={error ? label + ' ' + errorMessage : label} className={error ? 'alert-expand' : ''}>
        <Controller
          name={name}
          defaultValue=""
          rules={options}
          control={control}
          render={({ field }) => (
            <NumberFormat
              id={name}
              type="text"
              placeholder=""
              data-cy={name}
              data-test={name}
              thousandSeparator
              className="form-control"
              autoComplete="ignore-me"
              aria-label="Amount (to the nearest dollar) for label"
              {...field}
            />
          )}
        />
        <i>$</i>
        {tip ? (
          <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
            <a className="tooltippanel">
              <FontAwesomeIcon icon={faInfoCircle} />
            </a>
          </OverlayTrigger>
        ) : (
          <></>
        )}
      </FloatingLabel>
    </div>
  );
};

export default FormInputDollar;
