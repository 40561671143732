import React from 'react';

/**
 * @param {string | number} a
 *
 * Returns a string with the format: "This is the text."
 */

interface LocalProps {
  a: string | number;
}

export const TextOnly = ({ a }: LocalProps) => {
  return <td align="left">{a}</td>;
};
