import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

const Description = styled.Text`
  text-align: left;
  width: '100%';
  font-size: 9px;
`;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 1,
  },
  row: {
    flexDirection: 'row',
    height: 16,
  },
});

function AgentOfficerDocId({ dat }: any) {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.row}>
        <Description />
      </View>
      <View style={styles.row}>
        {dat.coverPage && (
          <Description>
            Document Id:
            {dat.coverPage.doc_id}
          </Description>
        )}
      </View>
    </View>
  );
}

export default AgentOfficerDocId;
