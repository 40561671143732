/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Typeahead } from 'react-bootstrap-typeahead';

type FormInputMultiSelectProps = {
  errors: any;
  control: any;
  required?: boolean;
  tip?: string;
  watch: any;
  name: string;
  label: string;
  selectOptions: Array<{
    value: string;
    text: string;
  }>;
};

// export type FormInputMultiSelectHandle = {
//   clear: () => void;
// };

const FormInputMultiSelect = (props: FormInputMultiSelectProps) => {
  const name = props.name;
  const label = props.label;
  const tip = props.tip;
  const options = {
    required: props.required || false,
  };

  const [selected, setSelected] = useState<TLoan[]>([]);

  type TLoan = {
    value: string;
    text: string;
  };
  let loanTypeaheadRef: Typeahead<TLoan> | null;

  const renderMenuItemChildren = (option: TLoan) => {
    const isSelected = selected.find((f) => f.value === option.value);

    return (
      <div>
        {isSelected ? (
          <>
            <span>
              <b>{option.text}</b>
            </span>
            <FontAwesomeIcon className="dropdown-check" icon={faCheck} />
          </>
        ) : (
          <span>{option.text}</span>
        )}
      </div>
    );
  };

  const groupBy = (list: TLoan[], keyGetter: (x: TLoan) => string) => {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };

  const onSelection = (selection: any, onChange: any) => {
    if (selection.length === selected.length + 1) {
      loanTypeaheadRef?.blur();
      loanTypeaheadRef?.focus();
    }

    const selectedGroups = groupBy(selection, (x) => x.value);
    const dupes = Array.from(selectedGroups).map((m) => {
      return m[1].length > 1 ? {} : m[1][0];
    });
    const newSelected = selection.filter((item: TLoan) => dupes.find((f) => f.value === item.value));
    setSelected(newSelected);
    onChange(newSelected);
    loanTypeaheadRef?.hideMenu();
  };

  // useImperativeHandle(, () => ({
  //   clear() {
  //     loanTypeaheadRef?.clear();
  //     setSelected([]);
  //   },
  // }));

  const toggleLoanTypeahead = () => {
    loanTypeaheadRef?.toggleMenu();
  };

  const filterBy = () => {
    return true;
  };

  return (
    <div className="loan-input">
      <FloatingLabel
        controlId="floatingSelect"
        label={props.errors[name] ? label + (props.required ? ' Required' : ' Incorrect') : props.watch && props.watch.length > 0 ? label + '(s)' : 'Select ' + label + '(s)'}
        className={props.errors[name] ? 'alert-expand' : ''}
      >
        {/*
               // @ts-ignore */}
        <Controller
          name={name}
          rules={options}
          control={props.control}
          render={({ field }) => (
            <Typeahead
              multiple
              filterBy={filterBy}
              {...field}
              id={name}
              ref={(typeahead) => (loanTypeaheadRef = typeahead)}
              labelKey="text"
              options={props.selectOptions}
              renderMenuItemChildren={renderMenuItemChildren}
              selected={selected}
              onChange={(selection) => onSelection(selection, field.onChange)}
              inputProps={{
                autoComplete: 'ignore-me',
                shouldSelectHint: (shouldSelect, e) => {
                  return e.keyCode === 9 || e.keyCode === 13 || shouldSelect;
                },
              }}
            >
              <img className="select-down loan-down" alt="select-down" onClick={toggleLoanTypeahead} />
            </Typeahead>
          )}
        />
        <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
          <a className={props.errors[name] ? 'tooltiplabel alert-expand' : 'tooltippanel'}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </OverlayTrigger>
      </FloatingLabel>
    </div>
  );
};

export default FormInputMultiSelect;
