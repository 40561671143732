import React from 'react';
import { TPaymentSubsidyResponseObject } from '../../../../types/api';
import { TdDownloadPdfColSpan, TextOnly } from '../../../atoms';

const PaymentSubsidy = (res: TPaymentSubsidyResponseObject | undefined) => {
  return (
    <table className="table">
      <tbody>
        <tr>{res!.browser && <TextOnly a={res!.browser.fumess} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess1} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess2} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess3} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess4} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess5} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess6} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess7} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess8} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess9} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess10} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess11} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess12} />}</tr>
        <tr>{res!.browser && <TextOnly a={res!.browser.brwmess13} />}</tr>
        <tr>
          <TdDownloadPdfColSpan v={3} />
        </tr>
      </tbody>{' '}
    </table>
  );
};

export default PaymentSubsidy;
