/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Accordion, Tab, Tabs } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormInputDollar, FormInputEmail, FormInputNumber, FormInputPercent2, FormInputTerm, FormInputTermHandle, FormInputText, FormInputZip, ValidationModal } from '../../../molecules';
import PdfButton from './PdfButton';
import { Help } from '../../../molecules/QuickStart';
import { tipAmortInput_ma, tipCust_name, tipEmail, tipEx_prin, tipInt_rate, tipShorterTerm, tipZip } from '../../../atoms';
import { ChristeeConfigCtx } from '../../../context';
import { AmortizationReport } from '../../Reports';
import { fetchAmortization, hAMORTIZATION } from '../../../../api';
import { TAmortizationResponse } from '../../../../types/api';
import { UiSettingsProviderContext } from '../../../atoms';
import { ButtonSendPdf } from '../../../molecules/ButtonSendPdf';
import ContactOptions from '../../ContactOptions';

const Amortization = () => {
  const defaultValues = {
    account_type: '',
    condo: '',
    cust_email: '',
    cust_name: '',
    cust_phone: '',
    ex_prin: '',
    input_dol_cred: '',
    input_ltv: '',
    input_ma: '',
    input_term: '',
    input_uw_fee: '',
    int_rate: '',
    lend_cred: '',
    lim_piti: '',
    m_debts: '',
    org_fee: '',
    prop_t: '',
    q_income: '',
    s_cred: '',
    sp: '',
    t_cash: '',
    zip: '',
  };
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    trigger,
    formState: { errors, isValid, touchedFields, submitCount },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { currentSettings } = useContext(UiSettingsProviderContext);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    console.log('useEffect->scrollTop');
    scrollTop();
  }, []);

  const [dats, setDats] = useState<TAmortizationResponse>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [key, setKey] = useState<string | null>('cash');
  const ctx = useContext(ChristeeConfigCtx);
  const termRef = useRef<FormInputTermHandle>(null);

  const onSubmit = async (data: any) => {
    await trigger();
    scrollTop();
    setButtonClicked(false);
    setLoading(true);
    await new Promise((r) => setTimeout(r, 800));
    console.log('data', data);
    console.log('watch', watch);
    console.log('errors', errors);

    const filteredData = {
      ...data,
      term: Array.isArray(data.term) ? data.term[0].value : data.term ? data.term : '30',
    };

    try {
      let captchaToken;
      if (executeRecaptcha) {
        captchaToken = await executeRecaptcha();
      }
      const res = await fetchAmortization(
        {
          ...filteredData,
          company_name: currentSettings.companyId,
          logged_in_email: currentSettings.email,
          logged_in_name: currentSettings.name,
          logged_in_phone: currentSettings.phone,
          account_type: currentSettings.accountType,
        },
        ctx?.stage,
        ctx?.key,
        captchaToken
      );
      console.log('onSubmit->res', res);
      setDats(res);
      setLoaded(true);
      setLoading(false);
      setKey('cash');
      console.log('dats after setDats', dats);
      scrollTop();
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const fireButton = (data: any) => {
    console.log('fireButton->data', data);
    setKey('cash');
    setButtonClicked(true);
  };

  const clearForm = (data: any) => {
    console.log('clearForm->data', data);
    console.log('clearForm->isValid', isValid);
    console.log('clearForm->errors', errors);
    console.log('clearForm->touchedFields', touchedFields);
    console.log('clearForm->submitCount', submitCount);
    console.log('clearForm->getValues->state', getValues('state'));
    termRef.current?.clear();
    reset(defaultValues);
    setDats({} as TAmortizationResponse);
    setLoaded(false);
    setLoading(false);
    setKey('cash');
    scrollTop();
  };

  return (
    <div className="buyers-choice">
      <div className="container-fluid">
        <div className="row g-2">
          <div className="col-md-3 col-xl-3">
            <h3 className="h2">Amortization</h3>
            <form className="christy" autoComplete="off">
              <fieldset>
                <legend className="sr-only">Required Fields:</legend>
                <FormInputPercent2 label="Interest Rate" tip={tipInt_rate} error={errors.int_rate} name="int_rate" required={true} control={control} />
                <FormInputDollar label="Beginning Loan Amt" tip={tipAmortInput_ma} name="input_ma" required={true} control={control} error={errors.input_ma} />
                <FormInputTerm watch={watch('term')} ref={termRef} control={control} errors={errors} required={true} />
                <FormInputZip label="Zip Code" tip={tipZip} error={errors.zip} name="zip" control={control} required={true} />
              </fieldset>
              <Accordion id="optional-inputs">
                <Accordion.Item eventKey="0">
                  <Accordion.Header data-cy="btn-optional">Optional Inputs</Accordion.Header>
                  <Accordion.Body>
                    <fieldset>
                      <FormInputDollar label="Extra Principal Monthly" tip={tipEx_prin} name="ex_prin" control={control} error={errors.ex_prin} />
                      {/* TODO - add in 10 to 29 range - Please enter a number between 10 and 29 only. */}
                      <FormInputNumber label="Shorter Term" tip={tipShorterTerm} error={errors.input_term} name="input_term" control={control} />
                      <FormInputText label="Name" tip={tipCust_name} error={errors.cust_name} name="cust_name" control={control} />
                      <FormInputText label="Phone" tip={tipCust_name} error={errors.cust_phone} name="cust_phone" control={control} />
                      <FormInputEmail accountType={currentSettings.accountType} tip={tipEmail} error={errors.cust_email} control={control} />
                    </fieldset>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <p className="mt-3">
                <ValidationModal label="Submit" errors={errors} onClick={handleSubmit(onSubmit)} />
                <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                  Clear
                </button>
              </p>
            </form>
          </div>

          <div className="col-md-9 col-xl-9">
            <div className="form-results">
              {loading ? (
                <img id="processed-image" src="https://www.askchristee.com/images/processed.gif" alt="processed" />
              ) : loaded ? (
                <Tabs id="controlled-tab" activeKey={key as string} onSelect={(k) => setKey(k)} className="mb-3">
                  <Tab eventKey="cash" title="Amortization Results">
                    {loaded ? (
                      <>
                        <br />
                        <AmortizationReport {...dats!} />
                        {(currentSettings.accountType === 'agent' || currentSettings.accountType === 'loan officer') && (
                          <div className="btn btn-generate-pdf" onClick={fireButton}>
                            <button className="btn btn-outline-primary" type="button">
                              Generate PDF
                            </button>
                          </div>
                        )}
                        {buttonClicked ? (
                          <div className="btn btn-download-pdf">
                            <PdfButton dat={dats!} />
                          </div>
                        ) : null}
                        {dats?.meta.email && dats?.meta.email !== 'nil' && <ButtonSendPdf dats={dats} />}
                      </>
                    ) : !loading ? (
                      <h3>Complete Form and Press Submit</h3>
                    ) : (
                      <></>
                    )}
                  </Tab>
                </Tabs>
              ) : (
                <>
                  <h4 id="house-title" className="h4">
                    You're seconds away from unlocking your Amortization data.
                  </h4>
                  <img id="house-image" src="https://www.askchristee.com/images/main.png" alt="home" />
                  <Help sFile={hAMORTIZATION} />
                </>
              )}
            </div>
            {/* only guests can see this */}
            {loaded && <ContactOptions {...currentSettings} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Amortization;
