import React from "react";
import { IDefaultProps } from '..';
// import { RegisterOptions } from 'react-hook-form';

export interface IRadioRequiredProps extends IDefaultProps {
        register: any,
        prompt: string,
        ids: string
}

const RadioRequired = ({ register, prompt, ids }: IRadioRequiredProps ) => {
  let radioYes = ids + "_yes";
  let radioNo = ids + "_no";
  return (
    <div className="form-group">
      <label htmlFor={ids} data-type="info" data-tip data-for={ids}>
        {prompt}
      </label>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          id={radioYes}
          value="y"
          data-cy={radioYes}
          {...register(ids, { required: true })}
        />
        <label className="form-check-label">Yes</label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          id={radioNo}
          value="n"
          data-cy={radioNo}
          {...register(ids, { required: true })}
        />
        <label className="form-check-label">No</label>
      </div>
    </div>
  );
}

export default RadioRequired;
