/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 *
 * The API expects the following values:
 *
 * {
 *   "name": "Jane Realtakins",
 *   "license_number": "12345",
 *   "phone": "301-999-9090",
 *   "email": "jane@example.com",
 *   "message": "Please help me",
 *   "zip1": "20122",
 *   "zip2": "20123",
 *   "zip3": "20127",
 *   "profile_complete": "true"
 * }
 */

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { postPricingRequest as CONTACT_API } from '../../../../api';
import {
  ButtonWithSpinner,
  States,
  tipPricingEmail,
  tipPricingLicenseNumber,
  tipPricingMessage,
  tipPricingName,
  tipPricingPhone,
  tipPricingZip1,
  tipPricingZip2,
  tipPricingZip3,
} from '../../../atoms';
import { FormInputRadioButtons, FormInputSelect, FormInputText } from '../../../molecules';

const stateList = States.map(({ code, text }) => ({ value: code, text }));

const RequestPricing = () => {
  const defaultValues = {
    email: '',
    name: '',
    phone: '',
  };
  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });

  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  useEffect(() => {
    scrollTop();
  }, []);

  const clearForm = () => {
    reset(defaultValues);
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const res = await CONTACT_API(data);
      if (res) {
        setIsSubmit(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row g-2">
        <div className="col-md-8 col-xl-8">
          {isSubmit ? (
            <div style={{ height: '200px' }}>
              <p className="mt-3">Thank you for your interest. Please be patient while we respond.</p>
            </div>
          ) : (
            <form className="christy" autoComplete="off">
              <fieldset>
                <div className="mt-3">
                  <FormInputText required={true} label="Name" tip={tipPricingName} error={errors.name} name="name" control={control} />
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="w-100 me-1">
                    <FormInputText required={true} label="Phone" tip={tipPricingPhone} error={errors.phone} name="phone" control={control} />
                  </div>
                  <div className="w-100 ms-1">
                    <FormInputText name="email" label="Email Address" required={true} data-test="email" tip={tipPricingEmail} error={errors.email} control={control} />
                  </div>
                </div>
                <div className="mt-3">
                  <FormInputText required={true} label="Message" tip={tipPricingMessage} error={errors.message} name="message" control={control} />
                </div>
                <div className="mt-3">
                  <FormInputSelect selectOptions={stateList} name="state" label="State" watch={watch('state')} control={control} errors={errors} required={true} />
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <div>
                    <FormInputText required={true} label="Zip Code 1" tip={tipPricingZip1} error={errors.zip1} name="zip1" control={control} />
                  </div>
                  <div className="mx-2">
                    <FormInputText required={true} label="Zip Code 2" tip={tipPricingZip2} error={errors.zip2} name="zip2" control={control} />
                  </div>
                  <div>
                    <FormInputText required={true} label="Zip Code 3" tip={tipPricingZip3} error={errors.zip3} name="zip3" control={control} />
                  </div>
                </div>
                <div className="mt-3">
                  <FormInputRadioButtons
                    label="Profile Complete"
                    name="profile_complete"
                    tip="Choose complete status"
                    defaultValue="n"
                    required={true}
                    ids={['married', 'single']}
                    values={['y', 'n']}
                    control={control}
                    errors={errors}
                  />
                </div>
                <div className="mt-3">
                  <FormInputText required={true} label="License Number" tip={tipPricingLicenseNumber} error={errors.license_number} name="license_number" control={control} />
                </div>
              </fieldset>
              <p className="mt-3">
                <ButtonWithSpinner variant="outline-secondary" loading={loading} type="submit" spinnerVariant="secondary" onClick={handleSubmit(onSubmit)}>
                  Submit
                </ButtonWithSpinner>
                <button type="reset" className="btn btn-outline-secondary me-2 mx-2" onClick={clearForm}>
                  Clear
                </button>
              </p>
            </form>
          )}
        </div>
        <div className="col-md-9 col-xl-9">
          <div className="form-results"></div>
        </div>
      </div>
    </div>
  );
};

export default RequestPricing;
