import React from 'react';
import { IDefaultMoleculeProps } from '..';
import { useController } from 'react-hook-form';

const FormInputNoTip = ({ register, name, options, control, error, label = 'Example Label', ids, ...inputProps }: IDefaultMoleculeProps) => {
  if (register) {
    console.log('FormInputNoTip->register', register);
  }
  // FC/control added
  const { field } = useController({ name, control: control, rules: options });

  return (
    <>
      <div className="form-group">
        {error && <div className="alert-expand">{error.message}</div>}
        <label className="sr-only" htmlFor="credits">
          {prompt}
        </label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" data-for={ids}>
              {label}
            </span>
          </div>
          <input className="form-control" {...field} data-cy={ids} data-test={ids} {...inputProps} />
        </div>
      </div>
    </>
  );
};

export default FormInputNoTip;
