import React, { FC } from 'react';
import { IDefaultMoleculeProps } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useController } from 'react-hook-form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const FormInputPercent: FC<IDefaultMoleculeProps> = ({ register, name, options, control, error, label = 'Example Label', ids, tip = 'Example Tip', ...inputProps }: IDefaultMoleculeProps) => {
  if (register) {
    console.log('FormInputPercent->register', register)
  }
  // FC/control added
  const { field } = useController({ name, control: control, rules: options });

  return (
    <>
      <div className="form-group">
        {error && <div className="alert-expand">{error.message}</div>}
        <label className="sr-only" htmlFor="credits">
          {label}
        </label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" data-tip data-for={ids}>
              <OverlayTrigger placement="right" overlay={<Tooltip>{tip}</Tooltip>}>
                <a
                  href="#"
                  className="tooltiplabel"
                >
                  <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;{label}
                </a>
              </OverlayTrigger>
            </span>
          </div>
          <input
            className="form-control"
            {...field}
            id={ids}
            data-cy={ids}
            {...inputProps}
          />
          <div className="input-group-append">
            <span className="input-group-text" id="basic-addon2">
              %
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormInputPercent;
