/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';
import { Controller } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { InputProps, Typeahead } from 'react-bootstrap-typeahead';

type SelectItem = {
  value: string;
  text: string;
};

type FormInputSelectProps = {
  errors: any;
  control: any;
  required?: boolean;
  tip?: string;
  watch: any;
  name: string;
  label: string;
  selectOptions: Array<SelectItem>;
};

export type FormInputLoanTypeSingleHandle = {
  clear: () => void;
};

interface MyInputProps extends InputProps {
  'data-cy'?: string;
}

const FormInputSelect = (props: FormInputSelectProps) => {
  const name = props.name;
  const label = props.label;
  const tip = props.tip;
  const options = {
    required: props.required || false,
  };

  let loanTypeaheadRef: Typeahead<SelectItem> | null;

  // useImperativeHandle(ref, () => ({
  //   clear() {
  //     console.log('FormInputLoanTypeSingle->clear called!');
  //     loanTypeaheadRef?.clear();
  //   },
  // }));

  const toggleLoanTypeahead = (data: any) => {
    console.log('toggleTermeTypeahead->data', data);
    loanTypeaheadRef?.toggleMenu();
  };

  const filterBy = () => {
    return true;
  };

  return (<div className="loan-input-single">
      <FloatingLabel
        controlId="floatingSelect"
        label={props.errors[name] ? label + (props.required ? ' Required' : ' Incorrect') : props.watch && props.watch.length > 0 ? label + '' : 'Select ' + label + ''}
        className={props.errors[name] ? 'alert-expand' : ''}
      >
        <Controller
          name={name}
          rules={options}
          control={props.control}
          render={({ field }) => (
            <Typeahead
              filterBy={filterBy}
              {...field}
              id="doType"
              inputProps={{ 'data-cy': 'doType', autoComplete: 'ignore-me' } as MyInputProps}
              ref={(typeahead) => (loanTypeaheadRef = typeahead)}
              labelKey="text"
              options={props.selectOptions}
            >
              <img className="select-down loan-down" alt="select-down" onClick={toggleLoanTypeahead} />
            </Typeahead>
          )}
        />
        <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
          <a className={props.errors[name] ? 'tooltiplabel alert-expand' : 'tooltippanel'}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </OverlayTrigger>
      </FloatingLabel>
    </div>
  );
};

export default FormInputSelect;
