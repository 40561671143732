import React from "react"

import {Card} from "react-bootstrap";
import IconHint from "../IconHint";

import "./styles.css";

type Props = {
  onClick: () => void;
  hintText: string;
  title: string,
  imageSrc: string
}

const ActionCard = ({ onClick, hintText, title, imageSrc }: Props) => {
  return (
    <Card className="action-card">
      <IconHint>
        <p className="text-left">
          {hintText}
        </p>
      </IconHint>
      <div onClick={onClick}>
        <img
          src={imageSrc}
          alt="text"
          className="action-card__img"
        />
        <p className="action-card__title">{title}</p>
      </div>
    </Card>
  )
}

export default ActionCard;
