import React from "react";
import { IDefaultProps } from '../../organisms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface IToolTipPromptProps extends IDefaultProps {
  tip: string,
  prompt: string,
  alert?: boolean
}

/**
 * This ToolTipPrompt and it's accompanying css was supplied by Sherri.
 * The tip will display on hover over the icon.  Also, the
 * tip will stay present if the user clicks on the icon.
 *
 * @param {dat} tip content
 * @param {prompt} prompt content
 */
const ToolTipPrompt = ({ tip, prompt, alert=false }: IToolTipPromptProps) => {
  // trigger="click" if you want to see the css
  return (
    <OverlayTrigger placement="right" overlay={<Tooltip>{tip}</Tooltip>}>
      <a
        href="#"
        className={ alert ? "tooltiplabel alert-expand" : "tooltippanel" }
      >
        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;{prompt}
      </a>
    </OverlayTrigger>
  );
}

export default ToolTipPrompt;
