/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { BuyOrRentPdf } from "@ask-christee/ui-pdf";

/**
 * NOTE: title is not passed as in other calls for the pdf.
 * title is not passed as a prop here because amortization has two sections
 */
const PdfHelper = ({ dat }: any) => {
  return (
    <PDFDownloadLink
      document={<BuyOrRentPdf dat={dat} />}
      fileName="buy-or-rent.pdf"
      style={{
        textDecoration: "none",
        padding: "10px",
        color: "#4a4a4a",
        backgroundColor: "#f2f2f2",
        border: "1px solid #4a4a4a",
      }}
    >
      {({ loading }) =>
        loading ? "Loading PDF..." : "Download PDF"
      }
    </PDFDownloadLink>
  );
}

export default PdfHelper;
