import React from 'react';

/**
 * @param {string | number} a
 * @param {string | number} b
 *
 * Returns a string with the format: "This is the prompt $100.00"
 */

interface LocalProps {
  a: string | number;
  b: string | number;
  c: string | number;
  d: string | number;
  e: string | number;
  f: string | number;
}

export const TextDollarTextPercentValueText = ({ a, b, c, d, e, f }: LocalProps) => {
  return (
    <td align="left">
      {a}&nbsp;${b}&nbsp;{c}&nbsp;{d}%&nbsp;{e}&nbsp;{f}
    </td>
  );
};
