/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { AgChartOptions } from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface Props {
  report_type: string;
  fumess: string;
  temp_pi: Number;
  temp_pt: Number;
  temp_mi: Number;
  temp_ins: Number;
  condo: Number;
  t_piti: Number;
}

const GenericPie = ({ report_type, fumess, temp_pi, temp_pt, temp_ins, temp_mi, condo, t_piti }: Props) => {
  console.log(fumess);
  const options: AgChartOptions = {
    title: {
      text: report_type,
    },
    subtitle: {
      text: 'Monthly Payment: ' + t_piti.toString(),
    },
    container: document.getElementById('myChart'),
    data: [
      { label: 'Principal Interest', value: temp_pi },
      { label: 'Property Taxes', value: temp_pt },
      { label: 'Home Insurance', value: temp_ins },
      { label: 'Mortgage Insurance', value: temp_mi },
      { label: 'Condo', value: condo },
    ],
    series: [
      {
        type: 'pie',
        angleKey: 'value',
        labelKey: 'label',
      },
    ],
    legend: {
      position: 'right',
      spacing: 40,
    },
    padding: {
      top: 40,
      right: 40,
      bottom: 40,
      left: 40,
    },
  };

  return (
    <Container>
      <Row>
        <Col>
          <AgChartsReact options={options} />
        </Col>
      </Row>
    </Container>
  );
};

export default GenericPie;
