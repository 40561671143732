import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { BudgetPdf } from "@ask-christee/ui-pdf"
import { TBudgetResponse } from "../../../../types/api";

const PdfHelper = (dats : TBudgetResponse) => {
  return (
    <PDFDownloadLink
      document={<BudgetPdf dat={dats} />}
      fileName="budget.pdf"
      style={{
          textDecoration: "none",
          padding: "9px",
          color: "#4a4a4a",
          backgroundColor: "#f2f2f2",
          border: "1px solid #4a4a4a",
          borderRadius: "5px",
      }}
    >
      {({ loading }) =>
        loading ? "Loading PDF..." : "Download PDF"
      }
    </PDFDownloadLink>
  );
}

export default PdfHelper;
