import React from "react";
import { IDefaultProps } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export interface ITextFieldRequiredTipProps extends IDefaultProps {
register: any,
                  prompt: string,
                  ids: string,
                          tip: string
}


const TextFieldRequiredTip = ({ register, prompt, ids, tip }: ITextFieldRequiredTipProps ) => {
  return (
    <div className="form-group">
      <label className="sr-only" htmlFor="credits">
        {prompt}
      </label>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" data-tip data-for={ids}>
            <a
              className="infotip"
              href="#"
              data-toggle="tooltip"
              data-placement="right"
              title={tip}
            >
            <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;{prompt}
            </a>
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder=""
          id={ids}
          data-cy={ids}
          {...register(ids, { required: true })}
        />
      </div>
    </div>
  );
}

export default TextFieldRequiredTip;
