import React from "react";
import { IDefaultProps } from '..';

const DownloadPdfMessage = ({}: IDefaultProps) => {
  return (
    <tr>
      <td>
        <strong>Download PDF file for complete details</strong>
      </td>
      <td>&nbsp;</td>
    </tr>
  );
}

export default DownloadPdfMessage;
