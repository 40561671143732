import React from "react";

import { Image, StyleSheet } from "@react-pdf/renderer";

import logo from "../../../../images/logo.png";

const styles = StyleSheet.create({
  logo: {
    width: 100,
    height: 20
  }
});

function PdfReportLogo() {
  return <Image style={styles.logo} src={logo} />;
}

export default PdfReportLogo;
