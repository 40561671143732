import React from 'react';
import { TVacationReport } from '..';
import { DetailText, DetailTextCenter, TdCash, TdCashPercent, TdPercent, TdTextTwoElementsCenter, TdVactionLimitMessage } from '../../../atoms';

const VacationThreeFour = ({ results }: TVacationReport) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <DetailText v="Selected Location" />
          {results!.aCalc3 && <TdTextTwoElementsCenter b={results!.aCalc3.state_sel} a={results!.aCalc3.area} />}
          {results!.aCalc4 && <TdTextTwoElementsCenter b={results!.aCalc4.state_sel} a={results!.aCalc4.area} />}
        </tr>
        <tr>
          <DetailText v="Report Type" />
          {results!.aCalc3 && <TdTextTwoElementsCenter a={results!.aCalc3.report_type} b={results!.aCalc3.fumess} />}
          {results!.aCalc4 && <TdTextTwoElementsCenter a={results!.aCalc4.report_type} b={results!.aCalc4.fumess} />}
        </tr>
        <tr>
          <DetailText v="Christee Ratios Used" />
          {results!.aCalc3 && <DetailTextCenter v={results!.aCalc3.ratio_string} />}
          {results!.aCalc4 && <DetailTextCenter v={results!.aCalc4.ratio_string} />}
        </tr>
        <tr>
          <DetailText v="Purchase Price" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.sp} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.sp} />}
        </tr>
        <tr>
          <DetailText v="Loan Amount" />
          {results!.aCalc3 && <TdCashPercent a={results!.aCalc3.ma} b={results!.aCalc3.ltv} />}
          {results!.aCalc4 && <TdCashPercent a={results!.aCalc4.ma} b={results!.aCalc4.ltv} />}
        </tr>
        <tr>
          <DetailText v="Required Cash" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.r_cash} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.r_cash} />}
        </tr>
        <tr>
          <DetailText v="Cash Available" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.t_cash} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.t_cash} />}
        </tr>
        <tr>
          <DetailText v="Interest Rate" />
          {results!.aCalc3 && <TdPercent v={results!.aCalc3.temp_rate} />}
          {results!.aCalc4 && <TdPercent v={results!.aCalc4.temp_rate} />}
        </tr>
        <tr>
          <DetailText v="Total Monthly Payment" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.t_piti} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.t_piti} />}
        </tr>
        <tr>
          <TdVactionLimitMessage />
        </tr>
      </tbody>
    </table>
  );
};

export default VacationThreeFour;
