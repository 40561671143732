import React, {useContext} from 'react';
import { TBudgetCalc } from '../../../../types/api';
import {
  DolPerText,
  DolText,
  TdAlternativePdfMessage,
  TdCash,
  TdCenter,
  TdDownloadPdfColSpan,
  TdPercent,
  TdText, UiSettingsProviderContext
} from '../../../atoms';

interface Props {
  calc: TBudgetCalc;
}

export type TDatBudget = Props;

const Budget: React.FC<Props> = ({ calc }) => {
  const { currentSettings } = useContext(UiSettingsProviderContext);

  return (
    <table className="table">
      <tbody>
        <tr>
          <TdText v="" />
          {calc && <td align="center">{calc.report_type}&nbsp;Estimates</td>}
        </tr>
        <tr>
          <TdText v="" />
          {calc && (
            <td align="center">
              {calc.fumess}&nbsp;{calc.dis_mtge}
            </td>
          )}
        </tr>
        <tr>
          <TdText v="State" />
          {calc && <TdCenter v={calc.state_sel} />}
        </tr>
        <tr>
          <TdText v="Selected Area" />
          {calc && <TdCenter v={calc.area} />}
        </tr>
        <tr>
          <TdText v="Purchase Price" />
          {calc && <TdCash v={calc.sp.toString()} />}
        </tr>
        <tr>
          <TdText v="Base Loan Amount" />
          {calc && <DolPerText dollAmt={calc.ma.toString()} percAmt={calc.ltv.toString()} txtDisplay="LTV" />}
        </tr>
        <tr>
          <TdText v="Total Loan Amount" />
          {calc && <DolPerText dollAmt={calc.total_mtge.toString()} percAmt={calc.temp_rate.toString()} txtDisplay="Rate" />}
        </tr>
        <tr>
          <TdText v="Total Mortgage Payment" />
          {calc && <TdCash v={calc.t_piti.toString()} />}
        </tr>
        <tr>
          <TdText v="Estimated Federal Taxes" />
          {calc && <TdCash v={calc.mfed_taxes.toString()} />}
        </tr>
        <tr>
          <TdText v="Estimated Social Security" />
          {calc && <TdCash v={calc.ss_with.toString()} />}
        </tr>
        <tr>
          <TdText v="Estimated Medicare Tax" />
          {calc && <TdCash v={calc.med_with.toString()} />}
        </tr>
        <tr>
          <TdText v="Estimated State Income Tax" />
          {calc && <TdCash v={calc.mstate_tax.toString()} />}
        </tr>
        <tr>
          <TdText v="Alimony" />
          {calc && <TdCash v={calc.alimony.toString()} />}
        </tr>
        <tr>
          <TdText v="Cable / Wifi" />
          {calc && <TdCash v={calc.cable.toString()} />}
        </tr>
        <tr>
          <TdText v="Car Payments" />
          {calc && <TdCash v={calc.car_pay.toString()} />}
        </tr>
        <tr>
          <TdText v="Cell Phone" />
          {calc && <TdCash v={calc.c_phone.toString()} />}
        </tr>
        <tr>
          <TdText v="Child Care" />
          {calc && <TdCash v={calc.c_care.toString()} />}
        </tr>
        <tr>
          <TdText v="Child Support" />
          {calc && <TdCash v={calc.c_support.toString()} />}
        </tr>
        <tr>
          <TdText v="Credit Cards" />
          {calc && <TdCash v={calc.c_cards.toString()} />}
        </tr>
        <tr>
          <TdText v="Food" />
          {calc && <DolText dollAmt={calc.food.toString()} txtDisplay={calc.dis_food} />}
        </tr>
        <tr>
          <TdText v="Insurance Health" />
          {calc && <TdCash v={calc.health_ins.toString()} />}
        </tr>
        <tr>
          <TdText v="Insurance Auto" />
          {calc && <TdCash v={calc.auto_ins.toString()} />}
        </tr>
        <tr>
          <TdText v="Pet Expenses" />
          {calc && <TdCash v={calc.pets.toString()} />}
        </tr>
        <tr>
          <TdText v="Retirement Fund Monthly" />
          {calc && <TdCash v={calc.retire.toString()} />}
        </tr>
        <tr>
          <TdText v="School Tuition" />
          {calc && <TdCash v={calc.coll_debt.toString()} />}
        </tr>
        <tr>
          <TdText v="Utilities Monthly" />
          {calc && (
            <td align="center">
              ${calc.utilities}&nbsp;{calc.dis_util}
            </td>
          )}
        </tr>
        {calc && calc.otherd && +calc.other_amt > 1 && (
          <tr>
            <TdText v={calc.otherd} />
            {calc && <TdCash v={calc.other_amt.toString()} />}
          </tr>
        )}
        <tr>
          <TdText v="" />
          {calc && <td align="center">&nbsp;</td>}
        </tr>
        <tr>
          <TdText v="Total Monthly Obligations" />
          {calc && <TdCash v={calc.total_exp.toString()} />}
        </tr>
        <tr>
          <TdText v="Obligations as % of Income" />
          {calc && <TdPercent v={calc.total_exp_per.toString()} />}
        </tr>
        <tr>
          <TdText v="Discretionary Income" />
          {calc && <TdCash v={calc.f_momey.toString()} />}
        </tr>
        <tr>
          <TdText v="Percent Free Money" />
          {calc && <TdPercent v={calc.f_money_per.toString()} />}
        </tr>
        <tr>
          {(currentSettings.accountType === "agent" || currentSettings.accountType === "loan officer") ? <TdDownloadPdfColSpan v={2} /> : <TdAlternativePdfMessage />}
        </tr>
      </tbody>
    </table>
  );
};

export default Budget;
