import ak from './data/ak.json';
import al from './data/al.json';
import ar from './data/ar.json';
import az from './data/az.json';
import ca from './data/ca.json';
import co from './data/co.json';
import ct from './data/ct.json';
import dc from './data/dc.json';
import de from './data/de.json';
import fl from './data/fl.json';
import ga from './data/ga.json';
import hi from './data/hi.json';
import ia from './data/ia.json';
import id from './data/id.json';
import il from './data/il.json';
import indiana from './data/in.json';
import ks from './data/ks.json';
import ky from './data/ky.json';
import la from './data/la.json';
import ma from './data/ma.json';
import md from './data/md.json';
import me from './data/me.json';
import mi from './data/mi.json';
import mn from './data/mn.json';
import mo from './data/mo.json';
import ms from './data/ms.json';
import mt from './data/mt.json';
import nc from './data/nc.json';
import nd from './data/nd.json';
import ne from './data/ne.json';
import nh from './data/nh.json';
import nj from './data/nj.json';
import nm from './data/nm.json';
import nv from './data/nv.json';
import ny from './data/ny.json';
import oh from './data/oh.json';
import ok from './data/ok.json';
import or from './data/or.json';
import pa from './data/pa.json';
import ri from './data/ri.json';
import sc from './data/sc.json';
import sd from './data/sd.json';
import tn from './data/tn.json';
import tx from './data/tx.json';
import ut from './data/ut.json';
import va from './data/va.json';
import vt from './data/vt.json';
import wa from './data/wa.json';
import wi from './data/wi.json';
import wv from './data/wv.json';
import wy from './data/wy.json';

const Areas = (stateWatch: any) => {
  var data = [];
  if (stateWatch === 'ak') {
     data = ak;
  } else if (stateWatch === 'al') {
     data = al;
  } else if (stateWatch === 'ar') {
     data = ar;
  } else if (stateWatch === 'az') {
     data = az;
  } else if (stateWatch === 'ca') {
     data = ca;
  } else if (stateWatch === 'co') {
     data = co;
  } else if (stateWatch === 'ct') {
     data = ct;
  } else if (stateWatch === 'dc') {
     data = dc;
  } else if (stateWatch === 'de') {
     data = de;
  } else if (stateWatch === 'fl') {
     data = fl;
  } else if (stateWatch === 'ga') {
     data = ga;
  } else if (stateWatch === 'hi') {
     data = hi;
  } else if (stateWatch === 'ia') {
     data = ia;
  } else if (stateWatch === 'id') {
     data = id;
  } else if (stateWatch === 'il') {
     data = il;
  } else if (stateWatch === 'in') {
     data = indiana;
  } else if (stateWatch === 'ks') {
     data = ks;
  } else if (stateWatch === 'ky') {
     data = ky;
  } else if (stateWatch === 'la') {
     data = la;
  } else if (stateWatch === 'ma') {
     data = ma;
  } else if (stateWatch === 'md') {
     data = md;
  } else if (stateWatch === 'me') {
     data = me;
  } else if (stateWatch === 'mi') {
     data = mi;
  } else if (stateWatch === 'mn') {
     data = mn;
  } else if (stateWatch === 'mo') {
     data = mo;
  } else if (stateWatch === 'ms') {
     data = ms;
  } else if (stateWatch === 'mt') {
     data = mt;
  } else if (stateWatch === 'nc') {
     data = nc;
  } else if (stateWatch === 'nd') {
     data = nd;
  } else if (stateWatch === 'ne') {
     data = ne;
  } else if (stateWatch === 'nh') {
     data = nh;
  } else if (stateWatch === 'nj') {
     data = nj;
  } else if (stateWatch === 'nm') {
     data = nm;
  } else if (stateWatch === 'nv') {
     data = nv;
  } else if (stateWatch === 'ny') {
     data = ny;
  } else if (stateWatch === 'oh') {
     data = oh;
  } else if (stateWatch === 'ok') {
     data = ok;
  } else if (stateWatch === 'or') {
     data = or;
  } else if (stateWatch === 'pa') {
     data = pa;
  } else if (stateWatch === 'ri') {
     data = ri;
  } else if (stateWatch === 'sc') {
     data = sc;
  } else if (stateWatch === 'sd') {
     data = sd;
  } else if (stateWatch === 'tn') {
     data = tn;
  } else if (stateWatch === 'tx') {
     data = tx;
  } else if (stateWatch === 'ut') {
     data = ut;
  } else if (stateWatch === 'va') {
     data = va;
  } else if (stateWatch === 'vt') {
     data = vt;
  } else if (stateWatch === 'wa') {
     data = wa;
  } else if (stateWatch === 'wi') {
     data = wi;
  } else if (stateWatch === 'wv') {
     data = wv;
  } else if (stateWatch === 'wy') {
     data = wy;
  } else {
     data = md;
  }

  let selectOptions = [];
  let v = data;

  if (stateWatch) {
    for (let i = 0; i < v.length; i++) {
      selectOptions.push(v[i]);
    }
  } else {
    selectOptions = [];
  }
  return selectOptions;
}

export default Areas;
