import React from "react";
import { IDefaultProps } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export interface IRadioFilingStatusProps extends IDefaultProps {
  register: any,
  prompt: string,
  ids: string,
  tip: 'tip1' | 'tip2' | string
}

const RadioFilingStatus = ({ register, prompt='Yes or No?', ids, tip }: IRadioFilingStatusProps) => {
  let radioYes = ids + "_yes";
  let radioNo = ids + "_no";

  return (
    <div className="form-group">
      <div className="input-group">
        <label htmlFor={ids} data-type="info" data-tip data-for={ids}>
          <a
            className="infotip"
            href="#"
            data-toggle="tooltip"
            data-placement="right"
            title={tip}
            >
            <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;{prompt}
          </a>
        </label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id={radioYes}
            value="m"
            data-cy={radioYes}
            {...register(ids)}
          />
          <label className="form-check-label" htmlFor="exempt_yes">
            Yes
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id={radioNo}
            value="s"
            data-cy={radioNo}
            {...register(ids)}
          />
          <label className="form-check-label" htmlFor="exempt_no">
            No
          </label>
        </div>
      </div>
    </div>
  );
}

export default RadioFilingStatus;
