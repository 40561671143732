import React from 'react';
import { TBuyersChoiceResponseObject } from '../../../../types/api';
import { BrowMess, DetailCashNaked, DetailText, DetailTextCenter, HtmlDetailTextTwo, TdBuyerQualLimitMessage } from '../../../atoms';

interface Props {
  conventional: TBuyersChoiceResponseObject;
  fha: TBuyersChoiceResponseObject;
  va: TBuyersChoiceResponseObject;
  usda: TBuyersChoiceResponseObject;
}

const BuyerChoiceCash: React.FC<Props> = ({ conventional, fha, va, usda }) => {
  if (conventional.bCalc) {
    var aText = conventional.bCalc.brow_head;
  } else {
    var aText = '';
  }
  if (fha.bCalc) {
    var bText = fha.bCalc.brow_head;
  } else {
    var bText = '';
  }
  if (va.bCalc) {
    var cText = va.bCalc.brow_head;
  } else {
    var cText = '';
  }
  if (usda.bCalc) {
    var dText = usda.bCalc.brow_head;
  } else {
    var dText = '';
  }

  return (
    <table className="table">
      <tbody>
        <tr>
          <td colSpan={5}>
            <BrowMess a={aText} b={bText} c={cText} d={dText} />
          </td>
        </tr>
        <tr>
          <DetailText v="Loan Type" />
          {conventional?.bCalc ? <DetailTextCenter v={conventional.bCalc.report_type} /> : null}
          {fha?.bCalc ? <DetailTextCenter v={fha.bCalc.report_type} /> : null}
          {va?.bCalc ? <DetailTextCenter v={va.bCalc.report_type} /> : null}
          {usda?.bCalc ? <DetailTextCenter v={usda.bCalc.report_type} /> : null}
        </tr>
        <tr>
          <DetailText v="Alerts" />
          {conventional?.bCalc ? <DetailTextCenter v={conventional?.bCalc?.fumess} /> : null}
          {fha?.bCalc ? <DetailTextCenter v={fha?.bCalc?.fumess} /> : null}
          {va?.bCalc ? <DetailTextCenter v={va?.bCalc?.fumess} /> : null}
          {usda?.bCalc ? <DetailTextCenter v={usda?.bCalc?.fumess} /> : null}
        </tr>
        <tr className="active-row">
          <DetailText v="Purchase Price" />
          {conventional?.bCalc ? <DetailCashNaked v={conventional?.bCalc?.sp} /> : null}
          {fha?.bCalc ? <DetailCashNaked v={fha?.bCalc?.sp} /> : null}
          {va?.bCalc ? <DetailCashNaked v={va?.bCalc?.sp} /> : null}
          {usda?.bCalc ? <DetailCashNaked v={usda?.bCalc?.sp} /> : null}
        </tr>
        <tr className="active-row">
          <DetailText v="Base Loan Amount" />
          {conventional?.bCalc ? <HtmlDetailTextTwo a={conventional?.bCalc?.ma} b={conventional?.bCalc?.ltv} /> : null}
          {fha?.bCalc ? <HtmlDetailTextTwo a={fha?.bCalc?.ma} b={fha?.bCalc?.ltv} /> : null}
          {va?.bCalc ? <HtmlDetailTextTwo a={va?.bCalc?.ma} b={va?.bCalc?.ltv} /> : null}
          {usda?.bCalc ? <HtmlDetailTextTwo a={usda?.bCalc?.ma} b={usda?.bCalc?.ltv} /> : null}
        </tr>
        <tr className="active-row">
          <DetailText v="Required Cash" />
          {conventional?.bCalc ? <DetailCashNaked v={conventional?.bCalc?.r_cash} /> : null}
          {fha?.bCalc ? <DetailCashNaked v={fha?.bCalc?.r_cash} /> : null}
          {va?.bCalc ? <DetailCashNaked v={va?.bCalc?.r_cash} /> : null}
          {usda?.bCalc ? <DetailCashNaked v={usda?.bCalc?.r_cash} /> : null}
        </tr>
        <tr>
          <DetailText v="Cash available" />
          {conventional?.bCalc ? <DetailCashNaked v={conventional?.bCalc?.t_cash} /> : null}
          {fha?.bCalc ? <DetailCashNaked v={fha?.bCalc?.t_cash} /> : null}
          {va?.bCalc ? <DetailCashNaked v={va?.bCalc?.t_cash} /> : null}
          {usda?.bCalc ? <DetailCashNaked v={usda?.bCalc?.t_cash} /> : null}
        </tr>
        <tr className="active-row">
          <DetailText v="Total Monthly Payment" />
          {conventional?.bCalc ? <DetailCashNaked v={conventional?.bCalc?.t_piti} /> : null}
          {fha?.bCalc ? <DetailCashNaked v={fha?.bCalc?.t_piti} /> : null}
          {va?.bCalc ? <DetailCashNaked v={va?.bCalc?.t_piti} /> : null}
          {usda?.bCalc ? <DetailCashNaked v={usda?.bCalc?.t_piti} /> : null}
        </tr>
        <tr>
          <DetailText v="Principal &amp; Interest" />
          {conventional?.bCalc ? <HtmlDetailTextTwo a={conventional?.bCalc.temp_pi} b={conventional?.bCalc.res_pi} /> : null}
          {fha?.bCalc ? <HtmlDetailTextTwo a={fha?.bCalc.temp_pi} b={fha?.bCalc.res_pi} /> : null}
          {va?.bCalc ? <HtmlDetailTextTwo a={va?.bCalc.temp_pi} b={va?.bCalc.res_pi} /> : null}
          {usda?.bCalc ? <HtmlDetailTextTwo a={usda?.bCalc.temp_pi} b={usda?.bCalc.res_pi} /> : null}
        </tr>
        <tr>
          <DetailText v="Monthly Mortgage Insurance" />
          {conventional?.bCalc ? <HtmlDetailTextTwo a={conventional?.bCalc.temp_mi} b={conventional?.bCalc.res_mi} /> : null}
          {fha?.bCalc ? <HtmlDetailTextTwo a={fha?.bCalc.temp_mi} b={fha?.bCalc.res_mi} /> : null}
          {va?.bCalc ? <HtmlDetailTextTwo a={va?.bCalc.temp_mi} b={va?.bCalc.res_mi} /> : null}
          {usda?.bCalc ? <HtmlDetailTextTwo a={usda?.bCalc.temp_mi} b={usda?.bCalc.res_mi} /> : null}
        </tr>
        <tr>
          <DetailText v="Monthly Property Taxes" />
          {conventional?.bCalc ? <HtmlDetailTextTwo a={conventional?.bCalc.temp_pt} b={conventional?.bCalc.res_pt} /> : null}
          {fha?.bCalc ? <HtmlDetailTextTwo a={fha?.bCalc.temp_pt} b={fha?.bCalc.res_pt} /> : null}
          {va?.bCalc ? <HtmlDetailTextTwo a={va?.bCalc.temp_pt} b={va?.bCalc.res_pt} /> : null}
          {usda?.bCalc ? <HtmlDetailTextTwo a={usda?.bCalc.temp_pt} b={usda?.bCalc.res_pt} /> : null}
        </tr>
        <tr>
          <DetailText v="Monthly Property Insurance" />
          {conventional?.bCalc ? <HtmlDetailTextTwo a={conventional?.bCalc.temp_ins} b={conventional?.bCalc.res_ins} /> : null}
          {fha?.bCalc ? <HtmlDetailTextTwo a={fha?.bCalc.temp_ins} b={fha?.bCalc.res_ins} /> : null}
          {va?.bCalc ? <HtmlDetailTextTwo a={va?.bCalc.temp_ins} b={va?.bCalc.res_ins} /> : null}
          {usda?.bCalc ? <HtmlDetailTextTwo a={usda?.bCalc.temp_ins} b={usda?.bCalc.res_ins} /> : null}
        </tr>
        <tr>
          <DetailText v="Monthly Condo/HOA fee" />
          {conventional?.bCalc ? <HtmlDetailTextTwo a={conventional?.bCalc.condo} b={conventional?.bCalc.res_condo} /> : null}
          {fha?.bCalc ? <HtmlDetailTextTwo a={fha?.bCalc.condo} b={fha?.bCalc.res_condo} /> : null}
          {va?.bCalc ? <HtmlDetailTextTwo a={va?.bCalc.condo} b={va?.bCalc.res_condo} /> : null}
          {usda?.bCalc ? <HtmlDetailTextTwo a={usda?.bCalc.condo} b={usda?.bCalc.res_condo} /> : null}
        </tr>
        <tr>
          <TdBuyerQualLimitMessage />
        </tr>
      </tbody>
    </table>
  );
};

export default BuyerChoiceCash;
