import React from "react";
import { IDefaultProps } from '../../organisms';

export interface IRadioOptionalProps extends IDefaultProps {
  register: any,
  prompt: string,
  ids: string
}

const RadioOptional = ({ register, prompt, ids }: IRadioOptionalProps ) => {
  let radioYes = ids + "_yes";
  let radioNo = ids + "_no";
  return (
    <div className="form-group">
      <div className="input-group">
        <div className="input-group-prepend">
                    <span className="input-group-text" >
          <label htmlFor={ids} data-type="info" data-tip data-for={ids}>
            {prompt}
          </label>
                    </span>
        </div>
        <div style={{ padding: 10 }}>
          <div className="form-check form-check-inline">
            <label className="form-check-label" htmlFor="exempt_yes">
              <input
                className="form-check-input"
                type="radio"
                id={radioYes}
                value="y"
                data-cy={radioYes}
                {...register(ids)}
              />Yes</label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label" htmlFor="exempt_no">
              <input
                className="form-check-input"
                type="radio"
                id={radioNo}
                value="n"
                data-cy={radioNo}
                {...register(ids)}
              />No</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RadioOptional;
