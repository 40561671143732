import { SettingsParamsType } from '../components/atoms/UiSettingsProvider';
// 2022-10-10 no more need to limit access to full report, per conversation with Gary
export const checkAccessFullReport = (currentSettings: SettingsParamsType) => {
  if ((currentSettings.accountType === 'agent' || currentSettings.accountType === 'loan officer' || currentSettings.accountType === 'user-guest') && currentSettings.initialCompletion) {
    var z = currentSettings.isApproved || new Date(new Date().getTime() - new Date(currentSettings.initialCompletion).getTime()).getDay() <= 7;
    console.log('z', z);
    return true;
  }
  return true;
};
