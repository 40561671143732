/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { EquityPdf } from '@ask-christee/ui-pdf';

/**
 * NOTE: title is not passed as in other calls for the pdf.
 * title is not passed as a prop here because amortization has two sections
 */
function PdfHelper({ dat }: any) {
  return (
    <PDFDownloadLink
      document={<EquityPdf dat={dat} />}
      fileName="equity.pdf"
      style={{
        textDecoration: 'none',
        padding: '10px',
        color: '#4a4a4a',
        backgroundColor: '#f2f2f2',
        border: '1px solid #4a4a4a',
      }}
    >
      {({ loading }) => (loading ? 'Loading PDF...' : 'Download PDF')}
    </PDFDownloadLink>
  );
}

export default PdfHelper;
