import { Document, Page, StyleSheet, Text } from "@react-pdf/renderer";
import React, { Fragment } from "react";
import {
  PdfReportTitle,
  PdfReportSubtitle,
  AgentOfficerDocId,
} from "../../../atoms";
import PdfReportCashAcalc from "./PdfReportCashAcalc";
import PdfReportCashBcalc from "./PdfReportCashBcalc";
import PdfClosingAcalc from "../ClosingAcalc";
import PdfClosingBcalc from "../ClosingBcalc";
import PdfMessageAcalc from "./PdfMessageAcalc";
import PdfMessageBcalc from "./PdfMessageBcalc";
import UserInfo from "../../../atoms/Pdf/UserInfo";
import PdfReportLogo from "../../../atoms/Pdf/ReportLogo";

/**
 * TODO: PDF fails when import atoms/styles
 *
 * import { StylesFromAtoms } from "../atoms/Styles";
 * const styles = StylesFromAtoms;
 */
const styles = StyleSheet.create({
  body: {
    paddingTop: 10,
    paddingBottom: 25,
    paddingHorizontal: 30,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 9,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "black",
  },
  image: {
    width: 70,
    height: 20,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
});

const ContainerFinal = ({ dat, title, program, coverPage }: any) => {
  return (
    <Fragment>
      {dat.bCalc && (
        <Fragment>
          <Page style={styles.body}>
            <Text style={styles.header} fixed>
              <PdfReportLogo />
            </Text>
            <PdfReportTitle title={title} />
            {/*{!!(coverPage.name && coverPage.email) && <UserInfo name={coverPage.name} email={coverPage.email} />}*/}
            <PdfReportCashBcalc dat={dat} />
            <PdfMessageBcalc dat={dat} />
            <AgentOfficerDocId dat={dat} />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
          <Page style={styles.body}>
            <Text style={styles.header} fixed>
              <PdfReportLogo />
            </Text>
            <PdfReportSubtitle program={program} cash="Minimum Cash" />
            <PdfClosingAcalc dat={dat} />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
          <Page style={styles.body}>
            <Text style={styles.header} fixed>
              <PdfReportLogo />
            </Text>
            <PdfReportSubtitle program={program} cash="With Cash" />
            <PdfClosingBcalc dat={dat} />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
        </Fragment>
      )}
      {!dat.bCalc && (
        <Fragment>
          <Page style={styles.body}>
            <Text style={styles.header} fixed>
              <PdfReportLogo />
            </Text>
            <PdfReportTitle title={title} />
            {!!(coverPage.name && coverPage.email) && <UserInfo name={coverPage.name} email={coverPage.email} />}
            <PdfReportCashAcalc dat={dat} />
            <PdfMessageAcalc dat={dat} />
            <AgentOfficerDocId dat={dat} />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
          <Page style={styles.body}>
            <Text style={styles.header} fixed>
              <PdfReportLogo />
            </Text>
            <PdfReportSubtitle program={program} cash="Minimal Cash" />
            <PdfClosingAcalc dat={dat} />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
        </Fragment>
      )}
    </Fragment>
  );
};

const BuyersChoice = ({ dat }: any) => {
  return (
    <Document>
      {dat.conventional.aCalc && (
        <ContainerFinal
          dat={dat.conventional}
          coverPage={dat.conventional.coverPage}
          title={dat.conventional.coverPage.title}
          program="Conventional"
        />
      )}
      {dat.fha.aCalc && (
        <ContainerFinal
          dat={dat.fha}
          coverPage={dat.conventional.coverPage}
          title={dat.fha.coverPage.title}
          program="FHA"
        />
      )}
      {dat.va.aCalc && (
        <ContainerFinal
          dat={dat.va}
          coverPage={dat.conventional.coverPage}
          title={dat.va.coverPage.title}
          program="VA"
        />
      )}
      {dat.usda.aCalc && (
        <ContainerFinal
          dat={dat.usda}
          coverPage={dat.conventional.coverPage}
          title={dat.usda.coverPage.title}
          program="USDA"
        />
      )}
    </Document>
  );
};

export default BuyersChoice;
