import React from "react";

import {useState} from "react";
import {Button, Modal} from "react-bootstrap";


const FormMessageVaLoan = () => {
  const [show, setShow] = useState(true);

  return (
    <Modal
      centered
      show={show}
      style={{ zIndex: 9999 }}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          &nbsp;
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <p>Please be sure to update optional fields related to th VA Loan Type</p>
        <ul style={{ listStyle: "inside" }}>
          <li>First VA Loan</li>
          <li>VA Exempt</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FormMessageVaLoan;
