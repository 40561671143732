/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { createContext } from "react";

interface ChristeeContextInterface {
    stage: string | null;
    key: string | null;
  }

const ChristeeConfigCtx = createContext<ChristeeContextInterface | null>(null);
const ChristeeConfigCtxParams: ChristeeContextInterface = { stage: 'dev', key: null };
type ChristeeConfigCtxParams = typeof ChristeeConfigCtxParams;

export { ChristeeConfigCtx, ChristeeConfigCtxParams };