import React from "react";
import { IDefaultProps } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export interface IToolTipPromptProps extends IDefaultProps {
        tip: string
        prompt: string
}

/**
 * This ToolTipPrompt and it's accompanying css was supplied by Sherri.
 * The tip will display on hover over the icon.  Also, the
 * tip will stay present if the user clicks on the icon.
 *
 * @param {dat} tip content
 * @param {prompt} prompt content
 */
const ToolTipPromptReverse = ({ tip, prompt }: IToolTipPromptProps) => {
  return (
    <a
      className="infotip"
      href="#"
      data-toggle="tooltip"
      data-placement="right"
      title={tip}
      style={{ textDecoration: 'none' }}
    >
      {prompt}&nbsp;&nbsp;<FontAwesomeIcon icon={faInfoCircle} />
    </a>
  );
}

export default ToolTipPromptReverse;
