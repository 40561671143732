/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { tipDoType } from '../../atoms';
import { Typeahead } from 'react-bootstrap-typeahead';

type FormInputLoanTypeProps = {
  errors: any;
  control: any;
  required?: boolean;
  tip?: string;
  watch: any;
};

export type FormInputLoanTypeHandle = {
  clear: () => void;
};

const FormInputLoanType = forwardRef<FormInputLoanTypeHandle, FormInputLoanTypeProps>((props, ref) => {
  const name = 'doType';
  const label = 'Loan Type';
  const tip = props.tip || tipDoType;
  const options = {
    required: props.required || false,
  };

  const [selected, setSelected] = useState<TLoan[]>([]);

  type TLoan = {
    value: string;
    text: string;
  };
  let loanTypeaheadRef: Typeahead<TLoan> | null;

  const renderMenuItemChildren = (option: TLoan) => {
    const isSelected = selected.find((f) => f.value === option.value);

    return (
      <div>
        {isSelected ? (
          <>
            <span>
              <b>{option.text}</b>
            </span>
            <FontAwesomeIcon className="dropdown-check" icon={faCheck} />
          </>
        ) : (
          <span>{option.text}</span>
        )}
      </div>
    );
  };

  const groupBy = (list: TLoan[], keyGetter: (x: TLoan) => string) => {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };

  const onSelection = (selection: any, onChange: any) => {
    console.log('FILT->selection.length', selection.length);
    console.log('FILT->selected.length', selection.length);
    if (selection.length === selected.length + 1) {
      loanTypeaheadRef?.blur();
      loanTypeaheadRef?.focus();
    }

    const selectedGroups = groupBy(selection, (x) => x.value);
    const dupes = Array.from(selectedGroups).map((m) => {
      return m[1].length > 1 ? {} : m[1][0];
    });
    const newSelected = selection.filter((item: TLoan) => dupes.find((f) => f.value === item.value));
    setSelected(newSelected);
    onChange(newSelected);
    console.log('FILT->getValues of doType', props.watch);
    console.log('FILT->selected', selected);

    loanTypeaheadRef?.hideMenu();
  };

  useImperativeHandle(ref, () => ({
    clear() {
      console.log('FormInputLoanType->clear called!');
      loanTypeaheadRef?.clear();
      setSelected([]);
    },
  }));

  const toggleLoanTypeahead = (data: any) => {
    console.log('toggleTermeTypeahead->data', data);
    console.log('toggleTermeTypeahead->loanTypeaheadRef', loanTypeaheadRef);
    console.log('toggleTermeTypeahead->loanTypeaheadRef->state', loanTypeaheadRef?.state);
    loanTypeaheadRef?.toggleMenu();
  };

  const filterBy = () => {
    return true;
  };

  let loanOptions = [
    { value: 'CONV', text: 'Conventional' },
    { value: 'FHA', text: 'FHA' },
    { value: 'USDA', text: 'USDA' },
    { value: 'VA', text: 'VA' },
  ];

  return (
    <div className="loan-input">
      <FloatingLabel
        controlId="floatingSelect"
        label={props.errors[name] ? label + (props.required ? ' Required' : ' Incorrect') : props.watch && props.watch.length > 0 ? label + '(s)' : 'Select ' + label + '(s)'}
        className={props.errors[name] ? 'alert-expand' : ''}
      >
        {/*
               // @ts-ignore */}
        <Controller
          name={name}
          rules={options}
          control={props.control}
          render={({ field }) => (
            <Typeahead
              multiple
              filterBy={filterBy}
              {...field}
              id="doType"
              ref={(typeahead) => (loanTypeaheadRef = typeahead)}
              labelKey="text"
              options={loanOptions}
              renderMenuItemChildren={renderMenuItemChildren}
              selected={selected}
              onChange={(selection) => onSelection(selection, field.onChange)}
              inputProps={{
                autoComplete: 'ignore-me',
                shouldSelectHint: (shouldSelect, e) => {
                  return e.keyCode === 9 || e.keyCode === 13 || shouldSelect;
                },
              }}
            >
              <img className="select-down loan-down" alt="select-down" onClick={toggleLoanTypeahead} />
            </Typeahead>
          )}
        />
        <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
          <a className={props.errors[name] ? 'tooltiplabel alert-expand' : 'tooltippanel'}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </OverlayTrigger>
      </FloatingLabel>
    </div>
  );
});

export default FormInputLoanType;
