// <div className="btn btn-generate-pdf">
//  <Button className="btn btn-outline-primary">
//  <div onClick={onClick}>{title}</div>
//</Button>
//</div>

import React from 'react';
//import { Button } from 'react-bootstrap';

type Props = {
  onClick: () => void;
  title: string;
};

const ActionButton = ({ onClick, title }: Props) => {
  return (
    <div className="btn btn-generate-pdf" onClick={onClick}>
      <button className="btn btn-outline-primary" type="button">
        {title}
      </button>
    </div>
  );
};

export default ActionButton;
