/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';
import { useController } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { tipInputcred } from '../../atoms';

type FormInputCreditScoreProps = {
  error: any;
  control: any;
  required?: boolean;
  tip?: string;
};

/* TODO INTEGER_ONLY */
const FormInputCreditScore = ({ error, control, required = false, tip = tipInputcred }: FormInputCreditScoreProps) => {
  const name = 'inputcred';
  const label = 'Credit Score';
  const min = 550;
  const max = 850;
  const options = {
    required: required,
    pattern: {
      value: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      message: 'Numeric required',
    },
    min: min,
    max: max,
    maxLength: 3,
    minLength: 3,
  };

  const { field } = useController({ name, control: control, rules: options, defaultValue: '' });

  return (
    <div className="sp-input">
      <FloatingLabel controlId="floatingSelect" label={error ? label + ' Incorrect' : label} className={error ? 'alert-expand' : ''}>
        <input type="text" min={min} max={max} className="form-control" id={name} data-cy={name} data-test={name} placeholder="" aria-label="Credit Score" {...field} />
        <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
          <a className="tooltippanel">
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </OverlayTrigger>
      </FloatingLabel>
    </div>
  );
};

export default FormInputCreditScore;
