import React, {useContext} from 'react';
import {TdAlternativePdfMessage, TdText, UiSettingsProviderContext} from '../../../atoms';
import { TClosingCostBrowser } from '../../../../types/api';

export type TClosingCostReport = {
  browser: TClosingCostBrowser,
}

const ClosingCost = ({ browser }: TClosingCostReport) => {
  const { currentSettings } = useContext(UiSettingsProviderContext);

  return (
    <table className="table">
      <tbody>
        <tr>
          <TdText v={browser.brwmess1} />
        </tr>
        <tr>
          <TdText v={browser.brwmess2} />
        </tr>
        <tr>
          <TdText v={browser.brwmess3} />
        </tr>
        <tr>
          <TdText v={browser.brwmess4} />
        </tr>
        <tr>
          <TdText v={browser.brwmess5} />
        </tr>
        <tr>
          <TdText v={browser.brwmess6} />
        </tr>
        <tr>
          <TdText v={browser.brwmess7} />
        </tr>
        <tr>
          <TdText v={browser.brwmess8} />
        </tr>
        <tr>
          <TdText v={browser.brwmess9} />
        </tr>
        <tr>
          <TdText v={browser.brwmess10} />
        </tr>
        <tr>
          <TdText v={browser.brwmess11} />
        </tr>
        <tr>
          <TdText v={browser.brwmess12} />
        </tr>
        <tr>
          <TdText v={browser.brwmess13} />
        </tr>
        <tr>
          <TdText v={browser.brwmess14} />
        </tr>
        <tr>
          <TdText v={browser.brwmess15} />
        </tr>
        <tr>
          <TdText v={browser.brwmess16} />
        </tr>
        <tr>
          <TdText v={browser.brwmess17} />
        </tr>
        <tr>
          <TdText v={browser.brwmess18} />
        </tr>
        <tr>
          <TdText v={browser.brwmess19} />
        </tr>
        <tr>
          <TdText v={browser.brwmess20} />
        </tr>
        <tr>
          <TdText v={browser.brwmess21} />
        </tr>
        <tr>
          <TdText v={browser.brwmess22} />
        </tr>
        <tr>
          <TdText v={browser.brwmess23} />
        </tr>
        <tr>
          <TdText v={browser.brwmess24} />
        </tr>
        <tr>
          <TdText v={browser.brwmess25} />
        </tr>
        <tr>
          <TdText v={browser.brwmess26} />
        </tr>
        <tr>
          <TdText v={browser.brwmess27} />
        </tr>
        <tr>
          <TdText v={browser.brwmess28} />
        </tr>
        <tr>
          <TdText v={browser.brwmess29} />
        </tr>
        <tr>
          <TdText v={browser.brwmess30} />
        </tr>
        <tr>
          {(currentSettings.accountType === "agent" || currentSettings.accountType === "loan officer") ? <TdText v={browser.brwmess31} /> : <TdAlternativePdfMessage />}
        </tr>
        <tr>
          <TdText v={browser.brwmess32} />
        </tr>
      </tbody>
    </table>
  );
};

export default ClosingCost;
