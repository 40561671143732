import React from 'react';
import { THecmResponseObject } from '../../../../types/api';
import { DetailCashNaked, DetailPercent, DetailText, DetailTextBold, DetailTextCenter, HtmlDetailTextTwo, TdDownloadPdfColSpan } from '../../../atoms';

export type THecmReport = {
  hecm: THecmResponseObject | undefined;
};

const Hecm = ({ hecm }: THecmReport) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <DetailText v="State" />
          {hecm!.aCalc && <DetailTextCenter v={hecm!.aCalc.state_sel} />}
          {hecm!.bCalc && <DetailTextCenter v={hecm!.bCalc.state_sel} />}
        </tr>
        <tr>
          <DetailText v="" />
          {hecm!.aCalc && <DetailTextCenter v={hecm!.aCalc.report_type} />}
          {hecm!.bCalc && <DetailTextCenter v={hecm!.bCalc.report_type} />}
        </tr>
        <tr>
          <DetailText v="Report Type" />
          {hecm!.aCalc && <DetailTextCenter v={hecm!.aCalc.fumess} />}
          {hecm!.bCalc && <DetailTextCenter v={hecm!.bCalc.fumess} />}
        </tr>
        <tr>
          <DetailText v="Selected Area" />
          {hecm!.aCalc && <DetailTextCenter v={hecm!.aCalc.area} />}
          {hecm!.bCalc && <DetailTextCenter v={hecm!.bCalc.area} />}
        </tr>
        <tr>
          <DetailText v="Interest Rate" />
          {hecm!.aCalc && <DetailPercent v={hecm!.aCalc.ex_rate} />}
          {hecm!.bCalc && <DetailPercent v={hecm!.bCalc.ex_rate} />}
        </tr>
        <tr>
          <DetailText v="Interest Rate With MIP" />
          {hecm!.aCalc && <DetailPercent v={hecm!.aCalc.act_rate} />}
          {hecm!.bCalc && <DetailPercent v={hecm!.bCalc.act_rate} />}
        </tr>
        <tr>
          <DetailText v="Age of Youngest Borrower" />
          {hecm!.aCalc && <DetailTextCenter v={hecm!.aCalc.age} />}
          {hecm!.bCalc && <DetailTextCenter v={hecm!.bCalc.age} />}
        </tr>
        <tr>
          <DetailText v="Sales Price" />
          {hecm!.aCalc && <DetailCashNaked v={hecm!.aCalc.sp} />}
          {hecm!.bCalc && <DetailCashNaked v={hecm!.bCalc.sp} />}
        </tr>
        <tr>
          <DetailText v="Base Loan Amount" />
          {hecm!.aCalc && <HtmlDetailTextTwo a={hecm!.aCalc.ma} b={hecm!.aCalc.ltv} />}
          {hecm!.bCalc && <HtmlDetailTextTwo a={hecm!.bCalc.ma} b={hecm!.bCalc.ltv} />}
        </tr>
        <tr>
          <DetailText v="Total Loan Amount" />
          {hecm!.aCalc && <DetailCashNaked v={hecm!.aCalc.total_mtge} />}
          {hecm!.bCalc && <DetailCashNaked v={hecm!.bCalc.total_mtge} />}
        </tr>
        <tr>
          <DetailText v="Down Payment" />
          {hecm!.aCalc && <DetailCashNaked v={hecm!.aCalc.d_payment} />}
          {hecm!.bCalc && <DetailCashNaked v={hecm!.bCalc.d_payment} />}
        </tr>
        <tr>
          <DetailText v="Estimated Closing Cost" />
          {hecm!.aCalc && <DetailCashNaked v={hecm!.aCalc.totalcc} />}
          {hecm!.bCalc && <DetailCashNaked v={hecm!.bCalc.totalcc} />}
        </tr>
        <tr>
          <DetailText v="Required Cash" />
          {hecm!.aCalc && <DetailCashNaked v={hecm!.aCalc.r_cash} />}
          {hecm!.bCalc && <DetailCashNaked v={hecm!.bCalc.r_cash} />}
        </tr>
        <tr>
          <DetailText v="Cash Available" />
          {hecm!.aCalc && <DetailCashNaked v={hecm!.aCalc.t_cash} />}
          {hecm!.bCalc && <DetailCashNaked v={hecm!.bCalc.t_cash} />}
        </tr>
        <tr>
          <DetailText v="Additional Cash Required" />
          {hecm!.aCalc && <DetailCashNaked v={hecm!.aCalc.ex_cash} />}
          {hecm!.bCalc && <DetailCashNaked v={hecm!.bCalc.ex_cash} />}
        </tr>
        <tr>
          <DetailTextBold v="Estimated Annual Obligations" />
          <DetailText v="" />
          <DetailText v="" />
        </tr>
        <tr>
          <DetailText v="Annual Property Taxes" />
          {hecm!.aCalc && <DetailCashNaked v={hecm!.aCalc.temp_pt} />}
          {hecm!.bCalc && <DetailCashNaked v={hecm!.bCalc.temp_pt} />}
        </tr>
        <tr>
          <DetailText v="Annual Property Insurance" />
          {hecm!.aCalc && <DetailCashNaked v={hecm!.aCalc.temp_ins} />}
          {hecm!.bCalc && <DetailCashNaked v={hecm!.bCalc.temp_ins} />}
        </tr>
        <tr>
          <TdDownloadPdfColSpan v={3} />
        </tr>
      </tbody>
    </table>
  );
};

export default Hecm;
