/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';
import { TBuyersChoiceResponseObject } from '../../../../types/api';
import { BrowMess, DetailCashNaked, DetailText, DetailTextCenter, HtmlDetailTextTwo, TdBuyerQualLimitMessage } from '../../../atoms';

interface Props {
  conventional: TBuyersChoiceResponseObject;
  fha: TBuyersChoiceResponseObject;
  va: TBuyersChoiceResponseObject;
  usda: TBuyersChoiceResponseObject;
}

const BuyerChoice = ({ conventional, fha, va, usda }: Props) => {
  if (conventional.aCalc) {
    var aText = conventional.aCalc.brow_head;
  } else {
    var aText = '';
  }
  if (fha.aCalc) {
    var bText = fha.aCalc.brow_head;
  } else {
    var bText = '';
  }
  if (va.aCalc) {
    var cText = va.aCalc.brow_head;
  } else {
    var cText = '';
  }
  if (usda.aCalc) {
    var dText = usda.aCalc.brow_head;
  } else {
    var dText = '';
  }

  return (
    <table className="table">
      <tbody>
        <tr>
          <td colSpan={5}>
            <BrowMess a={aText} b={bText} c={cText} d={dText} />
          </td>
        </tr>
        <tr>
          <DetailText v="Loan Type" />
          {conventional?.aCalc ? <DetailTextCenter v={conventional.aCalc.report_type} /> : null}
          {fha?.aCalc ? <DetailTextCenter v={fha.aCalc.report_type} /> : null}
          {va?.aCalc ? <DetailTextCenter v={va.aCalc.report_type} /> : null}
          {usda?.aCalc ? <DetailTextCenter v={usda.aCalc.report_type} /> : null}
        </tr>
        <tr>
          <DetailText v="Alerts" />
          {conventional?.aCalc ? <DetailTextCenter v={conventional?.aCalc?.fumess} /> : null}
          {fha?.aCalc ? <DetailTextCenter v={fha?.aCalc?.fumess} /> : null}
          {va?.aCalc ? <DetailTextCenter v={va?.aCalc?.fumess} /> : null}
          {usda?.aCalc ? <DetailTextCenter v={usda?.aCalc?.fumess} /> : null}
        </tr>
        <tr className="active-row">
          <DetailText v="Purchase Price" />
          {conventional?.aCalc ? <DetailCashNaked v={conventional?.aCalc?.sp} /> : null}
          {fha?.aCalc ? <DetailCashNaked v={fha?.aCalc?.sp} /> : null}
          {va?.aCalc ? <DetailCashNaked v={va?.aCalc?.sp} /> : null}
          {usda?.aCalc ? <DetailCashNaked v={usda?.aCalc?.sp} /> : null}
        </tr>
        <tr className="active-row">
          <DetailText v="Base Loan Amount" />
          {conventional?.aCalc ? <HtmlDetailTextTwo a={conventional?.aCalc?.ma} b={conventional?.aCalc?.ltv} /> : null}
          {fha?.aCalc ? <HtmlDetailTextTwo a={fha?.aCalc?.ma} b={fha?.aCalc?.ltv} /> : null}
          {va?.aCalc ? <HtmlDetailTextTwo a={va?.aCalc?.ma} b={va?.aCalc?.ltv} /> : null}
          {usda?.aCalc ? <HtmlDetailTextTwo a={usda?.aCalc?.ma} b={usda?.aCalc?.ltv} /> : null}
        </tr>
        <tr className="active-row">
          <DetailText v="Required Cash" />
          {conventional?.aCalc ? <DetailCashNaked v={conventional?.aCalc?.r_cash} /> : null}
          {fha?.aCalc ? <DetailCashNaked v={fha?.aCalc?.r_cash} /> : null}
          {va?.aCalc ? <DetailCashNaked v={va?.aCalc?.r_cash} /> : null}
          {usda?.aCalc ? <DetailCashNaked v={usda?.aCalc?.r_cash} /> : null}
        </tr>
        <tr>
          <DetailText v="Cash available" />
          {conventional?.aCalc ? <DetailCashNaked v={conventional?.aCalc?.t_cash} /> : null}
          {fha?.aCalc ? <DetailCashNaked v={fha?.aCalc?.t_cash} /> : null}
          {va?.aCalc ? <DetailCashNaked v={va?.aCalc?.t_cash} /> : null}
          {usda?.aCalc ? <DetailCashNaked v={usda?.aCalc?.t_cash} /> : null}
        </tr>
        <tr className="active-row">
          <DetailText v="Total Monthly Payment" />
          {conventional?.aCalc ? <DetailCashNaked v={conventional?.aCalc?.t_piti} /> : null}
          {fha?.aCalc ? <DetailCashNaked v={fha?.aCalc?.t_piti} /> : null}
          {va?.aCalc ? <DetailCashNaked v={va?.aCalc?.t_piti} /> : null}
          {usda?.aCalc ? <DetailCashNaked v={usda?.aCalc?.t_piti} /> : null}
        </tr>
        <tr>
          <DetailText v="Principal &amp; Interest" />
          {conventional?.aCalc ? <HtmlDetailTextTwo a={conventional?.aCalc.temp_pi} b={conventional?.aCalc.res_pi} /> : null}
          {fha?.aCalc ? <HtmlDetailTextTwo a={fha?.aCalc.temp_pi} b={fha?.aCalc.res_pi} /> : null}
          {va?.aCalc ? <HtmlDetailTextTwo a={va?.aCalc.temp_pi} b={va?.aCalc.res_pi} /> : null}
          {usda?.aCalc ? <HtmlDetailTextTwo a={usda?.aCalc.temp_pi} b={usda?.aCalc.res_pi} /> : null}
        </tr>
        <tr>
          <DetailText v="Monthly Mortgage Insurance" />
          {conventional?.aCalc ? <HtmlDetailTextTwo a={conventional?.aCalc.temp_mi} b={conventional?.aCalc.res_mi} /> : null}
          {fha?.aCalc ? <HtmlDetailTextTwo a={fha?.aCalc.temp_mi} b={fha?.aCalc.res_mi} /> : null}
          {va?.aCalc ? <HtmlDetailTextTwo a={va?.aCalc.temp_mi} b={va?.aCalc.res_mi} /> : null}
          {usda?.aCalc ? <HtmlDetailTextTwo a={usda?.aCalc.temp_mi} b={usda?.aCalc.res_mi} /> : null}
        </tr>
        <tr>
          <DetailText v="Monthly Property Taxes" />
          {conventional?.aCalc ? <HtmlDetailTextTwo a={conventional?.aCalc.temp_pt} b={conventional?.aCalc.res_pt} /> : null}
          {fha?.aCalc ? <HtmlDetailTextTwo a={fha?.aCalc.temp_pt} b={fha?.aCalc.res_pt} /> : null}
          {va?.aCalc ? <HtmlDetailTextTwo a={va?.aCalc.temp_pt} b={va?.aCalc.res_pt} /> : null}
          {usda?.aCalc ? <HtmlDetailTextTwo a={usda?.aCalc.temp_pt} b={usda?.aCalc.res_pt} /> : null}
        </tr>
        <tr>
          <DetailText v="Monthly Property Insurance" />
          {conventional?.aCalc ? <HtmlDetailTextTwo a={conventional?.aCalc.temp_ins} b={conventional?.aCalc.res_ins} /> : null}
          {fha?.aCalc ? <HtmlDetailTextTwo a={fha?.aCalc.temp_ins} b={fha?.aCalc.res_ins} /> : null}
          {va?.aCalc ? <HtmlDetailTextTwo a={va?.aCalc.temp_ins} b={va?.aCalc.res_ins} /> : null}
          {usda?.aCalc ? <HtmlDetailTextTwo a={usda?.aCalc.temp_ins} b={usda?.aCalc.res_ins} /> : null}
        </tr>
        <tr>
          <DetailText v="Monthly Condo/HOA fee" />
          {conventional?.aCalc ? <HtmlDetailTextTwo a={conventional?.aCalc.condo} b={conventional?.aCalc.res_condo} /> : null}
          {fha?.aCalc ? <HtmlDetailTextTwo a={fha?.aCalc.condo} b={fha?.aCalc.res_condo} /> : null}
          {va?.aCalc ? <HtmlDetailTextTwo a={va?.aCalc.condo} b={va?.aCalc.res_condo} /> : null}
          {usda?.aCalc ? <HtmlDetailTextTwo a={usda?.aCalc.condo} b={usda?.aCalc.res_condo} /> : null}
        </tr>
        <tr>
          <TdBuyerQualLimitMessage />
        </tr>
      </tbody>
    </table>
  );
};

export default BuyerChoice;
