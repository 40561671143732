import React, {useEffect, useState} from "react";

import {FloatingLabel, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import editIcon from "../../../images/edit-icon.svg";

import "./style.css";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import FormInterestRatesModal from "../FormInterestRatesModal";

interface Rate {
  [P: string]: {
    name: string,
    tip: string
  }[];
}

const rateContentHelper: Rate = {
  "Conventional": [
    {
      name: "conv_30",
      tip: "Tip"
    },
    {
      name: "conv_15",
      tip: "Tip"
    }
  ],
  "FHA": [
    {
      name: "fha_30",
      tip: "Tip"
    },
    {
      name: "fha_15",
      tip: "Tip"
    }
  ],
  "USDA": [
    {
      name: "usda_30",
      tip: "Tip"
    },
    {
      name: "usda_15",
      tip: "Tip",
    }
  ],
  "VA": [
    {
      name: "va_30",
      tip: "Tip"
    },
    {
      name: "va_15",
      tip: "Tip"
    }
  ],
  "Second Home Conv": [
    {
      name: "second_home_conv_30",
      tip: "Tip"
    },
    {
      name: "second_home_conv_15",
      tip: "Tip"
    }
  ],
  "HECM": [
    {
      name: "hecm",
      tip: "Tip"
    }
  ],
};

type Props = {
  watchCalcForm: any,
  resetCalcForm: any,
  selectedRates?: string[]
}

const FormInputChangeRate = ({ watchCalcForm, resetCalcForm, selectedRates = ["Conventional", "FHA", "USDA", "VA"] }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [ratesToRender, setRatesToRender] = useState<Rate>({});

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur' });

  const watchRates = watch();

  const hideModal = () => {
    setShowModal(false)
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await fetch("https://6ncwpihpv7.execute-api.us-east-1.amazonaws.com/prod/rates/1c8d0510-dc49-11ec-91cc-0766a59baf68");
        const parsedJson = await response.json();
        if (parsedJson) {
          const fieldsToDisplay = [].concat(...selectedRates.reduce((prev: any[], next: string) => {
            if (rateContentHelper[next]) {
              prev.push(rateContentHelper[next].map((field: any) => field.name));
            }
            return prev;
          }, []));

          const interest_rates: any = {};
          fieldsToDisplay.forEach((fieldName) => {
            interest_rates[fieldName] = parsedJson[fieldName];
          });
          resetCalcForm({
            ...watchCalcForm,
            interest_rates,
          });
          reset(
            {
              interest_rates,
            }
          );

          const newDisplayRates: Rate = {};

          selectedRates.forEach(selectedRate => {
            newDisplayRates[selectedRate] = rateContentHelper[selectedRate];
          });

          setRatesToRender(newDisplayRates);
        }
      } catch (err) {
        if (err instanceof Error) {
          toast.error(err.message);
        }
      }
    };

    if (!watchRates.interest_rates) {
      getData();
    }
  }, []);

  return (
    <>
      <div onClick={() => setShowModal(true)} className="sp-input change-rate">
        <OverlayTrigger
          placement="right"
          trigger={["hover", "focus"]}
          rootClose
          overlay={
            <Tooltip className="change-rate__tooltip" style={{maxWidth: "auto" }}>
              <table className="change-rate__table change-rate-table">
                <tbody>
                <tr>
                  <th className="change-rate-table__head-type pb-2">Rate</th>
                  <th className="fw-bold pb-2">30 year</th>
                  <th className="fw-bold pb-2">15 year</th>
                </tr>
                {Object.keys(ratesToRender)?.map(rateKey => (<tr key={rateKey}>
                    <td className="change-rate-table__type">{rateKey}</td>
                    {ratesToRender?.[rateKey]?.map((rateInfo) => {
                        return <td key={rateKey + rateInfo.name}>{watchRates.interest_rates?.[rateInfo.name]}</td>
                      }
                    )}
                  </tr>
                ))}
                </tbody>
              </table>
            </Tooltip>
          }
        >
          <FloatingLabel controlId="floatingSelect" label=" ">
            <div className="form-control change-rate__body">
              <div>Change Rate</div>
              <div className="change-rate__icon">
                <img src={editIcon} alt=""/>
              </div>
            </div>
            <a className="tooltippanel">
              <FontAwesomeIcon icon={faInfoCircle} />
            </a>
          </FloatingLabel>
        </OverlayTrigger>
      </div>
      <FormInterestRatesModal
        errors={errors}
        show={showModal}
        control={control}
        hideModal={hideModal}
        watchRates={watchRates}
        handleSubmit={handleSubmit}
        ratesToRender={ratesToRender}
        resetCalcForm={resetCalcForm}
        watchCalcForm={watchCalcForm} />
    </>
  );
};

export default FormInputChangeRate;
