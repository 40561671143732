import React, {useContext} from 'react';
import { TBuyWaitResults } from '../../../../types/api';
import {
  DetailCashNaked,
  DetailPercent,
  DetailText,
  DetailTextBold,
  DetailTextCenter,
  HtmlDetailTextTwo,
  TdAlternativePdfMessage, UiSettingsProviderContext
} from '../../../atoms';

interface Props {
  results: TBuyWaitResults;
}

const BuyWait: React.FC<Props> = ({ results: { aCalc, aNotes } }) => {
  const { currentSettings } = useContext(UiSettingsProviderContext);

  console.log('aNotes', aNotes);
  console.log('buyWaitReport->', aCalc);
  return (
    <table className="table">
      <tbody>
        <tr>
          <DetailText v="Report Type" />
          {aCalc && <DetailTextCenter v={aCalc.report_type} />}
          {aCalc && <DetailTextCenter v={aCalc.report_type_2} />}
        </tr>
        <tr>
          <DetailText v="State" />
          {aCalc && <DetailTextCenter v={aCalc.state_sel} />}
          {aCalc && <DetailTextCenter v={aCalc.state_select_2} />}
        </tr>
        <tr>
          <DetailText v="Selected Area" />
          {aCalc && <DetailTextCenter v={aCalc.area} />}
          {aCalc && <DetailTextCenter v={aCalc.area_2} />}
        </tr>
        <tr>
          <DetailText v="Loan Type" />
          {aCalc && <DetailTextCenter v={aCalc.fumess} />}
          {aCalc && <DetailTextCenter v={aCalc.fumess_2} />}
        </tr>
        <tr>
          <DetailText v="Purchase Price" />
          {aCalc && <DetailCashNaked v={aCalc.sp.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.sp_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Base Loan Amount" />
          {aCalc && <HtmlDetailTextTwo a={aCalc.ma.toString()} b={aCalc.ltv.toString()} />}
          {aCalc && <HtmlDetailTextTwo a={aCalc.ma_2.toString()} b={aCalc.ltv_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Total Loan Amount" />
          {aCalc && <DetailCashNaked v={aCalc.total_mtge.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.total_mtge_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Down Payment" />
          {aCalc && <DetailCashNaked v={aCalc.d_payment.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.d_payment_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Total Closing Cost" />
          {aCalc && <DetailCashNaked v={aCalc.totalcc.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.totalcc_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Closing Cost Credit" />
          {aCalc && <HtmlDetailTextTwo a={aCalc.dol_cred.toString()} b={aCalc.temp_cred.toString()} />}
          {aCalc && <HtmlDetailTextTwo a={aCalc.dol_cred_2.toString()} b={aCalc.temp_cred_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Required Cash" />
          {aCalc && <DetailCashNaked v={aCalc.r_cash.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.r_cash_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Interest Rate" />
          {aCalc && <DetailPercent v={aCalc.temp_rate.toString()} />}
          {aCalc && <DetailPercent v={aCalc.temp_rate_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Total Monthly Payment" />
          {aCalc && <DetailCashNaked v={aCalc.t_piti.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.t_piti_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Principal &amp; Interest" />
          {aCalc && <DetailCashNaked v={aCalc.temp_pi.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.temp_pi_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Mortgage Insurance" />
          {aCalc && <DetailCashNaked v={aCalc.temp_mi.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.temp_mi_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Property Taxes" />
          {aCalc && <DetailCashNaked v={aCalc.temp_pt.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.temp_pt_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Property Insurance" />
          {aCalc && <DetailCashNaked v={aCalc.temp_ins.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.temp_ins_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Condo/HOA Fee" />
          {aCalc && <DetailCashNaked v={aCalc.condo.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.condo_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Estimated Annual Tax Deduction" />
          {aCalc && <DetailCashNaked v={aCalc.tot_duct.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.tot_duct_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Federal Tax Bracket" />
          {aCalc && <DetailPercent v={aCalc.print_t_bracket.toString()} />}
          {aCalc && <DetailPercent v={aCalc.print_t_bracket.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Federal Tax Savings" />
          {aCalc && <DetailCashNaked v={aCalc.tax_saving.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.tax_saving_2.toString()} />}
        </tr>
        <tr>
          <DetailText v="Net Monthly Mortgage Payment" />
          {aCalc && <DetailCashNaked v={aCalc.net_mort_pay.toString()} />}
          {aCalc && <DetailCashNaked v={aCalc.net_mort_pay_2.toString()} />}
        </tr>
        <tr>
          {(currentSettings.accountType === "agent" || currentSettings.accountType === "loan officer") ? <DetailTextBold v="Download PDF File for Complete Details" /> : <TdAlternativePdfMessage />}
          <DetailText v="" />
          <DetailText v="" />
        </tr>
      </tbody>
    </table>
  );
};

export default BuyWait;
