/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 *
 *
 * The API expects the following values:
 * - "name": "Jane Homebuyer",
 * - "phone": "301-382-2816",
 * - "email": "jane@example.com",
 * - "message": "Please help me, I can't understand Buyer Prequal.",
 * - "zip": "20122",
 * - "interest": "Finance",
 * - "contact_by": "phone"
 */

import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { postChristeeAgentZip as CONTACT_API } from '../../../../api';
import {
  ButtonWithSpinner,
  tipContactContactBy,
  tipContactEmail,
  tipContactInterest,
  tipContactMessage,
  tipContactName,
  tipContactPhone,
  tipContactZip,
  UiSettingsProviderContext,
} from '../../../atoms';
import ActionButton from '../../../atoms/ActionButton';
import { FormInputEmail, FormInputMultiSelect, FormInputSelect, FormInputText } from '../../../molecules';

const ContactAgentButton = () => {
  const defaultValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
    zip: '',
    interest: '',
    contact_by: '',
  };
  const {
    control,
    reset,
    getValues,
    handleSubmit,
    watch,
    formState: { errors, isValid, touchedFields, submitCount },
  } = useForm<any>({ mode: 'onTouched', reValidateMode: 'onBlur', defaultValues });

  const { currentSettings } = useContext(UiSettingsProviderContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => setIsSubmit(false), 500);
  };

  const handleShow = () => setShow(true);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const clearForm = (data?: any) => {
    console.log('clearForm->data', data);
    console.log('clearForm->isValid', isValid);
    console.log('clearForm->errors', errors);
    console.log('clearForm->touchedFields', touchedFields);
    console.log('clearForm->submitCount', submitCount);
    console.log('clearForm->getValues->state', getValues('state'));
    reset(defaultValues);
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const res = await CONTACT_API({ ...data, company_name: currentSettings.companyId });
      toast.success(res.message);
      setLoading(false);
      setIsSubmit(true);
      clearForm();
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  useEffect(() => {
    console.log('useEffect->scrollTop');
    scrollTop();
  }, []);

  return (
    <>
      <ActionButton onClick={handleShow} title="Explore Home Buying Insights from Christee Approved Agent" />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className="display">
          <h4 className="fw-bold px-4 my-0">Get More Information</h4>
          <Modal.Title id="amortization-help-title">&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-md-5">
            {isSubmit ? (
              <div style={{ height: '200px' }}>
                <h2>Contact Us</h2>
                <p className="mt-3">Your message has successfully been sent. You will be contacted as soon as possible.</p>
              </div>
            ) : (
              <form className="christy" autoComplete="off">
                <fieldset>
                  <FormInputText label="Name" tip={tipContactName} required={true} error={errors.name} name="name" control={control} />
                  <FormInputText label="Phone" tip={tipContactPhone} error={errors.phone} name="phone" control={control} />
                  <FormInputEmail name="email" required={true} tip={tipContactEmail} accountType={currentSettings.accountType} error={errors.email} control={control} />
                  <FormInputText label="Message" tip={tipContactMessage} required={true} error={errors.message} name="message" control={control} />
                  <FormInputText label="Zip Code" tip={tipContactZip} required={false} error={errors.zip} name="zip" control={control} />
                  <FormInputMultiSelect
                    tip={tipContactInterest}
                    name="interest"
                    selectOptions={[
                      { text: 'Selling', value: 'selling' },
                      { text: 'Buying', value: 'buying' },
                      { text: 'Finance information', value: 'fin_info' },
                    ]}
                    label="Interest"
                    watch={watch('interest')}
                    control={control}
                    errors={errors}
                    required={true}
                  />
                  <FormInputSelect
                    tip={tipContactContactBy}
                    selectOptions={[
                      { text: 'Call', value: 'call' },
                      { text: 'Text', value: 'text' },
                      { text: 'Email', value: 'email' },
                    ]}
                    name="contact"
                    label="Preferred Contact"
                    watch={watch('contact')}
                    control={control}
                    errors={errors}
                    required={true}
                  />
                </fieldset>
                <p className="mt-3 d-flex justify-content-end">
                  <button type="reset" className="btn btn-outline-secondary me-2" onClick={clearForm}>
                    Clear
                  </button>
                  <ButtonWithSpinner variant="outline-primary" loading={loading} type="submit" spinnerVariant="primary" onClick={handleSubmit(onSubmit)}>
                    Submit
                  </ButtonWithSpinner>
                </p>
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactAgentButton;
