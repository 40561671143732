/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';
import { Document, Page, Text, StyleSheet } from '@react-pdf/renderer';
import { View } from '@react-pdf/renderer';
import ReportTitle from '../../../atoms/Pdf/ReportTitle';
import { TConsumerDebtNotes, TConsumerDebtResponse } from '../../../../types/api';
import UserInfo from '../../../atoms/Pdf/UserInfo';
import {PdfReportLogo} from "../../../atoms";

const styles = StyleSheet.create({
  body: {
    paddingTop: 10,
    paddingBottom: 25,
    paddingHorizontal: 30,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'black',
  },
  image: {
    width: 70,
    height: 20,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  description: {
    textAlign: 'left',
    width: '50%',
    fontSize: 9,
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
  },
  row: {
    flexDirection: 'row',
    height: 16,
  },
  acalc: {
    width: '30%',
    fontSize: 9,
    textAlign: 'left',
  },
  note: {
    width: '100%',
    textAlign: 'left',
    fontSize: 9,
  },
});

interface RProps {
  aNotes: TConsumerDebtNotes;
}

const ReportDetails: React.FC<RProps> = ({ aNotes }) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.fumess}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.fumess1}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}></Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess1}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess1a}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}></Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess2}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess3}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess3a}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}></Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess4}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess5}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}></Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess7}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess8}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess9}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess10}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess11}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.note}>{aNotes.pdfmess12}</Text>
      </View>
    </View>
  );
};

interface Props {
  dat: TConsumerDebtResponse & { title: string };
}
const PdfReportContainer: React.FC<Props> = ({ dat: { results, title, coverPage } }) => {
  console.log(coverPage);
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          <PdfReportLogo />
        </Text>
        <ReportTitle title={title} />
        {!!(coverPage.name && coverPage.email) && <UserInfo name={coverPage.name} email={coverPage.email} />}
        <ReportDetails {...results} />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
      </Page>
    </Document>
  );
};

export default PdfReportContainer;
