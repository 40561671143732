import React from "react";
import { IDefaultProps } from '../../organisms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface IToolTipsProps extends IDefaultProps {
  tip: string
}

/**
 * This ToolTips and it's accompanying css was supplied by Sherri.
 * The tip will display on hover over the icon.  Also, the
 * tip will stay present if the user clicks on the icon.
 *
 * @param {dat} Data passed in from the form.
 */
const ToolTips = ({ tip }: IToolTipsProps) => {
  return (
    <OverlayTrigger placement="right" overlay={<Tooltip>{tip}</Tooltip>}>
      <a
        href="#"
        className="tooltiplabel"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </a>
    </OverlayTrigger>
  );
}

export default ToolTips;
