import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faLinkedinIn  } from '@fortawesome/free-brands-svg-icons';
import img from './footer-bg.jpg';

export default function Footer() {
  let version = process.env.REACT_APP_VERSION;
  console.log('version =', version);
  console.log('img =', img);
  const footerStyle = {
          backgroundImage: 'url(' + img + ')'
  };

  return (
    <footer style={footerStyle}>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-3">
            <h2>Useful Links</h2>
            <nav>
              <ul id="menu-footer-menu" className="footer-menu">
                <li
                  id="menu-item-349"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-349"
                >
                  <a title="Buy" href="https://www.tcbrealtor.com/buy/">
                    Buy
                  </a>
                </li>
                <li
                  id="menu-item-350"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-350"
                >
                  <a title="Sell" href="https://www.tcbrealtor.com/sell/">
                    Sell
                  </a>
                </li>
                <li
                  id="menu-item-397"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-397"
                >
                  <a
                    title="Real Estate Finance"
                    href="https://www.tcbrealtor.com/real-estate-finance/"
                  >
                    Real Estate Finance
                  </a>
                </li>
                <li
                  id="menu-item-352"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-352"
                >
                  <a
                    title="Resources"
                    href="https://www.tcbrealtor.com/resources/"
                  >
                    Resources
                  </a>
                </li>
                <li
                  id="menu-item-351"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-351"
                >
                  <a
                    title="About Us"
                    href="https://www.tcbrealtor.com/about-us/"
                  >
                    About Us
                  </a>
                </li>
                <li
                  id="menu-item-570"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-570"
                >
                  <a
                    title="Careers"
                    href="https://www.tcbrealtor.com/resources/careers/"
                  >
                    Careers
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-md-3">
            <div className="footer-contact">
              <h2>Contact Us Today!</h2>
              <p>
                <a href="tel:443-370-8859">443-370-8859</a>
              </p>
              <p>
                <a href="mailto:info@tcbrealtor.com">info@tcbrealtor.com</a>
              </p>
            </div>
          </div>

          <div className="col-md-3">
            <h2>
              <span className="gray">Stay in Touch!</span>
            </h2>
            <section className="social" aria-label="Social Network Links">
              <span className="custom-social align-middle">
                <a
                  href="https://www.facebook.com/TCBRealtorMD"
                  title=""
                  aria-label="Facebook"
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faFacebook} aria-hidden="true"/>
                </a>
              </span>
              <span className="custom-social align-middle">
                <a
                  href="https://www.instagram.com/tcbrealtor/"
                  title=""
                  aria-label="instagram"
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faInstagram} aria-hidden="true"/>
                </a>
              </span>
              <span className="custom-social align-middle">
                <a
                  href="https://twitter.com/TCBrealtorMD"
                  title=""
                  aria-label="Twitter"
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faTwitter} aria-hidden="true"/>
                </a>
              </span>
              <span className="custom-social align-middle">
                <a
                  href="https://www.linkedin.com/company/tcb-realtor"
                  title=""
                  aria-label="Linkedin"
                  target="_blank"
                  rel="noopener"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} aria-hidden="true"/>
                </a>
              </span>
            </section>
          </div>
        </div>

        <div className="siteinfo">
          <p>&copy;2020 TCB Realtor. All rights reserved. { version && 'v' + version }</p>
          <p>
            Terms of Use /
            <a href="https://www.tcbrealtor.com/privacy-policy/">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
