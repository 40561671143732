export type TState = {
  code: string,
  text: string
}

const States: TState[ ] = [
  {
    code: 'al',
    text: "Alabama",
  },
  {
    code: "ak",
    text: "Alaska",
  },
  {
    code: "az",
    text: "Arizona",
  },
  {
    code: "ar",
    text: "Arkansas",
  },
  {
    code: "ca",
    text: "California",
  },
  {
    code: "co",
    text: "Colorado",
  },
  {
    code: "ct",
    text: "Connecticut",
  },
  {
    code: "dc",
    text: "District of Columbia",
  },
  {
    code: "de",
    text: "Delaware",
  },
  {
    code: "fl",
    text: "Florida",
  },
  {
    code: "ga",
    text: "Georgia",
  },
  {
    code: "hi",
    text: "Hawaii",
  },
  {
    code: "id",
    text: "Idaho",
  },
  {
    code: "il",
    text: "Illinois",
  },
  {
    code: "in",
    text: "Indiana",
  },
  {
    code: "ia",
    text: "Iowa",
  },
  {
    code: "ks",
    text: "Kansas",
  },
  {
    code: "ky",
    text: "Kentucky",
  },
  {
    code: "la",
    text: "Louisiana",
  },
  {
    code: "me",
    text: "Maine",
  },
  {
    code: "md",
    text: "Maryland",
  },
  {
    code: "ma",
    text: "Massachusetts",
  },
  {
    code: "mi",
    text: "Michigan",
  },
  {
    code: "mn",
    text: "Minnesota",
  },
  {
    code: "ms",
    text: "Mississippi",
  },
  {
    code: "mo",
    text: "Missouri",
  },
  {
    code: "mt",
    text: "Montana",
  },
  {
    code: "ne",
    text: "Nebraska",
  },
  {
    code: "nv",
    text: "Nevada",
  },
  {
    code: "nh",
    text: "New Hampshire",
  },
  {
    code: "nj",
    text: "New Jersey",
  },
  {
    code: "nm",
    text: "New Mexico",
  },
  {
    code: "ny",
    text: "New York",
  },
  {
    code: "nc",
    text: "North Carolina",
  },
  {
    code: "nd",
    text: "North Dakota",
  },
  {
    code: "oh",
    text: "Ohio",
  },
  {
    code: "ok",
    text: "Oklahoma",
  },
  {
    code: "or",
    text: "Oregon",
  },
  {
    code: "pa",
    text: "Pennsylvania",
  },
  {
    code: "ri",
    text: "Rhode Island",
  },
  {
    code: "sc",
    text: "South Carolina",
  },
  {
    code: "sd",
    text: "South Dakota",
  },
  {
    code: "tn",
    text: "Tennessee",
  },
  {
    code: "tx",
    text: "Texas",
  },
  {
    code: "ut",
    text: "Utah",
  },
  {
    code: "vt",
    text: "Vermont",
  },
  {
    code: "va",
    text: "Virginia",
  },
  {
    code: "wa",
    text: "Washington",
  },
  {
    code: "wv",
    text: "West Virginia",
  },
  {
    code: "wi",
    text: "Wisconsin",
  },
  {
    code: "wy",
    text: "Wyoming",
  },
];

export default States;
