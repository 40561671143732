/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from 'react';
import { TBuyerQualResponseObject } from '../../../../types/api/buyerqual';
import { BrowMess, DetailCashNaked, DetailText, DetailTextCenter, HtmlDetailTextTwo, TdBuyerQualLimitMessage } from '../../../atoms';

interface Props {
  conventional: TBuyerQualResponseObject;
  fha: TBuyerQualResponseObject;
  va: TBuyerQualResponseObject;
  usda: TBuyerQualResponseObject;
}

const BuyerQual = ({ conventional, fha, va, usda }: Props) => {
  if (conventional.aCalc) {
    var aText = conventional.aCalc.brow_head;
  } else {
    var aText = '';
  }
  if (fha.aCalc) {
    var bText = fha.aCalc.brow_head;
  } else {
    var bText = '';
  }
  if (va.aCalc) {
    var cText = va.aCalc.brow_head;
  } else {
    var cText = '';
  }
  if (usda.aCalc) {
    var dText = usda.aCalc.brow_head;
  } else {
    var dText = '';
  }
  return (
    <table className="table">
      <tbody>
        <tr>
          <td colSpan={5}>
            <BrowMess a={aText} b={bText} c={cText} d={dText} />
          </td>
        </tr>
        <tr>
          <DetailText v="Loan Type" />
          {conventional.aCalc && <DetailTextCenter v={conventional.aCalc.report_type.toString()} />}
          {fha.aCalc && <DetailTextCenter v={fha.aCalc.report_type.toString()} />}
          {va.aCalc && <DetailTextCenter v={va.aCalc.report_type.toString()} />}
          {usda.aCalc && <DetailTextCenter v={usda.aCalc.report_type.toString()} />}
        </tr>
        <tr>
          <DetailText v="Alerts" />
          {conventional.aCalc && <DetailTextCenter v={conventional.aCalc.fumess.toString()} />}
          {fha.aCalc && <DetailTextCenter v={fha.aCalc.fumess.toString()} />}
          {va.aCalc && <DetailTextCenter v={va.aCalc.fumess.toString()} />}
          {usda.aCalc && <DetailTextCenter v={usda.aCalc.fumess.toString()} />}
        </tr>
        <tr className="active-row">
          <DetailText v="Projected Purchase Price" />
          {conventional.aCalc && <DetailCashNaked v={conventional.aCalc.sp.toString()} />}
          {fha.aCalc && <DetailCashNaked v={fha.aCalc.sp.toString()} />}
          {va.aCalc && <DetailCashNaked v={va.aCalc.sp.toString()} />}
          {usda.aCalc && <DetailCashNaked v={usda.aCalc.sp.toString()} />}
        </tr>
        <tr className="active-row">
          <DetailText v="Base Loan" />
          {conventional.aCalc && <HtmlDetailTextTwo a={conventional.aCalc.ma.toString()} b={conventional.aCalc.ltv.toString()} />}
          {fha.aCalc && <HtmlDetailTextTwo a={fha.aCalc.ma.toString()} b={fha.aCalc.ltv.toString()} />}
          {va.aCalc && <HtmlDetailTextTwo a={va.aCalc.ma.toString()} b={va.aCalc.ltv.toString()} />}
          {usda.aCalc && <HtmlDetailTextTwo a={usda.aCalc.ma.toString()} b={usda.aCalc.ltv.toString()} />}
        </tr>
        <tr className="active-row">
          <DetailText v="Required Cash" />
          {conventional.aCalc && <DetailCashNaked v={conventional.aCalc.r_cash.toString()} />}
          {fha.aCalc && <DetailCashNaked v={fha.aCalc.r_cash.toString()} />}
          {va.aCalc && <DetailCashNaked v={va.aCalc.r_cash.toString()} />}
          {usda.aCalc && <DetailCashNaked v={usda.aCalc.r_cash.toString()} />}
        </tr>
        <tr>
          <DetailText v="Cash available" />
          {conventional.aCalc && <DetailCashNaked v={conventional.aCalc.t_cash.toString()} />}
          {fha.aCalc && <DetailCashNaked v={fha.aCalc.t_cash.toString()} />}
          {va.aCalc && <DetailCashNaked v={va.aCalc.t_cash.toString()} />}
          {usda.aCalc && <DetailCashNaked v={usda.aCalc.t_cash.toString()} />}
        </tr>
        <tr className="active-row">
          <DetailText v="Total Monthly Payment" />
          {conventional.aCalc && <DetailCashNaked v={conventional.aCalc.t_piti.toString()} />}
          {fha.aCalc && <DetailCashNaked v={fha.aCalc.t_piti.toString()} />}
          {va.aCalc && <DetailCashNaked v={va.aCalc.t_piti.toString()} />}
          {usda.aCalc && <DetailCashNaked v={usda.aCalc.t_piti.toString()} />}
        </tr>
        <tr>
          <DetailText v="Principal &amp; Interest" />
          {conventional.aCalc && <HtmlDetailTextTwo a={conventional.aCalc.temp_pi.toString()} b={conventional.aCalc.res_pi.toString()} />}
          {fha.aCalc && <HtmlDetailTextTwo a={fha.aCalc.temp_pi.toString()} b={fha.aCalc.res_pi.toString()} />}
          {va.aCalc && <HtmlDetailTextTwo a={va.aCalc.temp_pi.toString()} b={va.aCalc.res_pi.toString()} />}
          {usda.aCalc && <HtmlDetailTextTwo a={usda.aCalc.temp_pi.toString()} b={usda.aCalc.res_pi.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Mortgage Ins" />
          {conventional.aCalc && <HtmlDetailTextTwo a={conventional.aCalc.temp_mi.toString()} b={conventional.aCalc.res_mi.toString()} />}
          {fha.aCalc && <HtmlDetailTextTwo a={fha.aCalc.temp_mi.toString()} b={fha.aCalc.res_mi.toString()} />}
          {va.aCalc && <HtmlDetailTextTwo a={va.aCalc.temp_mi.toString()} b={va.aCalc.res_mi.toString()} />}
          {usda.aCalc && <HtmlDetailTextTwo a={usda.aCalc.temp_mi.toString()} b={usda.aCalc.res_mi.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Prop. Taxes" />
          {conventional.aCalc && <HtmlDetailTextTwo a={conventional.aCalc.temp_pt.toString()} b={conventional.aCalc.res_pt.toString()} />}
          {fha.aCalc && <HtmlDetailTextTwo a={fha.aCalc.temp_pt.toString()} b={fha.aCalc.res_pt.toString()} />}
          {va.aCalc && <HtmlDetailTextTwo a={va.aCalc.temp_pt.toString()} b={va.aCalc.res_pt.toString()} />}
          {usda.aCalc && <HtmlDetailTextTwo a={usda.aCalc.temp_pt.toString()} b={usda.aCalc.res_pt.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Property Ins." />
          {conventional.aCalc && <HtmlDetailTextTwo a={conventional.aCalc.temp_ins.toString()} b={conventional.aCalc.res_ins.toString()} />}
          {fha.aCalc && <HtmlDetailTextTwo a={fha.aCalc.temp_ins.toString()} b={fha.aCalc.res_ins.toString()} />}
          {va.aCalc && <HtmlDetailTextTwo a={va.aCalc.temp_ins.toString()} b={va.aCalc.res_ins.toString()} />}
          {usda.aCalc && <HtmlDetailTextTwo a={usda.aCalc.temp_ins.toString()} b={usda.aCalc.res_ins.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Condo/HOA" />
          {conventional.aCalc && <HtmlDetailTextTwo a={conventional.aCalc.condo.toString()} b={conventional.aCalc.res_condo.toString()} />}
          {fha.aCalc && <HtmlDetailTextTwo a={fha.aCalc.condo.toString()} b={fha.aCalc.res_condo.toString()} />}
          {va.aCalc && <HtmlDetailTextTwo a={va.aCalc.condo.toString()} b={va.aCalc.res_condo.toString()} />}
          {usda.aCalc && <HtmlDetailTextTwo a={usda.aCalc.condo.toString()} b={usda.aCalc.res_condo.toString()} />}
        </tr>
        <tr>
          <TdBuyerQualLimitMessage />
        </tr>
      </tbody>
    </table>
  );
};

export default BuyerQual;
