import React, { FC } from "react";
import { IDefaultMoleculeProps } from '..';

// import './_formInput.css';
import { useController } from 'react-hook-form';
import ToolTipPrompt from '../ToolTipPrompt';

const FormInput: FC<IDefaultMoleculeProps> = ({
                                                register,
                                                name,
                                                options,
                                                control,
                                                error,
                                                label='Example Label',
                                                ids,
                                                tip='Example Tip',
                                                ...inputProps }) => {
  if ( register )  {
    console.log('FormInput->register', register)
  }
  // FC/control added
  const { field } = useController({ name, control: control, rules: options });

  return (
    <>
      <div className="form-group">
        {error && <div className="alert-expand">{error.message}</div>}
        <label className="sr-only" htmlFor="credits">
          {label}
        </label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" data-tip={tip} data-for={ids}>
              <ToolTipPrompt tip={tip} prompt={label} />
            </span>
          </div>
          <input
            className="form-control"
            {...field}
            id={ids}
            data-cy={ids}
            {...inputProps}
          />
        </div>
      </div>
    </>
         );
};

export default FormInput;
