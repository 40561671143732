import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

type Props = {
  name: string;
  email: string;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 10,
  },
  text: {
    color: '#000000',
    fontSize: 10,
  },
});

const UserInfo = ({}: Props) => {
  return <View style={styles.container}></View>;
};

export default UserInfo;
