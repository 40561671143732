import React from "react";
import { IDefaultProps } from '../../organisms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface ITextFieldTipProps extends IDefaultProps {
  register: any,
  prompt: string,
  ids: string,
  tip: string
}

const TextFieldTip = ({ register, prompt, ids, tip }: ITextFieldTipProps) => {
  return (
    <div className="form-group">
      <label className="sr-only" htmlFor="credits">
        {prompt}
      </label>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" data-tip data-for={ids}>
            <OverlayTrigger placement="right" overlay={<Tooltip>{tip}</Tooltip>}>
              <a
                href="#"
                className="tooltiplabel"
              >
                <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;{prompt}
              </a>
            </OverlayTrigger>
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder=""
          id={ids}
          data-cy={ids}
          {...register(ids)}
        />
      </div>
    </div>
  );
}

export default TextFieldTip;
