import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ClosingCostPdf } from "@ask-christee/ui-pdf";

const PdfButton = ({ dat }: any) => {
  return (
    <PDFDownloadLink
      document={
        <ClosingCostPdf
          dat={dat}
        />
      }
      fileName="closing-cost.pdf"
      style={{
        textDecoration: "none",
        padding: "10px",
        color: "#4a4a4a",
        backgroundColor: "#f2f2f2",
        border: "1px solid #4a4a4a",
      }}
    >
      {({ loading}) =>
        loading ? "Loading PDF..." : "Download PDF"
      }
    </PDFDownloadLink>
  );
}

export default PdfButton;
