import React from "react";
import { IDefaultProps } from '../../organisms';
import ToolTipPrompt from '../ToolTipPrompt';
import { IError } from '../index';
import { useController } from 'react-hook-form';

export interface ITextFieldTipProps extends IDefaultProps {
  register?: any,
  prompt: string,
  ids: string,
  tip: string,
  required?: boolean,
  error?: IError,
  control?: any,
  options?: any
}

const TextFieldTip = ({ register, prompt, ids, tip, required=false, error, control, options }: ITextFieldTipProps) => {
  if (register) {
    console.log('FormInputDatePicker->register', register);
  }
  options = {required: required, ...options};
  let name = ids;
  const { field } = useController({ name, control: control, rules: options });

  console.log('TextFieldTip->required', required)
  console.log('TextFieldTip->error', error)
  return (
    <div className="form-group">
      <label className="sr-only" htmlFor="credits">
        {prompt}
      </label>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" data-tip data-for={ids}>
                        <ToolTipPrompt tip={tip}
                                       prompt={error && required ? prompt + ' Required' : prompt}
                                       alert={!!error} />
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder=""
          id={ids}
          data-cy={ids}
          {...field}
        />
      </div>
    </div>
  );
}

export default TextFieldTip;
