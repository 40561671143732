import React from 'react';
import { TVacationResponse } from '../../../../types/api';
import { DetailText, DetailTextCenter, TdCash, TdCashPercent, TdPercent, TdTextTwoElementsCenter, TdVactionLimitMessage } from '../../../atoms';

export type TVacationReport = TVacationResponse;

const VacationOneTwo = ({ results }: TVacationReport) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <DetailText v="Selected Location" />
          {results!.aCalc1 && <TdTextTwoElementsCenter b={results!.aCalc1.state_sel} a={results!.aCalc1.area} />}
          {results!.aCalc2 && <TdTextTwoElementsCenter b={results!.aCalc2.state_sel} a={results!.aCalc2.area} />}
        </tr>
        <tr>
          <DetailText v="Report Type" />
          {results!.aCalc1 && <TdTextTwoElementsCenter a={results!.aCalc1.report_type} b={results!.aCalc1.fumess} />}
          {results!.aCalc2 && <TdTextTwoElementsCenter a={results!.aCalc2.report_type} b={results!.aCalc2.fumess} />}
        </tr>
        <tr>
          <DetailText v="Christee Ratios Used" />
          {results!.aCalc1 && <DetailTextCenter v={results!.aCalc1.ratio_string} />}
          {results!.aCalc2 && <DetailTextCenter v={results!.aCalc2.ratio_string} />}
        </tr>
        <tr>
          <DetailText v="Purchase Price" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.sp} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.sp} />}
        </tr>
        <tr>
          <DetailText v="Loan Amount" />
          {results!.aCalc1 && <TdCashPercent a={results!.aCalc1.ma} b={results!.aCalc1.ltv} />}
          {results!.aCalc2 && <TdCashPercent a={results!.aCalc2.ma} b={results!.aCalc2.ltv} />}
        </tr>
        <tr>
          <DetailText v="Required Cash" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.r_cash} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.r_cash} />}
        </tr>
        <tr>
          <DetailText v="Cash Available" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.t_cash} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.t_cash} />}
        </tr>
        <tr>
          <DetailText v="Interest Rate" />
          {results!.aCalc1 && <TdPercent v={results!.aCalc1.temp_rate} />}
          {results!.aCalc2 && <TdPercent v={results!.aCalc2.temp_rate} />}
        </tr>
        <tr>
          <DetailText v="Total Monthly Payment" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.t_piti} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.t_piti} />}
        </tr>
        <tr>
          <TdVactionLimitMessage />
        </tr>
      </tbody>
    </table>
  );
};

export default VacationOneTwo;
