import React from 'react';
import { TVacationResponse } from '../../../../types/api';
import { DetailText, DetailTextCenter, TdCash, TdCashPercent, TdDownloadPdfColSpan, TdPercent, TdTextTwoElements } from '../../../atoms';

export type TVacationReport = TVacationResponse;

const VacationOneTwo = ({ results }: TVacationReport) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <DetailText v="Selected Location" />
          {results!.aCalc1 && <TdTextTwoElements b={results!.aCalc1.state_sel} a={results!.aCalc1.area} />}
          {results!.aCalc2 && <TdTextTwoElements b={results!.aCalc2.state_sel} a={results!.aCalc2.area} />}
        </tr>
        <tr>
          <DetailText v="Report Type" />
          {results!.aCalc1 && <TdTextTwoElements a={results!.aCalc1.report_type} b={results!.aCalc1.fumess} />}
          {results!.aCalc2 && <TdTextTwoElements a={results!.aCalc2.report_type} b={results!.aCalc2.fumess} />}
        </tr>
        <tr>
          <DetailText v="Christee Ratios Used" />
          {results!.aCalc1 && <DetailTextCenter v={results!.aCalc1.ratio_string} />}
          {results!.aCalc2 && <DetailTextCenter v={results!.aCalc2.ratio_string} />}
        </tr>
        <tr>
          <DetailText v="Target Payment" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.target_piti} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.target_piti} />}
        </tr>
        <tr>
          <DetailText v="Purchase Price" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.sp} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.sp} />}
        </tr>
        <tr>
          <DetailText v="Loan Amount" />
          {results!.aCalc1 && <TdCashPercent a={results!.aCalc1.ma} b={results!.aCalc1.ltv} />}
          {results!.aCalc2 && <TdCashPercent a={results!.aCalc2.ma} b={results!.aCalc2.ltv} />}
        </tr>
        <tr>
          <DetailText v="Down payment" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.d_payment} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.d_payment} />}
        </tr>
        <tr>
          <DetailText v="Closing Cost" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.totalcc} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.totalcc} />}
        </tr>
        <tr>
          <DetailText v="Seller Closing Credit" />
          {results!.aCalc1 && <TdCashPercent a={results!.aCalc1.dol_cred} b={results!.aCalc1.temp_cred} />}
          {results!.aCalc2 && <TdCashPercent a={results!.aCalc2.dol_cred} b={results!.aCalc2.temp_cred} />}
        </tr>
        <tr>
          <DetailText v="Lender Closing Credit" />
          {results!.aCalc1 && <TdCashPercent a={results!.aCalc1.dol_lend} b={results!.aCalc1.temp_lend} />}
          {results!.aCalc2 && <TdCashPercent a={results!.aCalc2.dol_lend} b={results!.aCalc2.temp_lend} />}
        </tr>
        <tr>
          <DetailText v="Required Cash" />

          {results!.aCalc1 && <TdCash v={results!.aCalc1.r_cash} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.r_cash} />}
        </tr>
        <tr>
          <DetailText v="Cash Available" />

          {results!.aCalc1 && <TdCash v={results!.aCalc1.t_cash} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.t_cash} />}
        </tr>
        <tr>
          <DetailText v="Additional Cash Required" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.ex_cash} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.ex_cash} />}
        </tr>
        <tr>
          <DetailText v="Interest Rate" />
          {results!.aCalc1 && <TdPercent v={results!.aCalc1.temp_rate} />}
          {results!.aCalc2 && <TdPercent v={results!.aCalc2.temp_rate} />}
        </tr>
        <tr>
          <DetailText v="Total Monthly Payment" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.t_piti} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.t_piti} />}
        </tr>
        <tr>
          <DetailText v="Principal and Interest" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.temp_pi} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.temp_pi} />}
        </tr>
        <tr>
          <DetailText v="Monthly Mortgage Insurance" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.temp_mi} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.temp_mi} />}
        </tr>
        <tr>
          <DetailText v="Monthly Property Taxes" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.temp_pt} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.temp_pt} />}
        </tr>
        <tr>
          <DetailText v="Monthly Property Insurance" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.temp_ins} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.temp_ins} />}
        </tr>
        <tr>
          <DetailText v="Monthly Condo/HOA" />
          {results!.aCalc1 && <TdCash v={results!.aCalc1.condo} />}
          {results!.aCalc2 && <TdCash v={results!.aCalc2.condo} />}
        </tr>
        <tr>
          <TdDownloadPdfColSpan v={2} />
        </tr>
      </tbody>
    </table>
  );
};

export default VacationOneTwo;
