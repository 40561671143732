import React from 'react';
import { TSellerNetResponse } from '../../../../types/api';
import { DetailText, DetailTextCenter, TdBlank, TdCash, TdCashPercent, TdDownloadPdfColSpan } from '../../../atoms';

export type TSellerNetReport = TSellerNetResponse;

const SellerNet = ({ results }: TSellerNetReport) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <TdBlank />
          <DetailTextCenter v={results!.aCalc.fumess} />
        </tr>
        <tr>
          <TdBlank />
          <DetailTextCenter v={results!.aCalc.current_date} />
        </tr>
        <tr>
          <TdBlank />
          <DetailTextCenter v={results!.aCalc.report_type} />
        </tr>
        <tr>
          <DetailText v="Sales Price" />
          <TdCash v={results!.aCalc.sp} />
        </tr>
        <tr>
          <DetailText v="Mortgage Payoff" />
          <TdCash v={results!.aCalc.total_payoff} />
        </tr>
        <tr>
          <DetailText v="Transfer Tax" />
          <TdCash v={results!.aCalc.transfer} />
        </tr>
        <tr>
          <DetailText v="Documentary Stamps" />
          <TdCash v={results!.aCalc.doc_stamps} />
        </tr>
        <tr>
          <DetailText v="Survey Charge" />
          <TdCash v={results!.aCalc.s_survey} />
        </tr>
        <tr>
          <DetailText v="Seller Closing Fee" />
          <TdCash v={results!.aCalc.s_clo_fee} />
        </tr>
        <tr>
          <DetailText v="Seller Doc Prep Fee" />
          <TdCash v={results!.aCalc.s_docprep} />
        </tr>
        <tr>
          <DetailText v="Title Abstract Fee" />
          <TdCash v={results!.aCalc.s_abs_fee} />
        </tr>
        <tr>
          <DetailText v="Closing Attorney Fee" />
          <TdCash v={results!.aCalc.s_attorney} />
        </tr>
        <tr>
          <DetailText v="Title Agent Fee" />
          <TdCash v={results!.aCalc.s_title_agent} />
        </tr>
        <tr>
          <DetailText v="Title Insurance" />
          <TdCash v={results!.aCalc.s_tit_ins} />
        </tr>
        <tr>
          <DetailText v="Mortgage Release" />
          <TdCash v={results!.aCalc.mtge_release} />
        </tr>
        <tr>
          <DetailText v="Real Estate Commission" />
          <TdCashPercent a={results!.aCalc.broker_fee} b={results!.aCalc.comm} />
        </tr>
        <tr>
          <DetailText v="Buyer Closing Credit" />
          <TdCashPercent a={results!.aCalc.dol_cred} b={results!.aCalc.temp_cred} />
        </tr>
        <tr>
          <DetailText v="Buyer Loan Discount Fee" />
          <TdCash v={results!.aCalc.loan_org} />
        </tr>
        <tr>
          <DetailText v="Closing Charges to Seller" />
          <TdCash v={results!.aCalc.seller_charges} />
        </tr>
        <tr>
          <DetailText v="Property Taxes Adjustment" />
          <TdCash v={results!.aCalc.dol_tax_adj} />
        </tr>
        <tr>
          <DetailText v="Estimated Seller Proceeds" />
          <TdCash v={results!.aCalc.seller_net} />
        </tr>
        <tr>
          <TdDownloadPdfColSpan v={2} />
        </tr>
      </tbody>
    </table>
  );
};

export default SellerNet;
