/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { SellerNetPdf } from "@ask-christee/ui-pdf";

const PdfButton = ({ dat }: any) => {
    console.log(dat);
    return (
    <PDFDownloadLink
      document={
        <SellerNetPdf
            dat={{...dat, title: "Christee Report: Seller Net"}}
        />
      }
      fileName="seller-net.pdf"
      style={{
        textDecoration: "none",
        padding: "10px",
        color: "#4a4a4a",
        backgroundColor: "#f2f2f2",
        border: "1px solid #4a4a4a",
      }}
    >
      {({ loading}) =>
        loading ? "Loading PDF..." : "Download PDF"
      }
    </PDFDownloadLink>
  );
}

export default PdfButton;
