import React from "react";
import DatePicker from 'react-date-picker';
import { useController } from 'react-hook-form';
import { IDefaultMoleculeProps } from '../index';
import './style.css';
import ToolTipPrompt from '../ToolTipPrompt';

const FormInputDatePicker = ({ register, name, options, control, error, label = 'Example Label', ids, tip = 'Example Tip' }: IDefaultMoleculeProps) => {

  if (register) {
    console.log('FormInputDatePicker->register', register);
  }
  const { field } = useController({ name, control: control, rules: options });


  return (
    <div className="form-group">
      {error && <div className="alert-expand">{error.message}</div>}
      <label className="sr-only" htmlFor={name}>
        {label}
      </label>
      <div className="input-group">
        <div className="input-group-prepend">
           <span className={error ? "input-group-text alert-expand" : "input-group-text"} data-for={ids}>
                        <ToolTipPrompt tip={tip}
                                       prompt={error ? label + ' Required' : label}
                                       alert={!!error} />
          </span>
        </div>
        <div className="date-picker-div">
          <DatePicker
            className="date-picker"
            {...field}
            onChange={field.onChange}
            value={field.value}
            clearIcon={null}
          />
        </div>
      </div>
    </div>
  );
}

export default FormInputDatePicker;
