import React from 'react';
import { TConsumerDebtResponseObject } from '../../../../types/api';
import { DetailText, TdDownloadPdfColSpan } from '../../../atoms';

const Consumerdebt = (consumerdebt: TConsumerDebtResponseObject | undefined) => {
  return (
    <table className="table">
      <tbody>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.fumess} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.fumess1} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess1a} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess2} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess3} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess3a} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess4} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess5} />}</tr>
        <tr>{consumerdebt!.browser && <DetailText v={consumerdebt!.browser.pdfmess6} />}</tr>
        <tr>
          <TdDownloadPdfColSpan v={3} />
        </tr>
      </tbody>
    </table>
  );
};

export default Consumerdebt;
