import React from 'react';
import { TVacationReport } from '..';
import { DetailText, DetailTextCenter, TdCash, TdCashPercent, TdDownloadPdfColSpan, TdPercent, TdTextTwoElements } from '../../../atoms';

const VacationThreeFour = ({ results }: TVacationReport) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <DetailText v="Selected Location" />
          {results!.aCalc3 && <TdTextTwoElements b={results!.aCalc3.state_sel} a={results!.aCalc3.area} />}
          {results!.aCalc4 && <TdTextTwoElements b={results!.aCalc4.state_sel} a={results!.aCalc4.area} />}
        </tr>
        <tr>
          <DetailText v="Report Type" />
          {results!.aCalc3 && <TdTextTwoElements a={results!.aCalc3.report_type} b={results!.aCalc3.fumess} />}
          {results!.aCalc4 && <TdTextTwoElements a={results!.aCalc4.report_type} b={results!.aCalc4.fumess} />}
        </tr>
        <tr>
          <DetailText v="Christee Ratios Used" />
          {results!.aCalc3 && <DetailTextCenter v={results!.aCalc3.ratio_string} />}
          {results!.aCalc4 && <DetailTextCenter v={results!.aCalc4.ratio_string} />}
        </tr>
        <tr>
          <DetailText v="Target Payment" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.target_piti} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.target_piti} />}
        </tr>
        <tr>
          <DetailText v="Purchase Price" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.sp} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.sp} />}
        </tr>
        <tr>
          <DetailText v="Loan Amount" />
          {results!.aCalc3 && <TdCashPercent a={results!.aCalc3.ma} b={results!.aCalc3.ltv} />}
          {results!.aCalc4 && <TdCashPercent a={results!.aCalc4.ma} b={results!.aCalc4.ltv} />}
        </tr>
        <tr>
          <DetailText v="Down payment" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.d_payment} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.d_payment} />}
        </tr>
        <tr>
          <DetailText v="Closing Cost" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.totalcc} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.totalcc} />}
        </tr>
        <tr>
          <DetailText v="Seller Closing Credit" />
          {results!.aCalc3 && <TdCashPercent a={results!.aCalc3.dol_cred} b={results!.aCalc3.temp_cred} />}
          {results!.aCalc4 && <TdCashPercent a={results!.aCalc4.dol_cred} b={results!.aCalc4.temp_cred} />}
        </tr>
        <tr>
          <DetailText v="Lender Closing Credit" />
          {results!.aCalc3 && <TdCashPercent a={results!.aCalc3.dol_lend} b={results!.aCalc3.temp_lend} />}
          {results!.aCalc4 && <TdCashPercent a={results!.aCalc4.dol_lend} b={results!.aCalc4.temp_lend} />}
        </tr>
        <tr>
          <DetailText v="Required Cash" />

          {results!.aCalc3 && <TdCash v={results!.aCalc3.r_cash} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.r_cash} />}
        </tr>
        <tr>
          <DetailText v="Cash Available" />

          {results!.aCalc3 && <TdCash v={results!.aCalc3.t_cash} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.t_cash} />}
        </tr>
        <tr>
          <DetailText v="Additional Cash Required" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.ex_cash} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.ex_cash} />}
        </tr>
        <tr>
          <DetailText v="Interest Rate" />
          {results!.aCalc3 && <TdPercent v={results!.aCalc3.temp_rate} />}
          {results!.aCalc4 && <TdPercent v={results!.aCalc4.temp_rate} />}
        </tr>
        <tr>
          <DetailText v="Total Monthly Payment" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.t_piti} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.t_piti} />}
        </tr>
        <tr>
          <DetailText v="Principal and Interest" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.temp_pi} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.temp_pi} />}
        </tr>
        <tr>
          <DetailText v="Monthly Mortgage Insurance" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.temp_mi} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.temp_mi} />}
        </tr>
        <tr>
          <DetailText v="Monthly Property Taxes" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.temp_pt} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.temp_pt} />}
        </tr>
        <tr>
          <DetailText v="Monthly Property Insurance" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.temp_ins} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.temp_ins} />}
        </tr>
        <tr>
          <DetailText v="Monthly Condo/HOA" />
          {results!.aCalc3 && <TdCash v={results!.aCalc3.condo} />}
          {results!.aCalc4 && <TdCash v={results!.aCalc4.condo} />}
        </tr>
        <tr>
          <TdDownloadPdfColSpan v={2} />
        </tr>
      </tbody>
    </table>
  );
};

export default VacationThreeFour;
