import React from "react";
import { IDefaultProps } from '..';

export interface ITextFieldRequiredProps extends IDefaultProps {
register: any,
                  prompt: string,
                  ids: string
}

const TextFieldRequired = ({ register, prompt, ids }: ITextFieldRequiredProps) => {
  return (
    <div className="form-group">
      <label className="sr-only" htmlFor="credits">
        {prompt}
      </label>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" data-tip data-for={ids}>
            {prompt}
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder=""
          id={ids}
          data-cy={ids}
          {...register(ids, { required: true })}
        />
      </div>
    </div>
  );
}

export default TextFieldRequired;
