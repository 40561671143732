/**
 * Using RowMessage to display an empty row
 * Using RowMessage to add a prompt that spans the entire report
 */
import React, { FC } from 'react';
import { TBuyOrRentResponseObject } from '../../../../types/api';
import { DetailCashNaked, DetailText, TdDownloadPdfColSpan, DetailTextCenter, RowMessage } from '../../../atoms';

interface Props {
  conventional: TBuyOrRentResponseObject | undefined;
  fha: TBuyOrRentResponseObject | undefined;
  va: TBuyOrRentResponseObject | undefined;
  usda: TBuyOrRentResponseObject | undefined;
}

const BuyOrRent: FC<Props> = ({ conventional, fha, va, usda }) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <DetailText v="Buy or Rent Results Christee" />
          {conventional?.aCalc && <DetailTextCenter v="" />}
          {fha?.aCalc && <DetailTextCenter v="" />}
          {va?.aCalc && <DetailTextCenter v="" />}
          {usda?.aCalc && <DetailTextCenter v="" />}
        </tr>
        <tr>
          <DetailText v="Location" />
          {conventional?.aCalc && <DetailTextCenter v={conventional?.aCalc.local$} />}
          {fha?.aCalc && <DetailTextCenter v={fha?.aCalc.local$} />}
          {va?.aCalc && <DetailTextCenter v={va?.aCalc.local$} />}
          {usda?.aCalc && <DetailTextCenter v={usda?.aCalc.local$} />}
        </tr>
        <tr>
          {conventional?.aCalc && <RowMessage v={5} prompt={conventional?.aCalc.detail$} />}
          {fha?.aCalc && <RowMessage v={5} prompt={fha?.aCalc.detail$} />}
          {va?.aCalc && <RowMessage v={5} prompt={va?.aCalc.detail$} />}
          {usda?.aCalc && <RowMessage v={5} prompt={usda?.aCalc.detail$} />}
        </tr>
        <tr>
          <DetailText v="Payment Analysis" />
          {conventional?.aCalc && <DetailTextCenter v="" />}
          {fha?.aCalc && <DetailTextCenter v="" />}
          {va?.aCalc && <DetailTextCenter v="" />}
          {usda?.aCalc && <DetailTextCenter v="" />}
        </tr>
        <tr>
          <DetailText v="Average Monthly Rent" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.m_rent.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.m_rent.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.m_rent.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.m_rent.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Mortgage Payment" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.t_piti.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.t_piti.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.t_piti.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.t_piti.toString()} />}
        </tr>

        <tr>
          <DetailText v="Net Mortgage Payment" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.net_mopiti.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.net_mopiti.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.net_mopiti.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.net_mopiti.toString()} />}
        </tr>
        <tr>
          <RowMessage v={5} prompt="" />
        </tr>
        <tr>
          <RowMessage v={5} prompt="Purchase Details" />
        </tr>
        <tr>
          <DetailText v="Purchase Price" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.sp.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.sp.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.sp.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.sp.toString()} />}
        </tr>
        <tr>
          <DetailText v="Mortgage Amount" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.ma.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.ma.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.ma.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.ma.toString()} />}
        </tr>
        <tr>
          <DetailText v="Down Payment" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.d_payment.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.d_payment.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.d_payment.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.d_payment.toString()} />}
        </tr>
        <tr>
          <DetailText v="Total Closing Cost" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.totalcc.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.totalcc.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.totalcc.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.totalcc.toString()} />}
        </tr>
        <tr>
          <DetailText v="Closing Cost Credit" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.dol_cred.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.dol_cred.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.dol_cred.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.dol_cred.toString()} />}
        </tr>
        <tr>
          <DetailText v="Cash Required" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.r_cash.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.r_cash.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.r_cash.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.r_cash.toString()} />}
        </tr>
        <tr>
          <RowMessage v={5} prompt="" />
        </tr>
        <tr>
          {conventional?.aCalc && <RowMessage v={5} prompt={conventional?.aCalc.project$} />}
          {fha?.aCalc && <RowMessage v={5} prompt={fha?.aCalc.project$} />}
          {va?.aCalc && <RowMessage v={5} prompt={va?.aCalc.project$} />}
          {usda?.aCalc && <RowMessage v={5} prompt={usda?.aCalc.project$} />}
        </tr>
        <tr>
          <DetailText v="Future Home Value" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.h_value} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.h_value} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.h_value} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.h_value} />}
        </tr>
        <tr>
          <DetailText v="Minus Mortgage Balance" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.ma_bal.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.ma_bal.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.ma_bal.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.ma_bal.toString()} />}
        </tr>
        <tr>
          <DetailText v="Equals Home Equity" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.net_equity} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.net_equity} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.net_equity} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.net_equity} />}
        </tr>
        <tr>
          <DetailText v="Value of Cash Investment" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.cash_value} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.cash_value} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.cash_value} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.cash_value} />}
        </tr>
        <tr>
          <DetailText v="Home Ownership Gain/Loss" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.net_gain} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.net_gain} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.net_gain} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.net_gain} />}
        </tr>
        <tr>
          <RowMessage v={5} prompt="" />
        </tr>
        <tr>
          <RowMessage v={5} prompt="Tax Considerations" />
        </tr>
        <tr>
          <DetailText v="Average Annual Deduction" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.avg_duct.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.avg_duct.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.avg_duct.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.avg_duct.toString()} />}
        </tr>
        <tr>
          <DetailText v="Average Annual Tax Savings" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.avg_taxs.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.avg_taxs.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.avg_taxs.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.avg_taxs.toString()} />}
        </tr>
        <tr>
          <DetailText v="Monthly Mortgage Payment" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.t_piti.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.t_piti.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.t_piti.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.t_piti.toString()} />}
        </tr>
        <tr>
          <RowMessage v={5} prompt="Minus" />
        </tr>
        <tr>
          <DetailText v="Average Monthly Tax Savings" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.mon_taxs.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.mon_taxs.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.mon_taxs.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.mon_taxs.toString()} />}
        </tr>
        <tr>
          <DetailText v="Net Mortgage Payment" />
          {conventional?.aCalc && <DetailCashNaked v={conventional?.aCalc.net_mopiti.toString()} />}
          {fha?.aCalc && <DetailCashNaked v={fha?.aCalc.net_mopiti.toString()} />}
          {va?.aCalc && <DetailCashNaked v={va?.aCalc.net_mopiti.toString()} />}
          {usda?.aCalc && <DetailCashNaked v={usda?.aCalc.net_mopiti.toString()} />}
        </tr>
        <tr>
          <TdDownloadPdfColSpan v={5} />
        </tr>
      </tbody>
    </table>
  );
};

export default BuyOrRent;
