/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { TBuyerQualResponseObject } from '../../../../types/api/buyerqual';
import GenericPie from '../InitialPie/GenericPie';

interface Props {
  conventional: TBuyerQualResponseObject;
  fha: TBuyerQualResponseObject;
  va: TBuyerQualResponseObject;
  usda: TBuyerQualResponseObject;
}

const BuyerQual = ({ conventional, fha, va, usda }: Props) => {
  return (
    <Container>
      <Row>
        {conventional.aCalc && (
          <GenericPie
            report_type={conventional.aCalc.report_type + ': ' + 'Minimum Cash'}
            fumess={conventional.aCalc.fumess}
            temp_pi={conventional.aCalc.temp_pi}
            temp_pt={conventional.aCalc.temp_pt}
            temp_mi={conventional.aCalc.temp_mi}
            temp_ins={conventional.aCalc.temp_ins}
            condo={conventional.aCalc.condo}
            t_piti={conventional.aCalc.t_piti}
          />
        )}
      </Row>
      <Row>
        {conventional.bCalc && (
          <GenericPie
            report_type={conventional.bCalc.report_type + ': ' + 'Spending Cash'}
            fumess={conventional.bCalc.fumess}
            temp_pi={conventional.bCalc.temp_pi}
            temp_pt={conventional.bCalc.temp_pt}
            temp_mi={conventional.bCalc.temp_mi}
            temp_ins={conventional.bCalc.temp_ins}
            condo={conventional.bCalc.condo}
            t_piti={conventional.bCalc.t_piti}
          />
        )}
      </Row>
      <Row>
        {fha.aCalc && (
          <GenericPie
            report_type={fha.aCalc.report_type + ': ' + 'Minimum Cash'}
            fumess={fha.aCalc.fumess}
            temp_pi={fha.aCalc.temp_pi}
            temp_pt={fha.aCalc.temp_pt}
            temp_mi={fha.aCalc.temp_mi}
            temp_ins={fha.aCalc.temp_ins}
            condo={fha.aCalc.condo}
            t_piti={fha.aCalc.t_piti}
          />
        )}
      </Row>
      <Row>
        {fha.bCalc && (
          <GenericPie
            report_type={fha.bCalc.report_type + ': ' + 'Spending Cash'}
            fumess={fha.bCalc.fumess}
            temp_pi={fha.bCalc.temp_pi}
            temp_pt={fha.bCalc.temp_pt}
            temp_mi={fha.bCalc.temp_mi}
            temp_ins={fha.bCalc.temp_ins}
            condo={fha.bCalc.condo}
            t_piti={fha.bCalc.t_piti}
          />
        )}
      </Row>
      <Row>
        {va.aCalc && (
          <GenericPie
            report_type={va.aCalc.report_type + ': ' + 'Minimum Cash'}
            fumess={va.aCalc.fumess}
            temp_pi={va.aCalc.temp_pi}
            temp_pt={va.aCalc.temp_pt}
            temp_mi={va.aCalc.temp_mi}
            temp_ins={va.aCalc.temp_ins}
            condo={va.aCalc.condo}
            t_piti={va.aCalc.t_piti}
          />
        )}
      </Row>
      <Row>
        {va.bCalc && (
          <GenericPie
            report_type={va.bCalc.report_type + ': ' + 'Spending Cash'}
            fumess={va.bCalc.fumess}
            temp_pi={va.bCalc.temp_pi}
            temp_pt={va.bCalc.temp_pt}
            temp_mi={va.bCalc.temp_mi}
            temp_ins={va.bCalc.temp_ins}
            condo={va.bCalc.condo}
            t_piti={va.bCalc.t_piti}
          />
        )}
      </Row>
      <Row>
        {usda.aCalc && (
          <GenericPie
            report_type={usda.aCalc.report_type + ': ' + 'Minimum Cash'}
            fumess={usda.aCalc.fumess}
            temp_pi={usda.aCalc.temp_pi}
            temp_pt={usda.aCalc.temp_pt}
            temp_mi={usda.aCalc.temp_mi}
            temp_ins={usda.aCalc.temp_ins}
            condo={usda.aCalc.condo}
            t_piti={usda.aCalc.t_piti}
          />
        )}
      </Row>
      <Row>
        {usda.bCalc && (
          <GenericPie
            report_type={usda.bCalc.report_type + ': ' + 'Spending Cash'}
            fumess={usda.bCalc.fumess}
            temp_pi={usda.bCalc.temp_pi}
            temp_pt={usda.bCalc.temp_pt}
            temp_mi={usda.bCalc.temp_mi}
            temp_ins={usda.bCalc.temp_ins}
            condo={usda.bCalc.condo}
            t_piti={usda.bCalc.t_piti}
          />
        )}
      </Row>
    </Container>
  );
};

export default BuyerQual;
