import React from "react";
import {
  DetailCashNaked, DetailText, DetailTextCenter,
  HtmlDetailTextFive, HtmlDetailTextSix, HtmlDetailTextThree, HtmlDetailTextTwo,
} from "../../../atoms";

export type TDatBuyRent = {
  conventional: {
    state_sel: string, fumess: string, area: string, report_years: string, m_rent: string,
    sp: string, ma: string, temp_rate: string, ltv: string, total_mtge: string, t_piti: string,
    temp_pi: string, temp_mi: string, temp_pt: string, temp_ins: string,
    d_payment: string, totalcc: string, dol_cred: string, temp_cred: string, r_cash: string,
    total_rent: string, total_piti: string, total_net_payment: string, tot_duct: string,
    total_int: string, total_taxes: string, tax_saving: string, print_t_bracket: string,
    net_mopiti: string, cash_value: string, temp_return: string, h_value: string, temp_app: string
    ma_bal: string, net_equity: string, net_gain: string, rate_return: string, avg_duct: string,
    avg_taxs: string, mon_taxs: string, project$: string, detail$: string, local$: string,
  }
  fha: any, va: any
}


const BuyRent = ( dat : TDatBuyRent) => {
  return (
    <table className="table">
      <thead> </thead>

      <tbody>
      <tr>
        <DetailText v="Buy or Rent Results Christee" />
        {dat.conventional && <DetailTextCenter v="" />}
      </tr>

      <tr>
        <DetailText v="Payment Analysis" />
        {dat.conventional && <DetailTextCenter v="" />}
      </tr>

      <tr>
        <DetailText v="Average Monthly Rent" />
        {dat.conventional && <DetailCashNaked v={dat.conventional.m_rent} />}
      </tr>

      <tr>
        <DetailText v="Monthly Mortgage Payment" />
        {dat.conventional && <DetailCashNaked v={dat.conventional.t_piti} />}
        {dat.fha && <DetailCashNaked v={dat.fha.t_piti} />}
        {dat.va && <DetailCashNaked v={dat.va.t_piti} />}
      </tr>

      <tr>
        <DetailText v="Effective Mortgage Payment" />
        {dat.conventional && (
          <DetailCashNaked v={dat.conventional.net_mopiti} />
        )}
        {dat.fha && <DetailCashNaked v={dat.fha.net_mopiti} />}
        {dat.va && <DetailCashNaked v={dat.va.net_mopiti} />}
      </tr>

      <tr>
        <DetailText v="" />
        {dat.conventional && <DetailTextCenter v="" />}
        {dat.fha && <DetailTextCenter v="" />}
        {dat.va && <DetailTextCenter v="" />}
      </tr>

      <tr>
        <DetailText v="Purchase Details" />
        {dat.conventional && <DetailTextCenter v="" />}
        {dat.fha && <DetailTextCenter v="" />}
        {dat.va && <DetailTextCenter v="" />}
      </tr>

      <tr>
        <DetailText v="Purchase Price" />
        {dat.conventional && <DetailCashNaked v={dat.conventional.sp} />}
        {dat.fha && <DetailCashNaked v={dat.fha.sp} />}
        {dat.va && <DetailCashNaked v={dat.va.sp} />}
      </tr>

      <tr>
        <DetailText v="Mortgage Amount" />
        {dat.conventional && (
          <HtmlDetailTextThree
            a=""
            b={dat.conventional.ma}
            c="LTV"
            d={dat.conventional.ltv}
          />
        )}
        {dat.fha && (
          <HtmlDetailTextThree a="" b={dat.fha.ma} c="LTV" d={dat.fha.ltv} />
        )}
        {dat.va && (
          <HtmlDetailTextThree a="" b={dat.va.ma} c="LTV" d={dat.va.ltv} />
        )}
      </tr>

      <tr>
        <DetailText v="Down Payment" />
        {dat.conventional && <DetailCashNaked v={dat.conventional.d_payment} />}
        {dat.fha && <DetailCashNaked v={dat.fha.d_payment} />}
        {dat.va && <DetailCashNaked v={dat.va.d_payment} />}
      </tr>

      <tr>
        <DetailText v="Total Closing Cost" />
        {dat.conventional && <DetailCashNaked v={dat.conventional.totalcc} />}
        {dat.fha && <DetailCashNaked v={dat.fha.totalcc} />}
        {dat.va && <DetailCashNaked v={dat.va.totalcc} />}
      </tr>

      <tr>
        <DetailText v="Seller Paid Closing" />
        {dat.conventional && (
          <HtmlDetailTextTwo
            a={dat.conventional.dol_cred}
            b={dat.conventional.temp_cred}
          />
        )}
        {dat.fha && (
          <HtmlDetailTextTwo a={dat.fha.dol_cred} b={dat.fha.temp_cred} />
        )}
        {dat.va && (
          <HtmlDetailTextTwo a={dat.va.dol_cred} b={dat.va.temp_cred} />
        )}
      </tr>

      <tr>
        <DetailText v="Cash Required" />
        {dat.conventional && <DetailCashNaked v={dat.conventional.r_cash} />}
        {dat.fha && <DetailCashNaked v={dat.fha.r_cash} />}
        {dat.va && <DetailCashNaked v={dat.va.r_cash} />}
      </tr>

      <tr>
        <DetailText v="" />
        {dat.conventional && <DetailTextCenter v="" />}
        {dat.fha && <DetailTextCenter v="" />}
        {dat.va && <DetailTextCenter v="" />}
      </tr>

      <tr>
        <DetailText v="Future Home Value" />
        {dat.conventional && (
          <HtmlDetailTextThree
            a=""
            b={dat.conventional.h_value}
            c="Compounded at"
            d={dat.conventional.temp_app}
          />
        )}
        {dat.fha && (
          <HtmlDetailTextThree
            a=""
            b={dat.fha.h_value}
            c="Compounded at"
            d={dat.fha.temp_app}
          />
        )}
        {dat.va && (
          <HtmlDetailTextThree
            a=""
            b={dat.va.h_value}
            c="Compounded at"
            d={dat.va.temp_app}
          />
        )}
      </tr>

      <tr>
        <DetailText v="Minus Mortgage Balance" />
        {dat.conventional && <DetailCashNaked v={dat.conventional.ma_bal} />}
        {dat.fha && <DetailCashNaked v={dat.fha.ma_bal} />}
        {dat.va && <DetailCashNaked v={dat.va.ma_bal} />}
      </tr>

      <tr>
        <DetailText v="Equals Home Equity" />
        {dat.conventional && (
          <HtmlDetailTextFive
            a={dat.conventional.net_equity}
            b={dat.conventional.report_years}
          />
        )}
        {dat.fha && (
          <HtmlDetailTextFive a={dat.fha.net_equity} b={dat.fha.report_years} />
        )}
        {dat.va && (
          <HtmlDetailTextFive a={dat.va.net_equity} b={dat.va.report_years} />
        )}
      </tr>

      <tr>
        <DetailText v="Future Value of Cash Investment" />
        {dat.conventional && (
          <HtmlDetailTextThree
            a=""
            b={dat.conventional.cash_value}
            c="Compounded at"
            d={dat.conventional.temp_return}
          />
        )}
        {dat.fha && (
          <HtmlDetailTextThree
            a=""
            b={dat.fha.cash_value}
            c="Compounded at"
            d={dat.fha.temp_return}
          />
        )}
        {dat.va && (
          <HtmlDetailTextThree
            a=""
            b={dat.va.cash_value}
            c="Compounded at"
            d={dat.va.temp_return}
          />
        )}
      </tr>

      <tr>
        <DetailText v="Net Gain/Loss" />
        {dat.conventional && (
          <HtmlDetailTextSix a={dat.conventional.net_gain} />
        )}
        {dat.fha && <HtmlDetailTextSix a={dat.fha.net_gain} />}
        {dat.va && <HtmlDetailTextSix a={dat.va.net_gain} />}
      </tr>

      <tr>
        <DetailText v="" />
        {dat.conventional && <DetailTextCenter v="" />}
        {dat.fha && <DetailTextCenter v="" />}
        {dat.va && <DetailTextCenter v="" />}
      </tr>

      <tr>
        <DetailText v="Tax Considerations" />
        {dat.conventional && <DetailTextCenter v="" />}
        {dat.fha && <DetailTextCenter v="" />}
        {dat.va && <DetailTextCenter v="" />}
      </tr>

      <tr>
        <DetailText v="Monthly Mortgage Payment" />
        {dat.conventional && <DetailCashNaked v={dat.conventional.t_piti} />}
        {dat.fha && <DetailCashNaked v={dat.fha.t_piti} />}
        {dat.va && <DetailCashNaked v={dat.va.t_piti} />}
      </tr>
      </tbody>
    </table>
  );
}

export default BuyRent;

