import React from 'react';
import { useController } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

type FormInputPercentProps = {
  label: string;
  tip: string;
  name: string;
  error: any;
  control: any;
  required?: boolean;
  min?: number;
  max?: number;
};

const FormInputPercent2 = ({ label, tip, name, error, control, required = false, min = 0, max = 100 }: FormInputPercentProps) => {
  const options = {
    required: required,
    min: min,
    max: max,
    pattern: {
      value: /(?=.*?\d)^\-?\%?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.|\.\d{1,3})?$/,
      message: 'Numeric required',
    },
  };

  const { field } = useController({ name, control: control, rules: options, defaultValue: '' });

  const errorMessage = required ? (label.length < 19 ? 'Required' : 'Req.') : label.length < 19 ? 'Incorrect' : 'Inc.';

  return (
    <div className="sp-input">
      <FloatingLabel controlId="floatingSelect" label={error ? label + ' ' + errorMessage : label} className={error ? 'alert-expand' : ''}>
        <input
          {...field}
          maxLength={field.value > 9 ? 6 : 5}
          min={min}
          max={max}
          type="text"
          className="form-control"
          id={name}
          data-cy={name}
          data-test={name}
          placeholder=""
          autoComplete="off"
          aria-label="Percent between 0 and 100 for label"
        />
        <i className="percent">%</i>
        <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
          <a className="tooltippanel">
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </OverlayTrigger>
      </FloatingLabel>
    </div>
  );
};

export default FormInputPercent2;
