import React from 'react';

interface LocalProps {
  a: string | number;
  b: string | number;
}

export const TextPercent = ({ a, b }: LocalProps) => {
  return (
    <td align="left">
      {a}&nbsp;{b}%
    </td>
  );
};
