import React from 'react';
import { useController } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

type FormInputTextProps = {
  disabled?: boolean;
  label: string;
  tip: string;
  name: string;
  error: any;
  control: any;
  required?: boolean;
};

const FormInputText = ({ label, tip, error, name, control, disabled = false, required = false }: FormInputTextProps) => {
  const options = {
    required: required,
  };

  const { field } = useController({ name, control: control, rules: options, defaultValue: '' });

  return (
    <div className="sp-input w-100">
      <FloatingLabel controlId="floatingSelect" label={error ? label + ' Incorrect' : label} className={error ? 'alert-expand' : ''}>
        <input disabled={disabled} type="text" className="form-control" id={name} data-cy={name} data-test={name} placeholder="" autoComplete="ignore-me" aria-label="Text Input" {...field} />
        <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
          <a className="tooltippanel">
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </OverlayTrigger>
      </FloatingLabel>
    </div>
  );
};

export default FormInputText;
