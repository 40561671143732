import React from "react";
import { IDefaultProps } from '../../organisms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export interface IRadioOptionalTipProps extends IDefaultProps {
        register: any,
        prompt: string,
        ids: string,
        tip: string
}

const RadioOptionalTip = ({ register, prompt, ids, tip }: IRadioOptionalTipProps) => {
  let radioYes = ids + "_yes";
  let radioNo = ids + "_no";
  return (
    <div className="form-group">
      <label htmlFor={ids} data-type="info" data-tip data-for={ids}>
        <a
          className="infotip"
          href="#"
          data-toggle="tooltip"
          data-placement="right"
          title={tip}
        >
        <FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;{prompt}
        </a>
      </label>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          id={radioYes}
          value="y"
          data-cy={radioYes}
          {...register(ids)}
        />
        <label className="form-check-label" htmlFor="exempt_yes">
          Yes
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="radio"
          id={radioNo}
          value="n"
          data-cy={radioNo}
          {...register(ids)}
        />
        <label className="form-check-label" htmlFor="exempt_no">
          No
        </label>
      </div>
    </div>
  );
}

export default RadioOptionalTip;
