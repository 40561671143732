import React from "react";

const MobileOverlay = () => {
  const closeNav = () => {
  }

  return (
    <div id="mobileNav" className="menu-overlay">
      <div className="overlay-header">
        <div className="container">
          <div className="close-btn pull-right">
            <button
              onClick={closeNav}
              className="btn btn-close"
              aria-label="Click to Close Menu"
            >
              ×
            </button>
          </div>
        </div>
      </div>

      <div className="overlay-content">
        <div className="container">
          <nav className="mobile-nav">
            <ul id="menu-main-menu-1" className="menu">
              <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-196 current_page_item menu-item-200 active">
                <a
                  title="Innovative Software"
                  href="https://www.tcbrealtor.com/innovative-software/"
                >
                  Innovative Software
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-59">
                <a title="About Us" href="https://www.tcbrealtor.com/about-us/">
                  About Us
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-303 dropdown">
                <a
                  title="Sell"
                  href="#"
                  data-toggle="dropdown"
                  className="dropdown-toggle"
                  aria-haspopup="true"
                >
                  Sell <span className="caret"></span>
                </a>
                <ul role="menu" className="dropdown-menu dropdown-menu-right">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-392">
                    <a
                      title="For Sellers"
                      href="https://www.tcbrealtor.com/sell/"
                    >
                      For Sellers
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-294">
                    <a
                      title="Not Happy, Don't Pay"
                      href="https://www.tcbrealtor.com/sell/not-happy-dont-pay/"
                    >
                      Not Happy, Don't Pay
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-295">
                    <a
                      title="Why List with TCB Realtor?"
                      href="https://www.tcbrealtor.com/sell/why-list-with-tcb-realtor/"
                    >
                      Why List with TCB Realtor?
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-296">
                    <a
                      title="TCB Realtor Unique Services for Sellers"
                      href="https://www.tcbrealtor.com/sell/tcb-realtor-unique-services-for-sellers/"
                    >
                      TCB Realtor Unique Services for Sellers
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-297">
                    <a
                      title="Protecting the Interest of the Seller"
                      href="https://www.tcbrealtor.com/sell/protecting-the-interest-of-the-seller/"
                    >
                      Protecting the Interest of the Seller
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-298">
                    <a
                      title="What is Staging?"
                      href="https://www.tcbrealtor.com/sell/what-is-staging/"
                    >
                      What is Staging?
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-299">
                    <a
                      title="Seller's Closing Cost"
                      href="https://www.tcbrealtor.com/sell/sellers-closing-cost/"
                    >
                      Seller's Closing Cost
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-300">
                    <a
                      title="Seller Closing Process"
                      href="https://www.tcbrealtor.com/sell/seller-closing-process/"
                    >
                      Seller Closing Process
                    </a>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-304 dropdown">
                <a
                  title="Buy"
                  href="#"
                  data-toggle="dropdown"
                  className="dropdown-toggle"
                  aria-haspopup="true"
                >
                  Buy <span className="caret"></span>
                </a>
                <ul role="menu" className="dropdown-menu dropdown-menu-right">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-393">
                    <a
                      title="For Buyers"
                      href="https://www.tcbrealtor.com/buy/"
                    >
                      For Buyers
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-422">
                    <a
                      title="Property Search"
                      href="https://www.tcbrealtor.com/homes-for-sale-search/"
                    >
                      Property Search
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-305">
                    <a
                      title="Technology"
                      href="https://www.tcbrealtor.com/buy/technology/"
                    >
                      Technology
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-306">
                    <a
                      title="Special Buyer Services"
                      href="https://www.tcbrealtor.com/buy/special-buyer-services/"
                    >
                      Special Buyer Services
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-307">
                    <a
                      title="Home Buying Steps with TCB Realtor"
                      href="https://www.tcbrealtor.com/buy/home-buying-steps-with-tcb-realtor/"
                    >
                      Home Buying Steps with TCB Realtor
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-308">
                    <a
                      title="Touring Property Guide"
                      href="https://www.tcbrealtor.com/buy/touring-property-guide/"
                    >
                      Touring Property Guide
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-309">
                    <a
                      title="Preparing to Make an Offer"
                      href="https://www.tcbrealtor.com/buy/preparing-to-make-an-offer/"
                    >
                      Preparing to Make an Offer
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-310">
                    <a
                      title="Buyer's Closing Cost"
                      href="https://www.tcbrealtor.com/buy/buyer-closing-cost/"
                    >
                      Buyer's Closing Cost
                    </a>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-311 dropdown">
                <a
                  title="Real Estate Finance"
                  href="#"
                  data-toggle="dropdown"
                  className="dropdown-toggle"
                  aria-haspopup="true"
                >
                  Real Estate Finance <span className="caret"></span>
                </a>
                <ul role="menu" className="dropdown-menu dropdown-menu-right">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-394">
                    <a
                      title="Real Estate Finance"
                      href="https://www.tcbrealtor.com/real-estate-finance/"
                    >
                      Real Estate Finance
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-313">
                    <a
                      title="Financing Terms"
                      href="https://www.tcbrealtor.com/real-estate-finance/financing-terms/"
                    >
                      Financing Terms
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-314">
                    <a
                      title="Formal Loan Application Process"
                      href="https://www.tcbrealtor.com/real-estate-finance/formal-loan-application-process/"
                    >
                      Formal Loan Application Process
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312">
                    <a
                      title="Buyers&#039; Closing Cost"
                      href="https://www.tcbrealtor.com/real-estate-finance/buyers-closing-cost/"
                    >
                      Buyers &#8217;Closing Cost
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-315">
                    <a
                      title="Sellers&#039; Closing Cost"
                      href="https://www.tcbrealtor.com/real-estate-finance/sellers-closing-cost/"
                    >
                      Sellers &#8217;Closing Cost
                    </a>
                  </li>
                </ul>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-316 dropdown">
                <a
                  title="Resources"
                  href="#"
                  data-toggle="dropdown"
                  className="dropdown-toggle"
                  aria-haspopup="true"
                >
                  Resources <span className="caret"></span>
                </a>
                <ul role="menu" className="dropdown-menu dropdown-menu-right">
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-395">
                    <a
                      title="Resources"
                      href="https://www.tcbrealtor.com/resources/"
                    >
                      Resources
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-317">
                    <a
                      title="Real Estate Terms"
                      href="https://www.tcbrealtor.com/resources/real-estate-terms/"
                    >
                      Real Estate Terms
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-318">
                    <a
                      title="Guide to Home Inspection"
                      href="https://www.tcbrealtor.com/resources/guide-to-home-inspection/"
                    >
                      Guide to Home Inspection
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-319">
                    <a
                      title="Exterior Components"
                      href="https://www.tcbrealtor.com/resources/exterior-components/"
                    >
                      Exterior Components
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-320">
                    <a
                      title="Diagram of a House"
                      href="https://www.tcbrealtor.com/resources/diagram-of-a-house/"
                    >
                      Diagram of a House
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-321">
                    <a
                      title="Architectural Designs"
                      href="https://www.tcbrealtor.com/resources/architectural-designs/"
                    >
                      Architectural Designs
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-322">
                    <a
                      title="Frequently Asked Questions"
                      href="https://www.tcbrealtor.com/resources/frequently-asked-questions/"
                    >
                      Frequently Asked Questions
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-571">
                    <a
                      title="Careers"
                      href="https://www.tcbrealtor.com/resources/careers/"
                    >
                      Careers
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default MobileOverlay;

