import React from 'react';
import { Button } from 'react-bootstrap';
import './styles.css';

/**
 * RenderHelp is a component that renders the help text for the QuickStart.
 *
 * This organism component should be called by the forms passing in the sFile
 * which is found in the config file.
 *
 * The markdown files are stored externally to the application in an S3 bucket.
 *
 * @param {string} sFile - The file name of the markdown file to be rendered.
 * @returns
 */

type Props = {
  sFile: string;
};

export const Help = ({ sFile }: Props) => {
  return (
    <>
      <Button variant="outline-dark" id="documentation-button" className="float-end mt-5" onClick={() => window.open(sFile, '_blank')}>
        Help
      </Button>
    </>
  );
};
