/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller } from 'react-hook-form';
import { FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { tipDoTypeSingle } from '../../atoms';
import { InputProps, Typeahead } from 'react-bootstrap-typeahead';

type FormInputLoanTypeSingleProps = {
  errors: any;
  control: any;
  required?: boolean;
  tip?: string;
  watch: any;
  label?: string;
  none?: boolean;
};

export type FormInputLoanTypeSingleHandle = {
  clear: () => void;
};

interface MyInputProps extends InputProps {
  'data-cy'?: string;
}

const FormInputLoanTypeSingle = forwardRef<FormInputLoanTypeSingleHandle, FormInputLoanTypeSingleProps>((props, ref) => {
  const name = 'doType';
  const label = props.label || 'Loan Type';
  const tip = props.tip || tipDoTypeSingle;
  const none = props.none || false;
  const options = {
    required: props.required || false,
  };

  type TLoan = {
    value: string;
    text: string;
  };
  let loanTypeaheadRef: Typeahead<TLoan> | null;

  useImperativeHandle(ref, () => ({
    clear() {
      console.log('FormInputLoanTypeSingle->clear called!');
      loanTypeaheadRef?.clear();
    },
  }));

  const toggleLoanTypeahead = (data: any) => {
    console.log('toggleTermeTypeahead->data', data);
    loanTypeaheadRef?.toggleMenu();
  };

  const filterBy = () => {
    return true;
  };

  let loanOptions = [
    { value: 'CONV', text: 'Conventional' },
    { value: 'FHA', text: 'FHA' },
    { value: 'USDA', text: 'USDA' },
    { value: 'VA', text: 'VA' },
  ];

  if (none) {
    loanOptions.unshift({ value: 'doNone', text: 'None' });
  }

  return (
    <div className="loan-input-single">
      <FloatingLabel
        controlId="floatingSelect"
        label={props.errors[name] ? label + (props.required ? ' Required' : ' Incorrect') : props.watch && props.watch.length > 0 ? label + '' : 'Select ' + label + ''}
        className={props.errors[name] ? 'alert-expand' : ''}
      >
        <Controller
          name={name}
          rules={options}
          control={props.control}
          render={({ field }) => (
            <Typeahead
              filterBy={filterBy}
              {...field}
              id="doType"
              inputProps={{ 'data-cy': 'doType', autoComplete: 'ignore-me' } as MyInputProps}
              ref={(typeahead) => (loanTypeaheadRef = typeahead)}
              labelKey="text"
              options={loanOptions}
            >
              <img className="select-down loan-down" alt="select-down" onClick={toggleLoanTypeahead} />
            </Typeahead>
          )}
        />
        <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
          <a className={props.errors[name] ? 'tooltiplabel alert-expand' : 'tooltippanel'}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </OverlayTrigger>
      </FloatingLabel>
    </div>
  );
});

export default FormInputLoanTypeSingle;
