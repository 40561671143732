import React from 'react';

/**
 * @param {string | number} a
 * @param {string | number} b
 *
 * Returns a string with the format: "This is the prompt $100.00"
 */

interface LocalProps {
  a: string | number;
  b: string | number;
  c: string | number;
  d: string | number;
}

export const TextDollarTextPercentDollar = ({ a, b, c, d }: LocalProps) => {
  return (
    <td align="left">
      {a}&nbsp;${b}&nbsp;{c}&nbsp;${d}
    </td>
  );
};
