/*
 * Copyright 2020-2021 AskChristee, LLC. All rights reserved.
 */

import { FloatingLabel, Form, OverlayTrigger, ToggleButtonGroup, Tooltip } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export type FormInputRadioButtonsProps = {
    name: string, control: any, errors: any,
    defaultValue: string, required?: boolean,
    ids: string[], values?: string[], labels?: string[],
    tip?: string, label: string, disabled?: boolean
}

const FormInputRadioButtons = ({ name, control, errors, defaultValue,
                                   required=false, disabled=false, tip, label,
                                   ids, values=['y', 'n'], labels=['Yes', 'No'] } : FormInputRadioButtonsProps ) => {

    const options = {
        required: required
    }

  return (
        <div className="radio-input">
            <FloatingLabel controlId="floatingSelect"
                           label={errors[name] ? label + ( required ? ' Required' : ' Incorrect') : label}
                           className={errors[name] ? 'alert-expand' : ''}
            >
                <Controller
                    name={name}
                    rules={options}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field }) => (
                        <ToggleButtonGroup {...field} type="radio" name={name} defaultValue={defaultValue} >
                            <Form.Check disabled={disabled} inline id={ids[0]} value={values[0]} type="radio" label={labels[0]} name={name} data-cy={ids[0]} />
                            <Form.Check disabled={disabled} inline id={ids[1]} value={values[1]} type="radio" label={labels[1]} name={name} data-cy={ids[1]} />
                        </ToggleButtonGroup>
                    )}
                />

                { tip ? (
                    <OverlayTrigger placement="right" trigger="click" rootClose overlay={<Tooltip>{tip}</Tooltip>}>
                        <a className={errors.filing_status ? ( required ? 'tooltiplabel alert-expand' : 'tooltippanel' ) : 'tooltippanel'}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </a>
                    </OverlayTrigger>
                ) : ( <></> ) }
            </FloatingLabel>
        </div>
    )
}

export default FormInputRadioButtons;
