import React, {useEffect, useState} from "react";

import FormInputRadioButtons from "../FormInputRadioButtons";

type FormInputUseRatiosProps = {
  loanTypes?: [],
  setValue: any,
  control: any, errors: any,
  tip?: string,
  disabled?: boolean
}

const FormInputFirstVaLoan = ({ loanTypes, setValue, ...rest }: FormInputUseRatiosProps) => {
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const newIsDisabled = !loanTypes?.find((type: {value: string, text: string}) => type.value === "VA");
    setValue("va_first", newIsDisabled ? "n" : "y");
    setIsDisabled(newIsDisabled);
  }, [loanTypes]);

  return <FormInputRadioButtons disabled={isDisabled} label="First VA Loan" name="va_first" defaultValue={"n"} ids={['va_first_yes', 'va_first_no']} {...rest} />
}

export default FormInputFirstVaLoan;
